<!-- how it works page  -->
<div >

    <!-- <section class="mb-5 mx-auto bg-header  overflow-hidden py-5" style="margin-top: 5rem; padding-bottom: 15rem !important;">
        <div class="container mt-5 d-flex align-items-center">
            <div
                class="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center text-primary">
                <h1 class="mb-3 mob-textcenter">Get more time to pay</h1>
                <h5 class="mob-textcenter px-4 p-lg-0">Taly lets you split your purchases into 4 interest-free
                    payments or pay after your purchase within 30 days.</h5>

                <button class="btn btn-success mt-3 rounded-pill" routerLink="/shop"><i class="fal fa-arrow-right me-2 ms-0"></i>SHOP NOW</button>
            </div>

        </div>
    </section> -->


       <app-howitworks></app-howitworks>

    <!-- <div class=" container">
        <section class="d-flex justify-content-center mt-5">
            <div class="row">
              <div class="col-lg-4 px-3 mb-2 section-action">
                <div class="p-4 pe-5 mob-textcenter text-center">
                  <img src="assets/images/home/download.png" class="mb-3 img-fluid" alt="">
                  <h4 class=" fw-bold">{{'home.section1.title1' | translate}}</h4>
                  <p class="text-muted">{{'home.section1.caption1' | translate}}</p>
                </div>
              </div>
              <div class="col-lg-4 px-3 mb-2 section-action">
                <div class="p-4 pe-5 mob-textcente text-center">
                  <img src="assets/images/home/download (2).png" class="mb-3 img-fluid" alt="">
                  <h4 class=" fw-bold">{{'home.section1.title2' | translate}}</h4>
                  <p class="text-muted">{{'home.section1.caption2' | translate}}</p>
                </div>
              </div>
              <div class="col-lg-4 px-3 section-action">
                <div class="p-4 pe-5 mob-textcenter text-center">
                  <img src="assets/images/home/download (1).png" class="mb-3 img-fluid" alt="">
                  <h4 class=" fw-bold">{{'home.section1.title3' | translate}}</h4>
                  <p class="text-muted">{{'home.section1.caption3' | translate}}</p>
                </div>
              </div>
            </div>
          </section> -->

    <!-- </div> -->
</div>
