<div class="d-flex justify-content-center">
  <div class="border rounded-3 section-pad p-4">
    <ng-container *ngIf="isCardPosted; else card_content">
      <div class="text-center">
        <div class="text-center text-primary mb-20">
          <img
            routerLink="/home"
            src="assets/images/logo/taly_logo.png"
            class="img-fluid mb-1"
            width="120"
            height="100"
            alt="taly"
          />
          <h4 class="text-primary">Secure Checkout</h4>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <ng-container *ngIf="isCardVerified; else error_content">
            <div
              class="tick-oval d-flex justify-content-center align-items-center mb-4"
              style="background-color: #6edd9d"
            >
              <i class="fal fa-check-circle text-white fa-3x"></i>
            </div>

            <h4 class="text-center">
              Your order has been successfully placed.
            </h4>
            <button
              class="btn btn-primary w-100 mt-5"
              routerLink="/consumer/dashboard"
            >
              {{ "general.back" | translate }}justify-content-center\j
            </button>
          </ng-container>

          <ng-template #error_content>
            <div
              class="tick-oval bg-danger d-flex justify-content-center align-items-center mb-4"
            >
              <i class="fal fa-exclamation-circle text-white fa-3x"></i>
            </div>

            <h4>
              {{
                cardVerifiedMsg == undefined
                  ? ("general.processing" | translate)
                  : cardVerifiedMsg
              }}
            </h4>

            <button class="btn btn-primary w-100 mt-5" (click)="backToCard()">
              {{ "general.back" | translate }}
            </button>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #card_content>
      <!-- skip link  -->
      <!-- <a
        role="button"
        (click)="onSkip()"
        class="text-dark d-flex align-items-center"
        >{{ "general.skip" | translate }}
        <i class="fal fa-long-arrow-right ms-1"></i
      ></a> -->

      <!-- head  -->
      <h3 class="mb-1 mt-4">{{ "general.cardDetails" | translate }}</h3>
      <p class="mb-4">{{ "general.cardDetailsCaption" | translate }}</p>

      <!-- add card form  -->
      <form autocomplete="off" [formGroup]="addCardForm">
        <!-- name  -->
        <label for="cardHolderName" class="small mb-1">{{
          "general.cardHolder" | translate
        }}</label>
        <input
          autocomplete="off"
          type="text"
          name="card-holder-name"
          class="txtbox mb-4"
          formControlName="holderName"
          [ngClass]="
            addCardForm.get('holderName').invalid &&
            addCardForm.get('holderName').touched
              ? 'invalidInput'
              : ''
          "
          noSpecialCharacter
          avoidNumbers
          maxlength="100"
        />

        <!-- card number   -->
        <label for="cardNo" class="small mb-1">{{
          "general.cardNumber" | translate
        }}</label>
        <div class="mb-4">
          <div
            class="txtbox"
            appClickOutside
            (clickOutside)="checkInput('ccNum')"
            [ngClass]="
              addCardForm.get('creditCardNumber').invalid &&
              addCardForm.get('creditCardNumber').touched
                ? 'invalidInput'
                : ''
            "
          >
            <input
              style="width: 85%"
              autocomplete="off"
              type="tel"
              name="card-number"
              class="fw-bold contactTxtbox txtbox2"
              formControlName="creditCardNumber"
              autocomplete="cc-number"
              ccNumber
              #ccNumber="ccNumber"
              #cardInput
            />
            <!-- <span class="scheme float-end"
                *ngIf="!addCardForm.get('creditCardNumber').hasError('ccNumber')">{{ccNumber.resolvedScheme$
                | async}}</span> -->

            <span
              class="scheme float-end"
              *ngIf="!addCardForm.get('creditCardNumber').hasError('ccNumber')"
              [ngSwitch]="ccNumber.resolvedScheme$ | async"
            >
              <img
                class="img-fluid"
                *ngSwitchCase="'visa'"
                src="assets/images/icons/visa.png"
                alt=""
                style="height: 26px; width: 35px"
              />
              <img
                class="img-fluid"
                *ngSwitchCase="'mastercard'"
                src="assets/images/icons/mc.png"
                alt=""
                style="height: 26px; width: 35px"
              />
              <img
                class="img-fluid"
                *ngSwitchCase="'amex'"
                src="assets/images/icons/amex.png"
                alt=""
                style="height: 26px; width: 35px"
              />
              <img
                class="img-fluid"
                *ngSwitchCase="'discover'"
                src="assets/images/icons/discover.png"
                alt=""
                style="height: 26px; width: 35px"
              />
              <img
                class="img-fluid"
                *ngSwitchCase="'dinersclub'"
                src="assets/images/icons/diners.png"
                alt=""
                style="height: 26px; width: 35px"
              />
              <img
                class="img-fluid"
                *ngSwitchDefault
                src="assets/images/icons/add-card.png"
                alt=""
                style="height: 26px; width: 35px"
              />
            </span>
          </div>

          <span
            class="text-danger small d-block mt-1"
            *ngIf="
              ccNumOutClick &&
              addCardForm.get('creditCardNumber').hasError('ccNumber')
            "
            >Not valid!</span
          >

          <!-- card Accepted  -->
          <p class="small my-1">{{ "general.cardAccepted" | translate }}</p>
          <div class="mb-4">
            <img src="assets/images/visa.svg" class="cards me-2 ms-0" />
            <img
              src="assets/images/icons-8-mastercard.png"
              class="cards me-2"
            />
            <!-- <img src="assets/images/icons-8-american-express.png" class="cards me-2" />
              <img src="assets/images/icons-8-discover.png" class="cards me-2" />
              <img src="assets/images/icons-8-diners-club.png" class="cards me-2" /> -->
          </div>
        </div>

        <!-- expiry date and CVV  -->
        <div class="row">
          <!-- expiration  -->
          <div class="col-lg-3 mb-3 mb-lg-0">
            <label class="mb-1 small d-block">{{
              "general.expiry" | translate
            }}</label>
            <input
              autocomplete="off"
              type="text"
              placeholder="MM/YY"
              name="card-expiry"
              class="txtbox"
              maxlength="7"
              autocomplete="cc-exp"
              ccExp
              formControlName="expiry"
              [ngClass]="
                addCardForm.get('expiry').invalid &&
                addCardForm.get('expiry').touched
                  ? 'invalidInput'
                  : ''
              "
            />
            <span
              class="text-danger small d-block mt-1"
              *ngIf="addCardForm.get('expiry').hasError('expiryNotValid')"
              >Not Valid!</span
            >
          </div>
          <!-- CVV  -->
          <div class="col-lg-3">
            <label class="mb-1 small d-block">{{
              "general.cvv" | translate
            }}</label>
            <input
              autocomplete="off"
              type="password"
              name="card-CVV"
              class="txtbox"
              autocomplete="off"
              formControlName="cvv"
              ccCVC
              appClickOutside
              (clickOutside)="checkInput('cvv')"
              [ngClass]="
                addCardForm.get('cvv').invalid && addCardForm.get('cvv').touched
                  ? 'invalidInput'
                  : ''
              "
            />
            <span
              class="text-danger small d-block mt-1"
              *ngIf="
                cvvOutClick && addCardForm.get('cvv').hasError('minlength')
              "
              >Not Valid!</span
            >
          </div>
          <!-- cvv logo  -->
          <p class="mt-2">
            <img
              src="assets/images/group-6.png"
              class="img-fluid ms-0 me-2"
            /><small>{{ "general.cvvDesc" | translate }}</small>
          </p>
        </div>

        <!-- check Terms -->
        <div class="mb-4 mt-4">
          <input
            autocomplete="off"
            type="checkbox"
            class="me-1 ms-0"
            formControlName="TnC"
            id="checkbox"
          />
          <label for="checkbox"
            >{{ "general.agreementOfTaly" | translate }}
          </label>
          <!-- <a class="ms-1" type="button">{{ "general.tnC" | translate }}</a> -->
          <a href="{{ link }}" target="_blank" class="ms-1">
            {{ "general.tnC" | translate }}</a
          >
        </div>

        <div class="row">
          <div class="col-6">
            <button
              (click)="onSubmit()"
              class="btn btn-success mb-3 w-100"
              [disabled]="addCardForm.invalid ? true : false ? loading : ''"
            >
              <span *ngIf="!loading">{{ "general.proceed" | translate }}</span>
              <span *ngIf="loading">
                <i class="fa fa-spinner fa-spin me-1"> </i>
                {{ "general.loading" | translate }}
              </span>
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn btn-info mb-3 w-100"
              (click)="onSkip()"
            >
              {{ "general.skip" | translate }}
            </button>
          </div>
        </div>
      </form>
    </ng-template>
  </div>
</div>
<!-- end container -->
