import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[OtpDesign]',
})
export class OtpDesignDirective {
  constructor(private otpElem: ElementRef, private renderer: Renderer2) {
    setTimeout(() => {
      this.renderer.addClass(otpElem.nativeElement, 'w-100');
      this.renderer.addClass(otpElem.nativeElement.childNodes[0], 'w-100');
      let otpContainer = otpElem.nativeElement.childNodes[0];
      const div = this.renderer.createElement('div');

      let btnElem =
        this.otpElem.nativeElement.childNodes[0].lastChild.previousSibling
          .firstChild;
      this.renderer.addClass(btnElem, 'text-info');
      this.renderer.addClass(btnElem.childNodes[1], 'text-dark');
      this.renderer.addClass(btnElem.childNodes[1], 'fw-bold');
      this.renderer.addClass(btnElem, 'p-0');
      this.renderer.addClass(btnElem, 'ms-lg-1');
      this.renderer.addClass(btnElem, 'ms-0');

      const span = this.renderer.createElement('span');
      const text = this.renderer.createText('Having trouble?');
      this.renderer.addClass(span, 'pr-5');
      this.renderer.appendChild(span, text);

      let resendbtnContainer =
        this.otpElem.nativeElement.childNodes[0].lastChild.previousSibling;
      this.renderer.addClass(resendbtnContainer, 'd-flex');
      this.renderer.addClass(resendbtnContainer, 'align-items-center');
      this.renderer.addClass(resendbtnContainer, 'mt-5');
      this.renderer.addClass(resendbtnContainer, 'flex-wrap');

      const parent = resendbtnContainer;
      const refChild = btnElem;
      this.renderer.insertBefore(parent, span, refChild);

      for (let i = 1; i < 6; i++) {
        this.renderer.appendChild(div, otpContainer.childNodes[0]);
      }

      this.renderer.insertBefore(
        otpElem.nativeElement.childNodes[0],
        div,
        resendbtnContainer
      );
      this.renderer.addClass(div, 'd-flex');
      this.renderer.addClass(div, 'justify-content-center');

      // let lastInput= otpContainer.firstElementChild.lastChild;
      // this.renderer.addClass(lastInput, 'me-2')
    }, 10);
  }
}
