import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  linkColors:string[];
  inactiveColor:string="tab-section-links";
  activeColor:string="linkactivecolor";
  ngOnInit(): void {
    this.linkColors=[this.activeColor,this.inactiveColor,this.inactiveColor,this.inactiveColor,this.inactiveColor];
  }
 
  
  onLinkClick(linkName){
    this.linkColors=[this.inactiveColor,this.inactiveColor,this.inactiveColor,this.inactiveColor,this.inactiveColor]
    switch (linkName) {
      case 'about':
        this.linkColors[0]= this.activeColor;
        break;
    
      case 'principles':
        this.linkColors[1]= this.activeColor;
        break;

      case 'spending':
        this.linkColors[2]=this.activeColor;
        break;

      case 'PCI':
        this.linkColors[3]=this.activeColor;
        break;

      case 'investors':
        this.linkColors[4]=this.activeColor;
        break;
    }  
  }

}
