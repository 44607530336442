import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/env.service';
import { environment } from 'src/environments/environment';
import { AnnualSale, AverageOrder } from '../../models/lov/annualSales.model';
import { BusinessType, Platform } from '../../models/lov/businesType.model';
import { Country } from '../../models/lov/country.model';
import { Industry } from '../../models/lov/industry.model';
import { City, Province } from '../../models/lov/province.model';

@Injectable({ providedIn: 'root' })
export class ConsumerLovService {
  constructor(private http: HttpClient, private environment: EnvService) {}

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(
      `${this.environment.ApiUrl}/backoffice/country`
    );
  }
  getProvinces(countryId: number): Observable<Province[]> {
    return this.http.get<Province[]>(
      `${this.environment.ApiUrl}/accounts/country/${countryId}/provinces`
    );
  }
  getCities(iso: any): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.environment.ApiUrl}/backoffice/city?countryIso=${iso}`
    );
  }

  getAreas(cityid: number) {
    return this.http.get<any[]>(
      `${this.environment.ApiUrl}/backoffice/area?cityId=${cityid}`
    );
  }
  getIndustries() {
    return this.http.get<Industry[]>(
      `${this.environment.ApiUrl}/backoffice/industryType`
    );
  }

  getCategory() {
    return this.http.get<Industry[]>(
      `${this.environment.ApiUrl}/backoffice/category`
    );
  }

  getBusinessTypes() {
    return this.http.get<BusinessType[]>(
      `${this.environment.ApiUrl}/backoffice/businessType`
    );
  }

  getAnnualSales() {
    return this.http.get<AnnualSale[]>(
      `${this.environment.ApiUrl}/backoffice/annualSale`
    );
  }

  getAverageOrders() {
    return this.http.get<AverageOrder[]>(
      `${this.environment.ApiUrl}/backoffice/averageOrderValue`
    );
  }

  getPlatforms() {
    return this.http.get<Platform[]>(
      `${this.environment.ApiUrl}/backoffice/platform`
    );
  }

  getAddressTypes() {
    return this.http.get<any[]>(
      `${this.environment.ApiUrl}/backoffice/addressType`
    );
  }
}
