import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MerchantCheckoutRoutingModule } from './merchant-checkout-routing.module';
import { MerchantHeaderComponent } from './merchant-header/merchant-header.component';
import { CheckoutPagesComponent } from './checkout-pages/checkout-pages.component';
import { Step1Component } from './checkout-pages/step1/step1.component';
import { PaymentSuccessComponent } from './checkout-pages/payment-success/payment-success.component';
import { PaymentPlanComponent } from './checkout-pages/payment-plan/payment-plan.component';
import { SecureCheckoutComponent } from './secure-checkout/secure-checkout.component';
import { Split4Component } from './checkout-pages/step1/split4/split4.component';
import { Pay30Component } from './checkout-pages/step1/pay30/pay30.component';
import { ComponentsModule } from 'src/app/Core/components/components.module';
import { LangTranslateModule } from 'src/app/lang-translate.module';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { CustomDirectivesModule } from 'src/app/Core/custom-directives/custom-directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginSignupComponent } from './checkout-pages/login-signup/login-signup.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { OtpComponent } from './checkout-pages/login-signup/otp/otp.component';
import { NgOtpInputModule } from '@weijen/ng-otp-input';
import { AngularOtpLibModule } from 'angular-otp-box';
import { PaymentFailedComponent } from './checkout-pages/payment-failed/payment-failed.component';
import { PipesModule } from 'src/app/Core/pipes/pipes.module';
import { NationalIdFormComponent } from './checkout-pages/login-signup/national-id-form/national-id-form.component';
import { MobileIdCheckoutComponent } from './checkout-pages/login-signup/mobile-id-checkout/mobile-id-checkout.component';
import { CustomerInfoCheckoutComponent } from './checkout-pages/login-signup/customer-info-checkout/customer-info-checkout.component';
// import { ComponentsModule } from '../Core/components/components.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { CardInfoCheckoutComponent } from './checkout-pages/login-signup/card-info-checkout/card-info-checkout.component';

@NgModule({
  declarations: [
    MerchantHeaderComponent,
    CheckoutPagesComponent,
    Step1Component,
    PaymentSuccessComponent,
    PaymentPlanComponent,
    SecureCheckoutComponent,
    Split4Component,
    Pay30Component,
    LoginSignupComponent,
    OtpComponent,
    PaymentFailedComponent,
    NationalIdFormComponent,
    MobileIdCheckoutComponent,
    CustomerInfoCheckoutComponent,
    CardInfoCheckoutComponent,
  ],
  imports: [
    CommonModule,
    MerchantCheckoutRoutingModule,
    ComponentsModule,
    LangTranslateModule,
    ReactiveFormsModule,
    FormsModule,
    CreditCardDirectivesModule,
    CustomDirectivesModule,
    NgxCaptchaModule,
    NgOtpInputModule,
    AngularOtpLibModule,
    PipesModule,
    BsDatepickerModule.forRoot(),
  ],
})
export class MerchantCheckoutModule {}
