<!-- form 2 component -->

<div
  class="section-pad background-light d-flex flex-column mx-auto mb-4 border text-primary"
>
  <!-- heading  -->
  <h3 class="mb-4">{{ "merchant.merchantReqForm" | translate }}</h3>
  <p class="mb-4">{{ "merchant.caption" | translate }}</p>
  <!-- <app-success-box appSuccessMsg [successText]="'Success! you are now few steps to complete'"></app-success-box> -->
  <form
    autocomplete="off"
    (ngSubmit)="onSubmit()"
    [formGroup]="merchantSignUpForm"
    #myForm="ngForm"
  >
    <!--  businessDetails  -->
    <h5 class="mb-3 fw-bold">{{ "merchant.title" | translate }}</h5>
    <form autocomplete="off" formGroupName="businessDetails">
      <!-- row 1  -->
      <div class="row d-flex flex-wrap">
        <!-- business name  -->
        <div class="col-sm-5 d-flex flex-column">
          <label class="small text-dark mb-1"
            >{{ "general.contact.businessName" | translate }}
            <span class="text-danger">*</span></label
          >
          <input
            autocomplete="off"
            type="text"
            name="marchant-business-name"
            class="txtbox mb-4"
            formControlName="businessName"
            [ngClass]="
              merchantSignUpForm.get('businessDetails').get('businessName')
                .errors &&
              merchantSignUpForm.get('businessDetails').get('businessName')
                .touched
                ? 'invalidInput'
                : ''
            "
            noSpecialCharacter
            avoidNumbers
            maxlength="100"
          />
        </div>

        <!-- country of registration  -->
        <div class="col-sm-5 d-flex flex-column">
          <label class="small text-dark mb-1"
            >{{ "general.contact.country" | translate }}
            <span class="text-danger">*</span></label
          >
          <select
            autocomplete="off"
            name="country-of-registration"
            class="txtbox form-select mb-4"
            formControlName="country"
            (change)="onSelectCountry()"
            [ngClass]="
              merchantSignUpForm.get('businessDetails').get('country').errors &&
              merchantSignUpForm.get('businessDetails').get('country').touched
                ? 'invalidInput'
                : ''
            "
          >
            <option value="" selected>
              Select {{ "general.contact.country" | translate }}
            </option>
            <option *ngFor="let country of countries">
              {{
                directionController.textDirection == "ltr"
                  ? country.nameEn
                  : country.nameAr
              }}
            </option>
          </select>
        </div>
      </div>

      <!-- row 2 -->
      <div class="row d-flex flex-wrap mb-4">
        <!-- industry  -->
        <div class="col-sm-5 d-flex flex-column">
          <label class="small text-dark mb-1"
            >{{ "general.contact.category" | translate }}
            <span class="text-danger">*</span></label
          >
          <select
            autocomplete="off"
            name="Marchant-industry"
            class="txtbox form-select mb-4"
            formControlName="industry"
            [ngClass]="
              merchantSignUpForm.get('businessDetails').get('industry')
                .errors &&
              merchantSignUpForm.get('businessDetails').get('industry').touched
                ? 'invalidInput'
                : ''
            "
          >
            <option value="" selected>
              Select {{ "general.contact.category" | translate }}
            </option>
            <option *ngFor="let industry of industries">
              {{
                directionController.textDirection == "ltr"
                  ? industry.nameEn
                  : industry.nameAr
              }}
            </option>
          </select>
        </div>

        <div class="col-sm-5 d-flex flex-column" *ngIf="isOtherIndustryName">
          <label class="small text-dark mb-1"
            >Other Category <span class="text-danger">*</span></label
          >
          <input
            autocomplete="off"
            type="text"
            name="marchant-business-name"
            class="txtbox mb-4"
            formControlName="otherIndustry"
            placeholder="Please specify Category"
            [ngClass]="
              merchantSignUpForm.get('businessDetails').get('otherIndustry')
                .errors &&
              merchantSignUpForm.get('businessDetails').get('otherIndustry')
                .touched
                ? 'invalidInput'
                : ''
            "
            noSpecialCharacter
            avoidNumbers
            maxlength="100"
          />
        </div>

        <!-- Business type  -->
        <div class="col-sm-5 d-flex flex-column">
          <label class="small text-dark mb-1"
            >{{ "general.contact.businessType" | translate }}
            <span class="text-danger">*</span></label
          >
          <select
            autocomplete="off"
            name="Marchant-Business-type"
            class="txtbox form-select mb-4"
            formControlName="businessType"
            (change)="
              onBusinessType(
                merchantSignUpForm.get('businessDetails').get('businessType')
                  .value
              )
            "
            [ngClass]="
              merchantSignUpForm.get('businessDetails').get('businessType')
                .errors &&
              merchantSignUpForm.get('businessDetails').get('businessType')
                .touched
                ? 'invalidInput'
                : ''
            "
          >
            <option value="" selected>
              Select {{ "general.contact.businessType" | translate }}
            </option>
            <option *ngFor="let businessType of businessTypes">
              {{
                directionController.textDirection == "ltr"
                  ? businessType.nameEn
                  : businessType.nameAr
              }}
            </option>
          </select>
        </div>
      </div>
    </form>

    <!-- contactInfo -->
    <h5 class="mb-3 fw-bold">{{ "general.contactInfo" | translate }}</h5>
    <form autocomplete="off" formGroupName="contactInfo">
      <!-- row 3 -->
      <div class="row d-flex flex-wrap mb-4">
        <!-- business email  -->
        <div class="col-sm-5 d-flex flex-column mb-4">
          <label class="small text-dark mb-1"
            >{{ "general.contact.businessEmail" | translate }}
            <span class="text-danger">*</span></label
          >
          <input
            autocomplete="off"
            type="email"
            name="marchant-email"
            class="txtbox"
            formControlName="businessEmail"
            [ngClass]="
              (merchantSignUpForm.get('contactInfo').get('businessEmail')
                .errors &&
                merchantSignUpForm.get('contactInfo').get('businessEmail')
                  .touched) ||
              (emailInputClick &&
                this.merchantSignUpForm.get('contactInfo').get('businessEmail')
                  .invalid)
                ? 'invalidInput'
                : ''
            "
            appClickOutside
            (clickOutside)="onClickOutside('email')"
          />

          <span
            class="mb-1 text-danger"
            *ngIf="
              this.merchantSignUpForm.get('contactInfo').get('businessEmail')
                .errors &&
              this.merchantSignUpForm.get('contactInfo').get('businessEmail')
                .touched &&
              this.merchantSignUpForm.get('contactInfo').get('businessEmail')
                .errors.pattern
            "
            >Enter valid email address!</span
          >
        </div>

        <!-- mobile number  -->
        <div class="col-sm-5 d-flex flex-column">
          <label class="small text-dark mb-1"
            >{{ "general.contact.number" | translate }}
            <span class="text-danger">*</span></label
          >
          <input
            autocomplete="off"
            type="tel"
            OnlyNumber
            [autocomplete]="false"
            maxlength="8"
            minlength="8"
            name="contactNumber"
            class="txtbox"
            formControlName="contactNumber"
            [ngClass]="
              merchantSignUpForm.get('contactInfo').get('contactNumber')
                .errors &&
              merchantSignUpForm.get('contactInfo').get('contactNumber').touched
                ? 'invalidInput'
                : ''
            "
            (keypress)="_keyPress($event)"
          />

          <span
            class="mb-1 text-danger"
            *ngIf="
              merchantSignUpForm.get('contactInfo').get('contactNumber')
                .errors &&
              merchantSignUpForm.get('contactInfo').get('contactNumber').touched
            "
            >Enter valid mobile number!</span
          >
          <!-- 
          <span *ngIf="merchantSignUpForm.get('contactNumber').hasError('required')">
            Please enter atleast 8 digit!
          </span> -->
          <!-- <span *ngIf="merchantSignUpForm.get('contactNumber').hasError('pattern')">
            It is not a valid mobile number.
          </span> -->
        </div>
      </div>
    </form>

    <!-- website -->
    <ng-container *ngIf="isNotInStore">
      <h5 class="mb-3 fw-bold">{{ "general.website" | translate }}</h5>
      <form autocomplete="off" formGroupName="website">
        <!-- row 4 -->
        <div class="row d-flex flex-wrap mb-4">
          <!-- website URL  -->
          <div class="col-sm-5 d-flex flex-column mb-4">
            <label class="small text-dark mb-1"
              >{{ "general.contact.websiteURL" | translate }}
              <span class="text-danger">*</span></label
            >
            <input
              autocomplete="off"
              type="url"
              name="marchant-website"
              class="txtbox mb-lg-0"
              formControlName="webURL"
              placeholder="www.taly.io"
              [ngClass]="
                (merchantSignUpForm.get('website').get('webURL').errors &&
                  merchantSignUpForm.get('website').get('webURL').touched) ||
                (urlInputClick &&
                  this.merchantSignUpForm.get('website').get('webURL').invalid)
                  ? 'invalidInput'
                  : ''
              "
              appClickOutside
              (clickOutside)="onClickOutside('url')"
            />
            <span class="mt-2 text-danger" *ngIf="s_url.value && s_url.invalid"
              >Your website URL is invalid!</span
            >
          </div>

          <div class="col-sm-5 d-flex flex-column">
            <!-- platform  -->
            <label class="small text-dark mb-1"
              >{{ "general.contact.platform" | translate }}
              <span class="text-danger">*</span></label
            >
            <select
              autocomplete="off"
              name=""
              id="marchant-platform"
              class="txtbox form-select"
              formControlName="platform"
              [ngClass]="
                merchantSignUpForm.get('website').get('platform').errors &&
                merchantSignUpForm.get('website').get('platform').touched
                  ? 'invalidInput'
                  : ''
              "
            >
              <option value="" selected>
                Select {{ "general.contact.platform" | translate }}
              </option>
              <option *ngFor="let platform of platforms">
                {{
                  directionController.textDirection == "ltr"
                    ? platform.nameEn
                    : platform.nameAr
                }}
              </option>
            </select>
          </div>

          <div class="col-sm-5 d-flex flex-column" *ngIf="isOtherPlatformName">
            <label class="small text-dark mb-1 mt-3"
              >Please specify <span class="text-danger">*</span></label
            >
            <input
              autocomplete="off"
              type="text"
              name="marchant-business-name"
              class="txtbox mb-4"
              formControlName="otherPlatfom"
              placeholder="Please specify platform"
              [ngClass]="
                merchantSignUpForm.get('website').get('otherPlatfom').errors &&
                merchantSignUpForm.get('website').get('otherPlatfom').touched
                  ? 'invalidInput'
                  : ''
              "
              noSpecialCharacter
              avoidNumbers
              maxlength="100"
            />
          </div>
        </div>
      </form>
    </ng-container>

    <!-- sales  -->
    <h5 class="mb-3 fw-bold">{{ "general.sales" | translate }}</h5>
    <form autocomplete="off" formGroupName="sales">
      <!-- row 7 -->
      <div class="row d-flex flex-wrap">
        <!-- annual sales  -->
        <div class="col-sm-5 d-flex flex-column">
          <label class="small text-dark mb-1"
            >{{ "general.contact.annualSales" | translate }}
            <span class="text-danger">*</span></label
          >
          <select
            autocomplete="off"
            name="Annual-sales"
            class="txtbox form-select mb-4"
            formControlName="annualSales"
            [ngClass]="
              merchantSignUpForm.get('sales').get('annualSales').errors &&
              merchantSignUpForm.get('sales').get('annualSales').touched
                ? 'invalidInput'
                : ''
            "
          >
            <option value="" selected>
              Select {{ "general.contact.annualSales" | translate }}
            </option>
            <option *ngFor="let annualSale of annualSales">
              {{
                directionController.textDirection == "ltr"
                  ? annualSale.nameEn
                  : annualSale.nameAr
              }}
            </option>
          </select>
        </div>

        <!-- average order :  -->
        <div class="col-sm-5 d-flex flex-column">
          <label class="small text-dark mb-1"
            >{{ "general.contact.averageOrder" | translate }}
            <span class="text-danger">*</span></label
          >
          <select
            autocomplete="off"
            name="average-order"
            class="txtbox form-select mb-4"
            formControlName="averageOrder"
            [ngClass]="
              merchantSignUpForm.get('sales').get('averageOrder').errors &&
              merchantSignUpForm.get('sales').get('averageOrder').touched
                ? 'invalidInput'
                : ''
            "
          >
            <option value="" selected>
              Select {{ "general.contact.averageOrder" | translate }}
            </option>
            <option *ngFor="let averageOrder of averageOrders">
              {{
                directionController.textDirection == "ltr"
                  ? averageOrder.nameEn
                  : averageOrder.nameAr
              }}
            </option>
          </select>
        </div>
      </div>
    </form>

    <div class="d-flex flex-column">
      <!-- <label for="mobileNumber" class="form-label">
        {{ "general.contact.captcha" | translate }}
        <span class="text-danger">*</span></label
      > -->

      <ngx-invisible-recaptcha
        #captchaElem
        [siteKey]="siteKey"
        (success)="handleSuccess($event)"
        [useGlobalDomain]="false"
        formControlName="captcha"
      >
      </ngx-invisible-recaptcha>
    </div>
    <!-- proceed btn  -->
    <button
      type="submit"
      (click)="execute()"
      class="btn btn-dark rounded-pill mt-4"
      [disabled]="merchantSignUpForm.invalid"
    >
      {{ "general.submit" | translate }}
    </button>
  </form>
</div>
