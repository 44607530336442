<!-- FAQ's page here  -->

<section class="section-inner">
  <div class="container text-primary">
    <h2 class="mb-4 mob-textcenter">
      {{ "content.faqPage.title" | translate }}
    </h2>

    <!-- Search bar  -->
    <div class="search-bar mb-5 row">
      <!-- search box  -->

      <div class="col-lg-6 col-12 mb-4">
        <div class="txtbox border rounded-pill d-flex mx-auto mx-lg-0">
          <i class="bi bi-search ms-1 me-2"></i>
          <input autocomplete="off" type="text" name="txtbox" class="contactTxtbox w-100 border-0"
            placeholder="{{ 'content.faqPage.search' | translate }}" aria-label="Search" />
          <br />
        </div>
      </div>

      <p class="text-primary">{{ "content.faqPage.lebal" | translate }}</p>
    </div>

    <!-- faqs  -->
    <div>
      <!-- nav tab toggler -->
      <ul class="nav mb-5 justify-content-lg-start justify-content-center">
        <li class="nav-item me-4 ms-0" (click)="onNavTab(1)">
          <h6 class="pb-1 text-primary navTab" [ngClass]="navtabsClass[0]" data-bs-toggle="tab"
            data-bs-target="#tab-customer" type="button" role="tab" aria-selected="true">
            <b>{{ "content.faqPage.tabs.tabName1" | translate }}</b>
          </h6>
        </li>
        <li class="nav-item" (click)="onNavTab(2)">
          <h6 class="pb-1 text-primary navTab" [ngClass]="navtabsClass[1]" data-bs-toggle="tab"
            data-bs-target="#tab-merchant" type="button" role="tab" aria-selected="false">
            <b>{{ "content.faqPage.tabs.tabName2" | translate }}</b>
          </h6>
        </li>
      </ul>

      <!-- tab content  -->
      <div class="tab-content">
        <!-- customer tab -->
        <div class="tab-pane fade show active mb-5" id="tab-customer" role="tabpanel">
          <div class="row mb-0 mb-lg-5 px-4 px-lg-0">
            <div class="col-lg-12 mb-4 mob-textcenter">
              <h3 class="mb-4">
                {{ "content.faqPage.tabs.tab1Content.0.title" | translate }}
              </h3>

              <accordion [closeOthers]="true" [isAnimated]="true">
                <accordion-group [panelClass]="'talyAccordion'" #ag1>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques1" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag1?.isOpen,
                        'fa-minus-circle': ag1?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    {{ "content.faqPage.tabs.tab1Content.0.ans1" | translate }}
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #ag2>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques2" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag2?.isOpen,
                        'fa-minus-circle': ag2?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p>
                      {{ "content.faqPage.tabs.tab1Content.0.ans2" | translate }} <a
                        href="assets/docs/sharia-compliant.pdf" target="_blank">
                        {{ "content.faqPage.tabs.tab1Content.0.ans2.1" | translate }}
                      </a>
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #ag3>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques3" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag3?.isOpen,
                        'fa-minus-circle': ag3?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">

                    {{ "content.faqPage.tabs.tab1Content.0.ans3" | translate }}
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #ag4>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques4" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag4?.isOpen,
                        'fa-minus-circle': ag4?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p>

                      {{ "content.faqPage.tabs.tab1Content.0.ans4" | translate }}
                    </p>
                    <p>{{ "content.faqPage.tabs.tab1Content.0.ans4.1" | translate }}</p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #ag5>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques5" | translate }}

                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag5?.isOpen,
                        'fa-minus-circle': ag5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">{{ "content.faqPage.tabs.tab1Content.0.ans5" | translate }}</div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #ag6>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques6" | translate }}

                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag5?.isOpen,
                        'fa-minus-circle': ag5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">{{ "content.faqPage.tabs.tab1Content.0.ans6" | translate }}</div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #ag7>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques7" | translate }}

                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag5?.isOpen,
                        'fa-minus-circle': ag5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">{{ "content.faqPage.tabs.tab1Content.0.ans7" | translate }}</div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #ag8>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques8" | translate }}

                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag5?.isOpen,
                        'fa-minus-circle': ag5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">{{ "content.faqPage.tabs.tab1Content.0.ans8" | translate }}</div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #ag9>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques9" | translate }}

                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag5?.isOpen,
                        'fa-minus-circle': ag5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">{{ "content.faqPage.tabs.tab1Content.0.ans9" | translate }}</div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #ag10>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques10" | translate }}

                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag5?.isOpen,
                        'fa-minus-circle': ag5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">{{ "content.faqPage.tabs.tab1Content.0.ans10" | translate }}</div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #ag11>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques11" | translate }}

                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag5?.isOpen,
                        'fa-minus-circle': ag5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">{{ "content.faqPage.tabs.tab1Content.0.ans11" | translate }}</div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #ag12>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.0.ques12" | translate }}

                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ag5?.isOpen,
                        'fa-minus-circle': ag5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">{{ "content.faqPage.tabs.tab1Content.0.ans12" | translate }}</div>
                </accordion-group>
              </accordion>

              <!-- <a href="javascript:void(0)" class="fw-bold">{{ 'content.faqPage.tabs.viewAll' | translate }}</a>                     -->
            </div>
            <div class="col-lg-12 mb-4 mob-textcenter">
              <h3 class="mb-4">
                {{ "content.faqPage.tabs.tab1Content.1.title" | translate }}
              </h3>

              <accordion [closeOthers]="true" [isAnimated]="true">
                <accordion-group [panelClass]="'talyAccordion'" #aga1>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.1.ques1" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !aga1?.isOpen,
                        'fa-minus-circle': aga1?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.1.ans1" | translate }}.
                    </p>
                    <p class="mb-3">

                      {{ "content.faqPage.tabs.tab1Content.1.ans1.1" | translate }}
                    </p>
                    <p class="mb-3">

                      {{ "content.faqPage.tabs.tab1Content.1.ans1.2" | translate }}:
                    </p>
                    <!-- <ul>
                      <li>{{ "content.faqPage.tabs.tab1Content.1.ans1.3" | translate }}</li>
                      <li>{{ "content.faqPage.tabs.tab1Content.1.ans1.4" | translate }}</li>
                      <li>{{ "content.faqPage.tabs.tab1Content.1.ans1.5" | translate }}</li>
                    </ul> -->
                    <p class="mt-3">{{ "content.faqPage.tabs.tab1Content.1.ans1.6" | translate }}</p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #aga2>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.1.ques2" | translate }}.
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !aga2?.isOpen,
                        'fa-minus-circle': aga2?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.1.ans2" | translate }}.
                    </p>
                    <p class="mb-3">

                      {{ "content.faqPage.tabs.tab1Content.1.ans2.1" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.1.ans2.2" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #aga3>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.1.ques3" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !aga3?.isOpen,
                        'fa-minus-circle': aga3?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.1.ans3" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.1.ans3.1" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #aga4>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.1.ques4" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !aga4?.isOpen,
                        'fa-minus-circle': aga4?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">{{ "content.faqPage.tabs.tab1Content.1.ans4" | translate }}</p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #aga5>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.1.ques5" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !aga5?.isOpen,
                        'fa-minus-circle': aga5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.1.ans5" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #aga6>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.1.ques6" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !aga6?.isOpen,
                        'fa-minus-circle': aga6?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.1.ans6" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #aga7>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.1.ques7" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !aga7?.isOpen,
                        'fa-minus-circle': aga7?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.1.ans7" | translate }}.
                    </p>
                  </div>
                </accordion-group>
              </accordion>

              <!-- <a href="javascript:void(0)" class="fw-bold">{{ 'content.faqPage.tabs.viewAll' | translate }}</a> -->
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 mb-4 mob-textcenter">
              <h3 class="mb-4">
                {{ "content.faqPage.tabs.tab1Content.2.title" | translate }}
              </h3>
              <accordion [closeOthers]="true" [isAnimated]="true">
                <accordion-group [panelClass]="'talyAccordion'" #agp1>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.2.ques1" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !agp1?.isOpen,
                        'fa-minus-circle': agp1?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">

                      {{ "content.faqPage.tabs.tab1Content.2.ans1" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans1.1" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans1.2" | translate }}.
                    </p>
                    <ul>
                      <li>{{ "content.faqPage.tabs.tab1Content.2.ans1.3" | translate }}</li>
                      <li>V{{ "content.faqPage.tabs.tab1Content.2.ans1.4" | translate }}</li>
                      <li>{{ "content.faqPage.tabs.tab1Content.2.ans1.5" | translate }}</li>
                    </ul>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #agp2>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.2.ques2" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !agp2?.isOpen,
                        'fa-minus-circle': agp2?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans2" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans2.1" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans2.2" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #agp3>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.2.ques3" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !agp3?.isOpen,
                        'fa-minus-circle': agp3?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans3" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans3.1" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #agp4>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.2.ques4" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !agp4?.isOpen,
                        'fa-minus-circle': agp4?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans4" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #agp5>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.2.ques5" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !agp5?.isOpen,
                        'fa-minus-circle': agp5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans5" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans5.1" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #agp6>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.2.ques6" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !agp6?.isOpen,
                        'fa-minus-circle': agp6?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans6" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans6.1" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans6.2" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #agp7>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.2.ques7" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !agp5?.isOpen,
                        'fa-minus-circle': agp5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans7" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.2.ans5.1" | translate }}.
                    </p>
                  </div>
                </accordion-group>
              </accordion>
              <!-- <a href="javascript:void(0)" class="fw-bold">{{ 'content.faqPage.tabs.viewAll' | translate }}</a> -->
            </div>
            <div class="col-lg-12 mb-4 mob-textcenter">
              <h3 class="mb-4">
                {{ "content.faqPage.tabs.tab1Content.3.title" | translate }}

              </h3>

              <accordion [closeOthers]="true" [isAnimated]="true">
                <accordion-group [panelClass]="'talyAccordion'" #ago1>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.3.ques1" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ago1?.isOpen,
                        'fa-minus-circle': ago1?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.3.ans1" | translate }}:
                    </p>
                    <ul>
                      <li>{{ "content.faqPage.tabs.tab1Content.3.ans1.1" | translate }}</li>
                      <li>{{ "content.faqPage.tabs.tab1Content.3.ans1.2" | translate }}</li>
                      <li>{{ "content.faqPage.tabs.tab1Content.3.ans1.3" | translate }}</li>
                      <li>{{ "content.faqPage.tabs.tab1Content.3.ans1.4" | translate }}
                      </li>
                    </ul>
                  </div>
                </accordion-group>
              </accordion>
              <!-- <a href="javascript:void(0)" class="fw-bold">{{ 'content.faqPage.tabs.viewAll' | translate }}</a> -->
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 mb-4 mob-textcenter">
              <h3 class="mb-4">
                {{ "content.faqPage.tabs.tab1Content.4.title" | translate }}
              </h3>
              <accordion [closeOthers]="true" [isAnimated]="true">
                <accordion-group [panelClass]="'talyAccordion'" #agr1>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.4.ques1" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !ago1?.isOpen,
                        'fa-minus-circle': ago1?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.4.ans1" | translate }}
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.4.ans1.1" | translate }}
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.4.ans1.2" | translate }}
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #agr2>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab1Content.4.ques2" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !agr2?.isOpen,
                        'fa-minus-circle': agr2?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab1Content.4.ans2" | translate }}
                    </p>
                  </div>
                </accordion-group>
              </accordion>
              <!-- <a href="javascript:void(0)" class="fw-bold">{{ 'content.faqPage.tabs.viewAll' | translate }}</a> -->
            </div>
          </div>
        </div>
        <!-- merchant tab -->
        <div class="tab-pane fade mb-5" id="tab-merchant" role="tabpanel">
          <div class="row rowmargin">
            <div class="col-lg-12 mb-4 mob-textcenter">
              <h3 class="mb-4">
                {{ "content.faqPage.tabs.tab2Content.0.title" | translate }}
              </h3>

              <accordion [closeOthers]="true" [isAnimated]="true">
                <accordion-group [panelClass]="'talyAccordion'" #mc1>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques1" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc1?.isOpen,
                        'fa-minus-circle': mc1?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-1">
                      {{ "content.faqPage.tabs.tab2Content.0.ans1" | translate }}
                    </p>
                    <!-- <p class="mb-3 nav-link">{{ "content.faqPage.tabs.tab2Content.0.ans1.1" | translate }}
                    </p> -->
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #mc2>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques2" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc2?.isOpen,
                        'fa-minus-circle': mc2?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">{{ "content.faqPage.tabs.tab2Content.0.ans2" | translate }} <a
                      routerLink="/merchant-request-form">{{"content.faqPage.tabs.tab2Content.0.ans2.1" | translate
                      }}</a>
                    {{"content.faqPage.tabs.tab2Content.0.ans2.2" | translate }}
                    <!-- <p class="mb-3">
                      <span class="nav-link" 
                       {{"content.faqPage.tabs.tab2Content.0.ans2.1" | translate }}  {{ "content.faqPage.tabs.tab2Content.0.ans2.3" | translate }}
                      </span>

                    </p> -->
                    <!-- <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.0.ans2.1" | translate }},
                      <span class="nav-link" routerLink="/forbusiness">click here</span>
                      {{ "content.faqPage.tabs.tab2Content.0.ans2.2" | translate }}
                    </p> -->
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #mc3>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques3" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc3?.isOpen,
                        'fa-minus-circle': mc3?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.0.ans3" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #mc4>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques4" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc4?.isOpen,
                        'fa-minus-circle': mc4?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.0.ans4" | translate }}
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #mc5>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques5" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc5?.isOpen,
                        'fa-minus-circle': mc5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.0.ans5" | translate }}:
                    </p>
                    <!-- <ul>
                      <li>{{ "content.faqPage.tabs.tab2Content.0.ans5.1" | translate }}:</li>
                      <li>{{ "content.faqPage.tabs.tab2Content.0.ans5.2" | translate }}:</li>
                      <li>{{ "content.faqPage.tabs.tab2Content.0.ans5.3" | translate }}:</li>
                    </ul> -->
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #mc6>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques6" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc6?.isOpen,
                        'fa-minus-circle': mc6?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.0.ans6" | translate }} <a
                        href="assets/docs/sharia-compliant.pdf" target="_blank">
                        {{ "content.faqPage.tabs.tab1Content.0.ans2.1" | translate }}
                      </a>
                    </p>
                  </div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #mc7>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques7" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc6?.isOpen,
                        'fa-minus-circle': mc6?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.0.ans7" | translate }}.
                    </p>
                  </div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #mc8>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques8" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc6?.isOpen,
                        'fa-minus-circle': mc6?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.0.ans8" | translate }}.
                    </p>
                  </div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #mc9>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques9" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc6?.isOpen,
                        'fa-minus-circle': mc6?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.0.ans9" | translate }}.
                    </p>
                  </div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #mc10>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques10" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc6?.isOpen,
                        'fa-minus-circle': mc6?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.0.ans10" | translate }}.
                    </p>
                  </div>
                </accordion-group>
                <accordion-group [panelClass]="'talyAccordion'" #mc11>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.0.ques11" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mc6?.isOpen,
                        'fa-minus-circle': mc6?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.0.ans11" | translate }}.
                    </p>
                  </div>
                </accordion-group>
              </accordion>

              <!-- <a href="javascript:void(0)" class="fw-bold">View all</a> -->
            </div>
            <!-- <div class="col-lg-12 mb-4 mob-textcenter">
              <h3 class="mb-4">
                {{ "content.faqPage.tabs.tab2Content.1.title" | translate }}
              </h3>

              <accordion [closeOthers]="true" [isAnimated]="true">
                <accordion-group [panelClass]="'talyAccordion'" #mcr1>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.1.ques1" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mcr1?.isOpen,
                        'fa-minus-circle': mcr1?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-1">{{ "content.faqPage.tabs.tab2Content.1.ans1" | translate }}.</p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.1.ans1.1" | translate }}
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #mcr2>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.1.ques2" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mcr2?.isOpen,
                        'fa-minus-circle': mcr2?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.1.ans2" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.1.ans2.1" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #mcr3>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.1.ques3" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mcr3?.isOpen,
                        'fa-minus-circle': mcr3?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.1.ans3" | translate }}.
                    </p>
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.1.ans3.1" | translate }}.
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #mcr4>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.1.ques4" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mcr4?.isOpen,
                        'fa-minus-circle': mcr4?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.1.ans4" | translate }}
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #mcr5>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.1.ques5" | translate }}
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mcr5?.isOpen,
                        'fa-minus-circle': mcr5?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.1.ans5" | translate }}
                    </p>

                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.1.ans5.1" | translate }}
                    </p>
                  </div>
                </accordion-group>

                <accordion-group [panelClass]="'talyAccordion'" #mcr6>
                  <p accordion-heading>
                    {{ "content.faqPage.tabs.tab2Content.1.ques6" | translate }}.
                    <i class="fas float-right" [ngClass]="{
                        'fa-plus-circle': !mcr6?.isOpen,
                        'fa-minus-circle': mcr6?.isOpen
                      }"></i>
                  </p>

                  <div class="answer">
                    <p class="mb-3">
                      {{ "content.faqPage.tabs.tab2Content.1.ans6" | translate }}.
                    </p>
                  </div>
                </accordion-group>
              </accordion>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- help center link  -->
    <!-- <div class="mob-textcenter">
            <h4 class="mb-4">{{ 'content.faqPage.section.title' | translate }}</h4>
            <button class="btn btn-outline-success rounded-pill fw-bold" routerLink="/contactus">{{ 'content.faqPage.section.btn' | translate }}</button>
          </div> -->
  </div>
</section>