import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[WebcamDesign]'
})
export class WebcamDesignDirective {

  constructor(private webcamElem: ElementRef, private renderer: Renderer2) { 
    setTimeout(() => {
      let videoElem= this.webcamElem.nativeElement.childNodes[0].childNodes[0];
      this.renderer.removeAttribute(videoElem, 'width');
      this.renderer.removeAttribute(videoElem, 'height');

      this.renderer.addClass(videoElem, 'w-100')
      this.renderer.addClass(videoElem, 'h-100')
      this.renderer.addClass(videoElem, 'rounded-bottom')
    }, 10);
  }

}
