<div class="container section-inner">
  <div class="WordSection1">
    <h2 class="mb-4 mob-textcenter">Privacy Policy</h2>

    <p class="Paragraph">Last modified: September 1,2022</p>

    <h6 class="CustomizableHeading">Introduction</h6>

    <p class="Paragraph">
      Taly General Trading Company WLL (<b>&quot;Company&quot;</b>
      or<b>&nbsp;&quot;We&quot;</b>) respect your privacy and are committed to
      protecting it through our compliance with this policy.
    </p>

    <p class="Paragraph">
      This policy describes the types of information we may collect from you or
      that you may provide when you visit the website www.taly.io (our
      &quot;<b>Website</b>&quot;) and our practices for collecting, using,
      maintaining, protecting, and disclosing that information.
    </p>

    <p class="Paragraph">This policy applies to information we collect:</p>

    <ul>
      <li>On this Website.</li>
      <li>
        In email, text, and other electronic messages between you and this
        Website.
      </li>
      <li>
        Through mobile and desktop applications you download from this Website,
        which provide dedicated non-browser-based interaction between you and
        this Website.
      </li>
      <li>
        When you interact with our advertising and applications on third-party
        websites and services, if those applications or advertising include
        links to this policy.
      </li>
    </ul>

    <p class="Paragraph">It does not apply to information collected by:</p>

    <ul>
      <li>
        Us offline or through any other means, including on any other website
        operated by Company or any third party; or
      </li>
      <li>
        Any third party, including through any application or content (including
        advertising) that may link to or be accessible from the Website]
      </li>
    </ul>

    <p class="Paragraph">
      Please read this policy carefully to understand our policies and practices
      regarding your information and how we will treat it. If you do not agree
      with our policies and practices, your choice is not to use our Website. By
      accessing or using this Website, you agree to this privacy policy. This
      policy may change from time to time. Your continued use of this Website
      after we make changes is deemed to be acceptance of those changes, so
      please check the policy periodically for updates.
    </p>

    <h6 class="CustomizableHeading">Children Under the Age of 16</h6>

    <p class="Paragraph">
      Our Website is not intended for children under 16 years of age. No one
      under age 16 may provide any personal information to the Website. We do
      not knowingly collect personal information from children under 16. If you
      are under 16, do not use or provide any information on this Website or
      through any of its features, register on the Website, make any purchases
      through the Website, use any of the interactive or public comment features
      of this Website, or provide any information about yourself to us,
      including your name, address, telephone number, email address, or any
      screen name or user name you may use. If we learn we have collected or
      received personal information from a child under 16 without verification
      of parental consent, we will delete that information. If you believe we
      might have any information from or about a child under 16, please contact
      us at <a href="mailto:contact@taly.io">contact@taly.io</a>.
    </p>

    <h6 class="CustomizableHeading">
      Information We Collect About You and How We Collect It
    </h6>

    <p class="Paragraph">
      We collect several types of information from and about users of our
      Website, including information:
    </p>

    <ul>
      <li>
        By which you may be personally identified, such as name, physical
        address, e-mail address, telephone number, personal identification
        number or any other identifier by which you may be contacted online or
        offline (<b>&quot;personal information&quot;</b>);
      </li>
      <li>
        That is about you but individually does not identify you, such as
        avatars, nicknames, usernames; and/or
      </li>
      <li>
        About your internet connection, the equipment you use to access our
        Website, and usage details.
      </li>
    </ul>

    <p class="Paragraph">We collect this information:</p>

    <ul>
      <li>Directly from you when you provide it to us.</li>
      <li>
        Automatically as you navigate through the site. Information collected
        automatically may include usage details, IP addresses, and information
        collected through cookies, web beacons, and other tracking technologies.
      </li>
      <li>From third parties, for example, our business partners.</li>
    </ul>

    <h6 class="CustomizableHeading">Information You Provide to Us</h6>

    <p class="Paragraph">
      The information we collect on or through our Website may include:
    </p>

    <ul>
      <li>
        Information that you provide by filling in forms on our Website. This
        includes information provided at the time of registering to use our
        Website, subscribing to our service, posting material, or requesting
        further services. We may also ask you for information when you enter a
        promotion sponsored by us, and when you report a problem with our
        Website.
      </li>
      <li>
        Records and copies of your correspondence (including email addresses),
        if you contact us.
      </li>
      <li>
        Your responses to surveys that we might ask you to complete for research
        purposes.
      </li>
      <li>
        Details of transactions you carry out through our Website and of the
        fulfillment of your orders. You may be required to provide financial
        information before placing an order through our Website.
      </li>
      <li>Your search queries on the Website.</li>
    </ul>

    <p class="Paragraph">
      You also may provide information to be published or displayed
      (hereinafter, &quot;<b>posted</b>&quot;) on public areas of the Website,
      or transmitted to other users of the Website or third parties
      (collectively, &quot;<b>User Contributions</b>&quot;). Your User
      Contributions are posted on and transmitted to others at your own risk.
      Although we limit access to certain pages, please be aware that no
      security measures are perfect or impenetrable.
    </p>

    <h6 class="CustomizableHeading">
      Information We Collect Through Automatic Data Collection Technologies
    </h6>

    <p class="Paragraph">
      As you navigate through and interact with our Website, we may use
      automatic data collection technologies to collect certain information
      about your equipment, browsing actions, and patterns, including:
    </p>

    <ul>
      <li>
        Details of your visits to our Website, including traffic data, location
        data, logs, and other communication data and the resources that you
        access and use on the Website.
      </li>
      <li>
        Information about your computer and internet connection, including your
        IP address, operating system, and browser type.
      </li>
    </ul>

    <p class="Paragraph">
      We also may use these technologies to collect information about your
      online activities over time and across third-party websites or other
      online services (behavioral tracking).
    </p>

    <p class="Paragraph">
      The information we collect automatically may include personal information.
      We may maintain it or associate it with personal information we collect in
      other ways or receive from third parties. It helps us to improve our
      Website and to deliver a better and more personalized service, including
      by enabling us to:
    </p>

    <ul>
      <li>Estimate our audience size and usage patterns.</li>
      <li>
        Store information about your preferences, allowing us to customize our
        Website according to your individual interests.
      </li>
      <li>Speed up your searches.</li>
      <li>Recognize you when you return to our Website.</li>
    </ul>

    <p class="Paragraph">
      The technologies we use for this automatic data collection may include:
    </p>

    <ul>
      <li>
        <strong>Cookies (or browser cookies).</strong> A cookie is a small file
        placed on the hard drive of your computer. You may refuse to accept
        browser cookies by activating the appropriate setting on your browser.
        However, if you select this setting you may be unable to access certain
        parts of our Website. Unless you have adjusted your browser setting so
        that it will refuse cookies, our system will issue cookies when you
        direct your browser to our Website.
      </li>
      <li>
        <strong>Flash Cookies.</strong> Certain features of our Website may use
        local stored objects (or Flash cookies) to collect and store information
        about your preferences and navigation to, from, and on our Website.
        Flash cookies are not managed by the same browser settings as are used
        for browser cookies.
      </li>
      <li>
        <strong> Web Beacons.</strong> Pages of our the Website and our e-mails
        may contain small electronic files known as web beacons (also referred
        to as clear gifs, pixel tags, and single-pixel gifs) that permit the
        Company, for example, to count users who have visited those pages or
        opened an email and for other related website statistics (for example,
        recording the popularity of certain website content and verifying system
        and server integrity).
      </li>
    </ul>

    <p class="Paragraph">
      We do not collect personal information automatically, but we may tie this
      information to personal information about you that we collect from other
      sources or you provide to us.
    </p>

    <h6 class="CustomizableHeading">
      Third-Party Use of Cookies and Other Tracking Technologies
    </h6>

    <p class="Paragraph">
      Some content or applications, including advertisements, on the Website are
      served by third-parties, including advertisers, ad networks and servers,
      content providers, and application providers. These third parties may use
      cookies alone or in conjunction with web beacons or other tracking
      technologies to collect information about you when you use our website.
      The information they collect may be associated with your personal
      information or they may collect information, including personal
      information, about your online activities over time and across different
      websites and other online services. They may use this information to
      provide you with interest-based (behavioral) advertising or other targeted
      content.
    </p>

    <p class="Paragraph">
      We do not control these third parties' tracking technologies or how they
      may be used. If you have any questions about an advertisement or other
      targeted content, you should contact the responsible provider directly.
    </p>

    <p class="CustomizableHeading" align="left" style="text-align: left">
      <a name="a183824"><u>How We Use Your Information</u></a>
    </p>

    <p class="Paragraph">
      We use information that we collect about you or that you provide to us,
      including any personal information:
    </p>

    <ul>
      <li>To present our Website and its contents to you.</li>
      <li>
        To provide you with information, products, or services that you request
        from us.
      </li>
      <li>To fulfill any other purpose for which you provide it.</li>
      <li>
        To provide you with notices about your account/subscription, including
        expiration and renewal notices.
      </li>
      <li>
        To carry out our obligations and enforce our rights arising from any
        contracts entered into between you and us, including for billing and
        collection.
      </li>
      <li>
        To notify you about changes to our Website or any products or services
        we offer or provide though it.
      </li>
      <li>
        To allow you to participate in interactive features on our Website.
      </li>
      <li>
        In any other way we may describe when you provide the information.
      </li>
      <li>For any other purpose with your consent.</li>
    </ul>
    <p class="Paragraph">
      We may also use your information to contact you about our own and
      third-parties' goods and services that may be of interest to you.
    </p>

    <p class="Paragraph">
      We may use the information we have collected from you to enable us to
      display advertisements to our advertisers' target audiences. Even though
      we do not disclose your personal information for these purposes without
      your consent, if you click on or otherwise interact with an advertisement,
      the advertiser may assume that you meet its target criteria.
    </p>

    <h6 class="CustomizableHeading">Disclosure of Your Information</h6>

    <p class="Paragraph">
      We may disclose aggregated information about our users, and information
      that does not identify any individual, without restriction.
    </p>

    <p class="Paragraph">
      We may disclose personal information that we collect or you provide as
      described in this privacy policy:
    </p>

    <ul>
      <li>To our subsidiaries and affiliates.</li>
      <li>
        To contractors, service providers, and other third parties we use to
        support our business and who are bound by contractual obligations to
        keep personal information confidential and use it only for the purposes
        for which we disclose it to them.
      </li>
      <li>
        To a buyer or other successor in the event of a merger, divestiture,
        restructuring, reorganization, dissolution, or other sale or transfer of
        some or all of the Company's assets, whether as a going concern or as
        part of bankruptcy, liquidation, or similar proceeding, in which
        personal information held by the Company about our Website users is
        among the assets transferred.
      </li>
      <li>
        To third parties to market their products or services to you if you have
        consented to these disclosures. We contractually require these third
        parties to keep personal information confidential and use it only for
        the purposes for which we disclose it to them.
      </li>
      <li>
        For any other purpose disclosed by us when you provide the information.
      </li>
      <li>With your consent.</li>
    </ul>

    <p class="Paragraph">We may also disclose your personal information:</p>

    <ul>
      <li>
        To comply with any court order, law, or legal process, including to
        respond to any government or regulatory request.
      </li>
      <li>
        To enforce or apply our
        <a routerLink="/terms-of-use">Terms of use</a> or
        <a routerLink="/terms-and-conditions">Terms of service,</a> and other
        agreements, including for billing and collection purposes.
      </li>
      <li>
        If we believe disclosure is necessary or appropriate to protect the
        rights, property, or safety of the Company, our customers, or others.
        This includes exchanging information with other companies and
        organizations for the purposes of fraud protection and credit risk
        reduction.
      </li>
    </ul>

    <h6 class="CustomizableHeading">
      Accessing and Correcting Your Information
    </h6>

    <p class="Paragraph">
      You can review and change your personal information by logging into the
      Website and visiting your account profile page.
    </p>

    <p class="Paragraph">
      You may also send us an email at
      <a href="mailto:contact@taly.io">contact@taly.io</a> to request access to,
      correct or delete any personal information that you have provided to us.
      We cannot delete your personal information except by also deleting your
      user account. We may not accommodate a request to change information if we
      believe the change would violate any law or legal requirement or cause the
      information to be incorrect.
    </p>

    <h6 class="CustomizableHeading">Data Security</h6>

    <p class="Paragraph">
      We have implemented measures designed to secure your personal information
      from accidental loss and from unauthorized access, use, alteration, and
      disclosure. All information you provide to us is stored on our secure
      servers behind firewalls. Any payment transactions will be encrypted using
      SSL technology.
    </p>

    <p class="Paragraph">
      The safety and security of your information also depends on you. Where we
      have given you (or where you have chosen) a password for access to certain
      parts of our Website, you are responsible for keeping this password
      confidential. We ask you not to share your password with anyone. We urge
      you to be careful about giving out information in public areas of the
      Website like message boards. The information you share in public areas may
      be viewed by any user of the Website.
    </p>

    <p class="Paragraph">
      Unfortunately, the transmission of information via the internet is not
      completely secure. Although we do our best to protect your personal
      information, we cannot guarantee the security of your personal information
      transmitted to our Website. Any transmission of personal information is at
      your own risk. We are not responsible for circumvention of any privacy
      settings or security measures contained on the Website.
    </p>

    <h6 class="CustomizableHeading">Changes to Our Privacy Policy</h6>

    <p class="Paragraph">
      Any amendment to our Privacy policy is subject to your prior consent and
      approval.
    </p>

    <h6 class="CustomizableHeading">Contact Information</h6>

    <p class="Paragraph">
      To ask questions or comment about this privacy policy and our privacy
      practices, contact us at:
    </p>

    <p class="Paragraph">
      <a href="mailto:contact@taly.io">contact@taly.io.</a>
    </p>
  </div>
</div>
