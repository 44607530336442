<!-- how it works page  -->
<div class="">
    <div class="text-center my-2">
        <img src="assets/images/logo/taly_logo.png"  class=" img-fluid mb-1" width="120" height="100" alt="taly">      
    </div>
    <div class="">
        <div class="howBox" style="background-color: #f4f3f2; padding: 5rem;">
            <h3 class="mb-4 mob-textcenter">{{'content.howPage.section1.title' |translate}}</h3>
            <div class="row">
                <div class="col-lg-6 pe-lg-3 pe-0 mb-lg-0 mb-4 ps-0">
                    <div class="rounded-3 p-4 text-white" style="background-color: #4586bf;">
                        <label class="small fw-bold mb-3">Online purchase </label>
                        <h4 class="mb-3">Choose your favorite brand</h4>
                        <p class="mb-4">Add what you want to your cart and choose Taly at
                            checkout. Sign up, link any card and get approved
                            instantly.</p>
                        <h4 class="mb-3">Choose Taly at the checkout</h4>
                        <p class="mb-4">Pay only 25% upfront and we'll remind you about the
                            rest. Completely free, no interest.</p>
                        <h4 class="mb-3">Pay in 4 or in 30 days</h4>
                        <p class="mb-4">Put your desired items in your shopping cart and
                            select Taly at the checkout. Sign up, link any card,
                            and get quick approval.</p>
                    </div>
                    
                </div>
                <div class="col-lg-6 px-0">
                    <div class="rounded-3 p-4 text-white" style="background-color: #53a1e6;">
                        <label class="small fw-bold mb-3">In-store purchase </label>
                        <h4 class="mb-3">Download our app</h4>
                        <p class="mb-4">Add what you want to your cart and choose Taly at 
                            checkout. Sign up, link any card and get approved 
                            instantly.</p>
                        <h4 class="mb-3">Scan your QR code</h4>
                        <p class="mb-4">Pay only 25% upfront and we'll remind you about the
                            rest. Completely free, no interest.</p>
                        <h4 class="mb-3">Pay and have fun.</h4>
                        <p class="mb-4">Put your desired items in your shopping cart and
                            select Taly at the checkout. Sign up, link any card,
                            and get quick approval.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class=" container">
        <section class="d-flex justify-content-center mt-5">
            <div class="row">
              <div class="col-lg-4 px-3 mb-2 section-action">
                <div class="p-4 pe-5 mob-textcenter">
                  <img src="assets/images/home/download.png" class="mb-3 img-fluid" alt="">
                  <h4 class=" fw-bold">{{'home.section1.title1' | translate}}</h4>
                  <p class="text-muted">{{'home.section1.caption1' | translate}}</p>
                </div>
              </div>
              <div class="col-lg-4 px-3 mb-2 section-action">
                <div class="p-4 pe-5 mob-textcenter">
                  <img src="assets/images/home/download (2).png" class="mb-3 img-fluid" alt="">
                  <h4 class=" fw-bold">{{'home.section1.title2' | translate}}</h4>
                  <p class="text-muted">{{'home.section1.caption2' | translate}}</p>
                </div>
              </div>
              <div class="col-lg-4 px-3 section-action">
                <div class="p-4 pe-5 mob-textcenter">
                  <img src="assets/images/home/download (1).png" class="mb-3 img-fluid" alt="">
                  <h4 class=" fw-bold">{{'home.section1.title3' | translate}}</h4>
                  <p class="text-muted">{{'home.section1.caption3' | translate}}</p>
                </div>
              </div>
            </div>
            <!-- section action end  -->
          </section>
    
    </div>
</div>