import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
export enum UtcToLocalTimeFormat {
  FULL = 'full', // 'EEEE, MMMM d, y, h:mm:ss a zzzz'   - Monday, June 15, 2015 at 9:03:01 AM GMT+01:00
  SHORT = 'short', // 'd/M/yy, h:mm'
}

@Injectable({
  providedIn: 'root',
})
export class UtcConverterService {
  constructor(private datePipe: DatePipe) {}

  public convertUtcToLocalTime(
    gmtDate: string, // UTC ISO-8601
    format: UtcToLocalTimeFormat = UtcToLocalTimeFormat.FULL
  ): string {
    var browserLanguage = navigator.language;
   // console.log(1 + ' => ' + gmtDate);
    if (this.isValidDate(gmtDate)) {
     // console.log(2 + ' => ' + gmtDate);

      let userAgent = navigator.userAgent;
      let browserName;
      let newDate;

      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome';
        if (format == 'full') {
          newDate = new Date(gmtDate + ':00Z');
        } else {
          newDate = new Date(gmtDate);
        }
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'firefox';
        let _date = gmtDate.replace(/ /g, 'T');
        let date = _date.replace(/\//g, '-');
        if (format == 'full') {
          newDate = new Date(date + ':00Z');
        } else {
          newDate = new Date(date);
        }
      } else if (userAgent.match(/safari/i)) {
        browserName = 'safari';
        let _date = gmtDate.replace(/ /g, 'T');
        let date = _date.replace(/\//g, '-');

        if (format == 'full') {
          newDate = new Date(date + ':00Z');
        } else {
          newDate = new Date(date);
        }
      } else if (userAgent.match(/opr\//i)) {
        browserName = 'opera';
        if (format == 'full') {
          newDate = new Date(gmtDate + ':00Z');
        } else {
          newDate = new Date(gmtDate);
        }
      } else if (userAgent.match(/edg/i)) {
        browserName = 'edge';
        if (format == 'full') {
          newDate = new Date(gmtDate + ':00Z');
        } else {
          newDate = new Date(gmtDate);
        }
      } else {
        if (format == 'full') {
          newDate = new Date(gmtDate + ':00Z');
        } else {
          newDate = new Date(gmtDate);
        }
        browserName = 'No browser detection';
      }

      if (this.isValidDate(newDate)) {
        if (format === UtcToLocalTimeFormat.SHORT) {
          let _date = this.datePipe.transform(newDate, 'dd-MM-yyyy');
          // if (this.isValidDate(_date)) {
          return _date;
          // } else {
          //   return gmtDate;
          // }
        } else if (format === UtcToLocalTimeFormat.FULL) {
       //   console.log(4 + ' => ' + newDate);
          let _date = this.datePipe.transform(newDate, 'dd-MM-yyyy hh:mm a');
        //  console.log(5 + ' => ' + _date);
          // if (this.isValidDate(_date)) {
          return _date;
          // } else {
          //   return gmtDate;
          // }

          //return _date;
        } else {
          // console.error(
          //   `Do not have logic to format utc date, format:${format}`
          // );
          return newDate.toDateString();
        }
      } else {
       // console.log(this.isValidDate(gmtDate));
        return this.isValidDate(gmtDate)
          ? this.datePipe.transform(gmtDate, 'dd-MM-yyyy hh:mm a')
          : gmtDate;
      }
    } else {
      //console.log(3 + ' => ' + gmtDate);
      return gmtDate;
    }
  }

  public convertLocalTimeToUtc(localDate: string): string {
    var date = new Date(localDate);
    return date.toUTCString();
  }

  isValidDate(dateString) {
  //  console.group();
  //  console.log('isValidDate');
    let validationResult;
    const inputDate = new Date(dateString);
  //  console.log(inputDate);

    if (inputDate.toString() === 'Invalid Date') {
      validationResult = false;
    //  console.log('Invalid Date');
    } else {
      validationResult = true;
   //   console.log('valid Date');
    }

 //   console.log(validationResult);
  //  console.groupEnd();

    return validationResult;
  }
}
