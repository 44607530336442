<!-- marchant request form step-1  -->
<div class="container section-inner">
    <div class="row">
        <!-- colume-1 for request form goes here  -->
        <div class="col-sm-6">

            <!-- <app-form1></app-form1> -->
        </div>


        <!-- colume-2 => section for talypay join benifits section  -->
        <div class="col-sm-6">
            <!-- => section for talypay join benifits section  -->
            <!-- <app-talypay-benifits></app-talypay-benifits> -->
        </div>

        <!-- full form component is here  -->
        <app-form2></app-form2>
        

        <!-- row end here  -->
    </div>
</div>
