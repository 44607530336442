import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  AnnualSale,
  AverageOrder,
} from 'src/app/Core/models/lov/annualSales.model';
import {
  BusinessType,
  Platform,
} from 'src/app/Core/models/lov/businesType.model';
import { Country } from 'src/app/Core/models/lov/country.model';
import { Industry } from 'src/app/Core/models/lov/industry.model';
import { MerchantProfile } from 'src/app/Core/models/merchantProfile/signup.model';
import { SignupModel } from 'src/app/Core/models/signup.model';
import { ConsumerLovService } from 'src/app/Core/services/lov/consumer-lovs.service';
import { SignupService } from 'src/app/Core/services/merchantProfile/signup.service';
import { TextDirectionController } from 'src/app/utilities/textDirectionController';
import {
  InvisibleReCaptchaComponent,
  NgxCaptchaModule,
  ReCaptcha2Component,
} from 'ngx-captcha';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form2',
  templateUrl: './form2.component.html',
  styleUrls: ['./form2.component.scss'],
})
export class Form2Component implements OnInit {
  @ViewChild('captchaElem', { static: false })
  captchaElem: InvisibleReCaptchaComponent;

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private lov: ConsumerLovService,
    private signupService: SignupService
  ) {}

  directionController = new TextDirectionController();
  merchantSignUpForm: FormGroup;
  success: string;
  error: string;
  countries: Country[] = [];
  cities: any[] = [];
  industries: Industry[] = [];
  businessTypes: BusinessType[] = [];
  annualSales: AnnualSale[] = [];
  averageOrders: AverageOrder[] = [];
  platforms: Platform[] = [];
  formData: any = {
    status: 'PENDING',
    businessName: <string>null,
    countryId: <number>null,
    industryId: <number>null,
    otherIndustry: <string>null,
    businessType: <string>null,
    businessEmail: <string>null,
    contactNumber: <string>null,
    url: <string>null,
    platfomId: <number>null,
    otherPlatfom: <string>null,
    annualSalesId: <number>null,
    averageOrderValueId: <number>null,
    countryNameEn: <string>null,
    averageOrderValueNameEn: <string>null,
    annualSaleNameEn: <string>null,
    platformNameEn: <string>null,
    categoryNameEn: <string>null,
    recaptcha: <string>null,
  };

  siteKey: string;
  captchaLang: string;
  isOtherPlatformName: boolean = false;
  isOtherIndustryName: boolean = false;
  urlRegEx = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  ngOnInit(): void {
    this.siteKey = environment.siteKey;
    this.captchaLang = this.directionController.isLTR() ? 'en' : 'ar';

    this.lov
      .getCountries()
      .subscribe(
        (res) => (this.countries = res.filter((elem) => elem.status == '1'))
      );
    this.lov
      .getCategory()
      .subscribe(
        (res) => (this.industries = res.filter((elem) => elem.status == '1'))
      );
    this.lov
      .getBusinessTypes()
      .subscribe(
        (res) => (this.businessTypes = res.filter((elem) => elem.status == '1'))
      );
    this.lov
      .getAnnualSales()
      .subscribe(
        (res) => (this.annualSales = res.filter((elem) => elem.status == '1'))
      );
    this.lov
      .getAverageOrders()
      .subscribe(
        (res) => (this.averageOrders = res.filter((elem) => elem.status == '1'))
      );
    this.lov
      .getPlatforms()
      .subscribe(
        (res) => (this.platforms = res.filter((elem) => elem.status == '1'))
      );

    this.merchantSignUpForm = new FormGroup({
      businessDetails: new FormGroup({
        businessName: new FormControl(null, [
          Validators.required,
          this.noWhitespaceValidator,
        ]),
        country: new FormControl(null, [
          Validators.required,
          this.noWhitespaceValidator,
        ]),
        industry: new FormControl(null, [
          Validators.required,
          this.noWhitespaceValidator,
        ]),
        otherIndustry: new FormControl(null),
        businessType: new FormControl(null, [
          Validators.required,
          this.noWhitespaceValidator,
        ]),
      }),

      contactInfo: new FormGroup({
        businessEmail: new FormControl(null, [
          Validators.required,
          Validators.email,
          this.noWhitespaceValidator,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ]),
        contactNumber: new FormControl(null, [
          Validators.required,
          this.noWhitespaceValidator,
          Validators.pattern('[1-9]\\d{7}'),
        ]),
      }),

      website: new FormGroup({
        webURL: new FormControl(null, [
          Validators.required,
          //Validators.pattern(this.urlRegEx),
          Validators.pattern(
            '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
          ),
          this.noWhitespaceValidator,
        ]),
        platform: new FormControl(null, [
          //   Validators.required,
          this.noWhitespaceValidator,
        ]),
        otherPlatfom: new FormControl(null),
      }),

      sales: new FormGroup({
        annualSales: new FormControl(null, [Validators.required]),
        averageOrder: new FormControl(null, [Validators.required]),
      }),

      captcha: new FormControl(null),
    });

    this.translate
      .get('toastr.success')
      .subscribe((text) => (this.success = text));
    this.translate.get('toastr.error').subscribe((text) => (this.error = text));

    this.merchantSignUpForm
      .get('website')
      .get('platform')
      .valueChanges.subscribe((val) => {
        if (
          this.merchantSignUpForm.get('businessDetails')?.get('businessType')
            ?.value != 'INSTORE'
        ) {
          if (val == 'Other' || val == 'Others') {
            this.merchantSignUpForm
              .get('website')
              .get('otherPlatfom')
              .setValidators(Validators.required);
            this.isOtherPlatformName = true;
          } else {
            this.merchantSignUpForm
              .get('website')
              .get('otherPlatfom')
              .clearValidators();
            this.isOtherPlatformName = false;
          }
        } else {
          this.merchantSignUpForm
            .get('website')
            .get('otherPlatfom')
            .clearValidators();
          this.isOtherPlatformName = false;
        }
      });

    this.merchantSignUpForm
      .get('businessDetails')
      .get('industry')
      .valueChanges.subscribe((val) => {
        if (val == 'Other' || val == 'Others') {
          this.merchantSignUpForm
            .get('businessDetails')
            .get('otherIndustry')
            .setValidators(Validators.required);
          this.isOtherIndustryName = true;
        } else {
          this.merchantSignUpForm
            .get('businessDetails')
            .get('otherIndustry')
            .clearValidators();
          this.isOtherIndustryName = false;
        }
      });
  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  //set areas according to selected country
  onSelectCountry() {
    let iso = this.getSelectedElem(
      this.countries,
      this.merchantSignUpForm.get('businessDetails').get('country').value
    ).isoCode;
    this.lov.getCities(iso).subscribe((res) => {
      this.cities = res.filter((elem) => elem.status == '1');
      this.merchantSignUpForm
        .get('headOffice')
        .get('area')
        .setValue(
          this.directionController.isLTR()
            ? this.cities[0].nameEn
            : this.cities[0].nameAr
        );
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  emailInputClick: boolean;
  urlInputClick: boolean;
  onClickOutside(InvalidType) {
    switch (InvalidType) {
      case 'email':
        this.merchantSignUpForm.get('contactInfo').get('businessEmail').value
          ? (this.emailInputClick = true)
          : (this.emailInputClick = false);
        break;

      case 'url':
        this.merchantSignUpForm.get('website').get('webURL').value
          ? (this.urlInputClick = true)
          : (this.urlInputClick = false);
        break;

      default:
        break;
    }
  }

  get s_url() {
    return this.merchantSignUpForm.get('website').get('webURL');
  }

  isCaptchaCorrect: boolean;
  onCaptchaChange() {
    this.isCaptchaCorrect = true;
  }
  get f() {
    return this.merchantSignUpForm.controls;
  }
  onSubmit() {
    this.formData.recaptcha = this.captchaResponse;
    console.log(this.captchaResponse);
    console.log(this.formData.recaptcha);
    
    if (this.merchantSignUpForm.valid) {
      this.formData.businessName = this.merchantSignUpForm
        .get('businessDetails')
        ?.get('businessName')?.value;

      this.formData.countryId = this.getSelectedElem(
        this.countries,
        this.merchantSignUpForm?.get('businessDetails')?.get('country')?.value
      ).isoCode;

      const countryName: any = this.countries.filter(
        (country) => country.isoCode == this.formData.countryId
      )[0]?.['nameEn'];

      this.formData.countryNameEn = countryName;

      this.formData.industryId = this.getSelectedElem(
        this.industries,
        this.merchantSignUpForm?.get('businessDetails')?.get('industry')?.value
      ).id;

      if (this.formData.industryId) {
        const industryId: any = this.industries.filter(
          (industry) => industry.id == this.formData.industryId
        )[0]?.['nameEn'];
        this.formData.categoryNameEn = industryId;
      }

      this.formData.businessType = this.merchantSignUpForm
        .get('businessDetails')
        ?.get('businessType')?.value;
      this.formData.businessEmail = this.merchantSignUpForm
        .get('contactInfo')
        ?.get('businessEmail')?.value;
      this.formData.contactNumber = this.merchantSignUpForm
        .get('contactInfo')
        ?.get('contactNumber')?.value;
      this.formData.url = this.merchantSignUpForm
        .get('website')
        ?.get('webURL')?.value;
      if (this.merchantSignUpForm.get('website')?.get('platform')?.value) {
        this.formData.platfomId = this.getSelectedElem(
          this.platforms,
          this.merchantSignUpForm.get('website')?.get('platform')?.value
        ).id;

        if (this.formData.platfomId) {
          const platformName: any = this.platforms.filter(
            (platform) => platform.id == this.formData.platfomId
          )[0]?.['nameEn'];
          this.formData.platformNameEn = platformName;
        }
      }

      this.formData.otherIndustry = this.merchantSignUpForm
        .get('businessDetails')
        ?.get('otherIndustry')?.value;
      this.formData.otherPlatfom = this.merchantSignUpForm
        .get('website')
        ?.get('otherPlatfom')?.value;
      if (this.merchantSignUpForm?.get('sales')?.get('annualSales')?.value) {
        this.formData.annualSalesId = this.getSelectedElem(
          this.annualSales,
          this.merchantSignUpForm?.get('sales')?.get('annualSales')?.value
        ).id;

        if (this.formData.annualSalesId) {
          const annualSaleName: any = this.annualSales.filter(
            (annualSale) => annualSale.id == this.formData.annualSalesId
          )[0]?.['nameEn'];
          this.formData.annualSaleNameEn = annualSaleName;
        }
      }

      if (this.merchantSignUpForm?.get('sales').get('averageOrder')?.value) {
        this.formData.averageOrderValueId = this.getSelectedElem(
          this.averageOrders,
          this.merchantSignUpForm?.get('sales').get('averageOrder')?.value
        ).id;

        if (this.formData.averageOrderValueId) {
          const averageOrderValueName: any = this.averageOrders.filter(
            (averageOrder) =>
              averageOrder.id == this.formData.averageOrderValueId
          )[0]?.['nameEn'];
          this.formData.averageOrderValueNameEn = averageOrderValueName;
        }
      }
console.log(this.formData);

      //sending data to api:
      this.signupService.postInterestRequest(this.formData).subscribe(
        (res) => {
          this.isCaptchaCorrect = true;
          this.urlInputClick = false;
          this.emailInputClick = false;

          this.toastr.success(
            'Your request has been submitted successfully',
            this.success
          );
          this.captchaElem.resetCaptcha();
          console.log('reset');

          this.merchantSignUpForm.reset();
          this.ngOnInit();
        },
        (err) => {
          this.isCaptchaCorrect = false;
          this.toastr.error(err.error.message, this.error);
        }
      );
      // grecaptcha.reset();
    }
  }

  //get selected value
  getSelectedElem(dataArray: any[], value): any {
    let isArabic: boolean;
    this.directionController.isLTR() ? (isArabic = false) : (isArabic = true);
    let element;
    if (isArabic)
      dataArray.forEach((elem) => {
        if (elem.nameAr == value) element = elem;
      });
    else
      dataArray.forEach((elem) => {
        if (elem.nameEn == value) element = elem;
      });

    return element;
  }

  isNotInStore: boolean;
  onBusinessType(businessType) {
    switch (businessType) {
      case 'Online':
        this.isNotInStore = true;
        this.merchantSignUpForm
          .get('website')
          .get('webURL')
          .setValidators([Validators.required, this.noWhitespaceValidator]);
        this.merchantSignUpForm
          .get('website')
          .get('platform')
          .setValidators([Validators.required]);
        break;
      case 'In-Store':
        this.isNotInStore = false;
        this.merchantSignUpForm.updateOn;
        this.merchantSignUpForm.get('website').get('webURL').clearValidators();
        this.merchantSignUpForm
          .get('website')
          .get('platform')
          .clearValidators();
        break;
      case 'Both':
        this.isNotInStore = true;
        this.merchantSignUpForm.updateOn;
        this.merchantSignUpForm
          .get('website')
          .get('webURL')
          .setValidators([Validators.required, ,]);
        this.merchantSignUpForm
          .get('website')
          .get('platform')
          .setValidators([Validators.required]);
        break;

      default:
        this.merchantSignUpForm.get('website').get('webURL').clearValidators();
        this.merchantSignUpForm
          .get('website')
          .get('platform')
          .clearValidators();
        break;
    }
    this.merchantSignUpForm
      .get('website')
      .get('webURL')
      .updateValueAndValidity();
    this.merchantSignUpForm
      .get('website')
      .get('platform')
      .updateValueAndValidity();
  }

  public captchaSuccess = false;
  public captchaResponse?: string;

  execute(): void {
    this.captchaElem.execute();
  }

  handleSuccess(captchaResponse: string): void {
    this.merchantSignUpForm?.get('captcha')?.setValue(captchaResponse);
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
  }
}
