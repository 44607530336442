import { Component, OnInit } from '@angular/core';
import { TextDirectionController } from 'src/app/utilities/textDirectionController';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent implements OnInit {
  constructor() {}
  directionController = new TextDirectionController();

  ngOnInit(): void {}

  mobileWidth = window.matchMedia('(max-width: 991px)');
  navTabSwipper: SwiperOptions = {
    slidesPerView: this.mobileWidth.matches ? 2 : 8,
    spaceBetween: 30,
    // navigation: {
    //   nextEl: '.nextNav',
    //   prevEl: '.prevNav'
    // },
    scrollbar: true,
    grabCursor: true,
  };

  sensibleConfig: SwiperOptions = {
    navigation: {
      nextEl: '.nextNav',
      prevEl: '.prevNav',
    },
    spaceBetween: 30,
    slidesPerView: this.mobileWidth.matches ? 2 : 4,
    loop: true,
    keyboard: true,
    grabCursor: true,
    fadeEffect: {
      crossFade: true,
    },
  };
}
