<ngx-loading-bar></ngx-loading-bar>
<div class="mainOuter__style">
  <div
    [ngClass]="'currentTheme' | translate"
    [dir]="directionController.textDirection"
  >
    <ng-container *ngIf="isDeveloperDocs == false; else developerTemplate">
      <ng-container *ngIf="isCountrySelected">
        <app-header-home
          [isHomePage]="isHomePage"
          *ngIf="
            !(
              this.route.url.includes('/checkout') ||
              this.route.url.includes('/how-it-works') ||
              this.route.url.includes('/securecheckout') ||
              isPluginPage ||
              this.route.url.includes('/consumer/knet/status') ||
              orderId
            )
          "
        ></app-header-home>
        <ngx-ui-loader></ngx-ui-loader>

        <!-- outlet div  -->
        <div
          [ngClass]="
            isHowitWorksPage || isHomePage
              ? 'position-relative'
              : 'position-relative main-app-body'
          "
        >
          <!-- loader  -->
          <div
            class="position-absolute bg-light d-flex justify-content-center fadeOut"
            id="load"
            #load
          >
            <!-- loading spinner  -->
            <div class="lds-facebook text-secondary">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <!-- outlet  -->
          <router-outlet #outlet="outlet" (activate)="onActivateRoute(outlet)">
          </router-outlet>

          <!-- Modal -->
          <div
            class="modal fade"
            id="userIdleModel"
            tabindex="-1"
            aria-labelledby="userIdleModel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="userIdleModelModelLabel">
                    Session timeout
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    (click)="confirmTimeOut(false)"
                  ></button>
                </div>
                <div class="modal-body">
                  Are you sure, you want to delete your card?
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-success"
                    data-bs-dismiss="modal"
                    (click)="confirmTimeOut(true)"
                  >
                    Yes! Confirm
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                    (click)="confirmTimeOut(false)"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-footer
          class="footer"
          *ngIf="
            !(
              this.route.url.includes('/checkout') ||
              this.route.url.includes('/how-it-works') ||
              this.route.url.includes('/securecheckout') ||
              isPluginPage ||
              this.route.url.includes('/consumer/knet/status') ||
              orderId
            )
          "
        >
        </app-footer>
      </ng-container>
    </ng-container>
    <ng-template #developerTemplate>
      <app-sidebar
        [isExpanded]="sidebarExpanded"
        (toggleSidebar)="sidebarExpanded = !sidebarExpanded"
      >
      </app-sidebar>
      <div
        class="content developer-class"
        [ngClass]="{ sidebarExpanded: sidebarExpanded }"
      >
        <router-outlet #outlet="outlet" (activate)="onActivateRoute(outlet)">
        </router-outlet>
      </div>
    </ng-template>
  </div>
</div>
