import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import Swal from "sweetalert2";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup;
  constructor(public route: Router,) { }

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      //  category: new FormControl('Customer',[ Validators.required]),
      yourname: new FormControl(null, [Validators.required]),
      mobile: new FormControl(null, [Validators.required]),
      subject: new FormControl(null, [Validators.required]),
      message: new FormControl(null, Validators.required)
    })
  }

  onSubmit() {
    Swal.fire({
      showCloseButton: true,
      confirmButtonText: 'Continue',

      icon: 'success',
      title: 'Thank you for contacting us will get back to you shortly with great news!',
      showConfirmButton: true,
      // timer: 1500
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.route.navigate(['/dashboard']);
      }
    })
  }
}
