<!-- <p>step1 works!</p> -->
<h4>Your order summery</h4>

<div class="row">
    <div class="col-lg-8 mt-3 mt-lg-0">
        <div class="d-flex flex-wrap align-items-center my-4">
            <h6 class="me-3 ms-0 fw-bold w-25 tW">Order Amount</h6>
            <h5 class="fw-bold text-secondary"><span class="me-2 ms-0">BHD</span>120.000</h5>
        </div>
        <div class="d-flex flex-wrap align-items-center my-4">
            <h6 class="me-3 ms-0 fw-bold w-25 tW">Merchant</h6>
            <h5 class="fw-bold">H&M</h5>
        </div>
    </div>
    <div class="col-lg-4">
        <img src="" alt="">
    </div>
</div>

<section *ngIf="!isloginned">
    <div class="mb-3">
        <h5 class=" fw-bold">Split in 4</h5>
        <app-split4 *ngIf="isSplit4" [installments]="installments"></app-split4>
        <app-pay30 *ngIf="!isSplit4" [installments]="installments"></app-pay30>
    </div>

    <div style="margin-top: 10rem;">
        <button class="btn btn-dark w-100" (click)='onProceed()'>Proceed</button>
    </div>
</section>

<app-payment-plan *ngIf="isloginned"></app-payment-plan>