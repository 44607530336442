import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-box',
  templateUrl: './success-box.component.html',
  styleUrls: ['./success-box.component.scss']
})
export class SuccessBoxComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    
  
  }

}
