import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { SignupService } from 'src/app/Core/services/signup/signup.service';
import { Router } from '@angular/router';
import { log } from 'console';

@Component({
  selector: 'app-mobile-id-checkout',
  templateUrl: './mobile-id-checkout.component.html',
  styleUrls: ['./mobile-id-checkout.component.scss'],
})
export class MobileIdCheckoutComponent implements OnInit {
  @Output() moveStep = new EventEmitter<number>();

  showMessage: boolean = false;
  showCustInfo: boolean = false;
  showReject: boolean = false;
  interval: any;
  timerInterval: any;
  @Input() nationalId: any;

  constructor(private signupService: SignupService, private router: Router) {}

  ngOnInit(): void {
    this.timer(5);

    this.interval = setInterval(() => {
      this.requestStatus();
    }, 5000);
  }
  back() {}

  displayTimer: any;
  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.displayTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(this.timerInterval);
        clearInterval(this.interval);
        this.router.navigate(['/consumer/login']);
        this.moveStep.emit(1);
      }
    }, 1000);
  }

  requestStatus() {
    const requestStatusDetails: any = {
      requestId: sessionStorage.getItem('reqID'),
      nationalId: sessionStorage.getItem('nationalId'),
    };
    this.signupService
      .verifyMobileIdStatus(requestStatusDetails)
      .subscribe((res) => {
        if (res.status == 'APPROVED') {
          this.signupService.setConsumerDetails(res?.details);
          this.showMessage = true;
          this.displayTimer = undefined;
          clearInterval(this.interval);
          clearInterval(this.timerInterval);
          setTimeout(() => {
            this.moveStep.emit(12);
          }, 5000);
        }
        if (res.status == 'DECLINED') {
          this.showReject = true;
          clearInterval(this.timerInterval);
          this.displayTimer = undefined;
          clearInterval(this.interval);
        }
      });
  }
  backOnNationalId() {
    this.moveStep.emit(10);
    this.showReject = false;
    clearInterval(this.timerInterval);
    clearInterval(this.interval);
  }
}
