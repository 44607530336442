<div
  [ngClass]="
    !isDashboardPage
      ? 'featureMerchants__style'
      : 'featureMerchants__style_dashboard'
  "
  class=""
>
  <h3 class="featureMerchangt__Heading">
    {{ "consumer.dashboardPage.section5.title" | translate }}
  </h3>
  <div class="swiper_area">
    <swiper [config]="sensibleConfig">
      <button
        class="swiper-button-prev bg-white border-0 prevNav carousel_button"
      ></button>
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let merchant of featuredMerchants">
          <div class="item img-con">
            <img
              src="assets/merchants-logo/{{ merchant.logo }}"
              alt="{{ merchant.logo }}"
            />
            <div class="overlay w-100">
              <a
                href="{{ merchant.url }}"
                target="_blank"
                class="img-txt btn px-3 bg-success rounded-3"
              >
                Visit the website
              </a>
            </div>
          </div>
        </div>
      </div>

      <button
        class="swiper-button-next bg-white border-0 nextNav carousel_button"
      ></button>
    </swiper>
  </div>

  <div *ngIf="!isDashboardPage">
    <p class="merchhentTxt" style=" white-space: break-spaces;">
    
      {{"home.section7.title1" | translate}}
    </p>
    <div class="btnsOuter">
      <button class="cmnBtns" routerLink="/forbusiness">LEARN MORE</button>
      <button class="cmnBtns" routerLink="/merchant-request-form">
        MERCHANT FORM
      </button>
    </div>
  </div>
</div>

<!-- <div class="featureMerchants__style">
    <h3 class="featureMerchangt__Heading">
        Featured Merchants
    </h3>
    <div class="owl-carousel" >
      
        <div class="item img-con"><img src="../../../assets/images-new/Zara-Logo 3.png" alt="">
          <div class="overlay w-100">
            <a href="https://www.zara.com/" target="_blank" class="img-txt btn px-3 bg-success rounded-3">Visit the website</a>
          </div></div>
        <div class="item img-con"><img src="../../../assets/images-new/HM-Logo 6.png" alt="">
          <div class="overlay w-100">
            <a href="https://www.hm.com/" target="_blank" class="img-txt btn px-3 bg-success rounded-3">Visit the website</a>
          </div>
        </div>
        <div class="item img-con"><img src="../../../assets/images-new/Ikea_logo 6.png" alt="">
          <div class="overlay w-100">
            <a href="https://www.ikea.com/" target="_blank"  class="img-txt btn px-3 bg-success rounded-3">Visit the website</a>
          </div>
        </div>
        <div class="item img-con"><img src="../../../assets/images-new/xcite-logo 6.png" alt="">
          <div class="overlay w-100">
            <a href="https://www.xcite.com/" target="_blank" class="img-txt btn px-3 bg-success rounded-3">Visit the website</a>
          </div>
        </div>
        <img src="../../../../assets/images-new/Arrow Right.png" alt="">
    </div>
    <div *ngIf="!isDashboardPage" >
    <p class="merchhentTxt">Are you a merchant?<br/>
        Add Taly to your store to let customers <br/>
        split their payments! </p>
        <div class="btnsOuter">
            <button class="cmnBtns" routerLink="/forbusiness" >LEARN MORE</button>
            <button class="cmnBtns"   routerLink="/merchants/request-form"> MERCHANT FORM </button>
        </div>
      </div>
</div> -->
