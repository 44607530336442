import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import {
  CommonModule,
  getLocaleNumberSymbol,
  LOCATION_INITIALIZED,
  NumberSymbol,
  registerLocaleData,
} from '@angular/common';
// import ngx-translate and the http loader
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeArKw from '@angular/common/locales/ar-KW';
import { InternationalizationModule } from './internationalization/internationalization.module';

registerLocaleData(localeArKw, 'ar-KW');

getLocaleNumberSymbol('ar-KW', NumberSymbol.CurrencyGroup);
// // returns decimal separator, dot (.) in this case:
getLocaleNumberSymbol('ar-KW', NumberSymbol.CurrencyDecimal);

@NgModule({
  declarations: [],
  imports: [
    InternationalizationModule,
    // ngx-translate and the loader module
    HttpClientModule,

    TranslateModule,
  ],
  exports: [HttpClientModule, TranslateModule],
})
export class LangTranslateModule {}
