<div class="container mob-textcenter mb-2">
    <h2 class="mb-2">Shop with us</h2>
    <!-- <h6 class=" text-muted">At the checkout, taly will be available.</h6> -->
    <h6 class=" text-muted mt-3">Categories</h6>
  </div>

  <div class="container mob-textcenter">
    <swiper [config]="sensibleConfig">
      <!-- Add Arrows -->
      <div class="d-flex justify-content-end mb-3 mt-lg-0 mt-2">
        <button class=" me-3 bg-white border-0 prevNav"><i class="text-primary"
            [ngClass]="directionController.isLTR()?'fal fa-arrow-left':'fal fa-arrow-right'"></i></button>
        <button class=" bg-white border-0  nextNav"><i class="text-primary"
            [ngClass]="directionController.isLTR()?'fal fa-arrow-right':'fal fa-arrow-left'"></i></button>
      </div>
      <!-- swiper  -->
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="  cursor-pointer">
            <div class="img-con">
              <img src="assets/images/home/Rectangle 32.png" class="img-fluid mb-3 swiper-image" alt="">
            </div>

            <h5 class="fw-bold">CLOTHING</h5>

          </div>
        </div>
        <div class="swiper-slide">
          <div class=" cursor-pointer ">
            <div class="img-con">
              <img src="assets/images/home/Rectangle 33.png" class="img-fluid mb-3 swiper-image" alt="">
            </div>
            <h5 class="fw-bold">ELECTRONIC</h5>

          </div>
        </div>
        <div class="swiper-slide">
          <div class=" cursor-pointer">
            <div class="img-con">
              <img src="assets/images/home/Rectangle 34.png" class="img-fluid mb-3 swiper-image" alt="">
            </div>
            <h5 class="fw-bold">FURNITURE</h5>

          </div>
        </div>


      </div>
    </swiper>
  </div>
