import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckoutService } from 'src/app/Core/services/customer-checkout/checkout.service';
import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { AuthenticationService } from 'src/app/Core/services/signin/auth.service';
import { MerchantLoginService } from '../../secure-checkout/merchant-login.service';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {
  constructor(
    private dataService: DataServiceService,
    private routes: Router,
    private checkoutService: CheckoutService,
    private merchantLogin: MerchantLoginService,
    private authService: AuthenticationService
  ) {}
  @Output() moveStep = new EventEmitter<number>();
  orderToken: string;

  isSplit4: boolean;
  installments: any[];
  ngOnInit(): void {
    this.orderToken = this.routes.url.slice(
      this.routes.url.lastIndexOf('/') + 1
    );
    this.checkoutService.getCalcPayment(this.orderToken).subscribe((res) => {
      res.length > 2 ? (this.isSplit4 = true) : (this.isSplit4 = false);
      this.installments = res;
      this.dataService.emitOrderToken(this.orderToken);
    });

    this.authService.isLoggedIn() &&
    sessionStorage.getItem('login') == 'consumer'
      ? (this.isloginned = true)
      : (this.isloginned = false);
  }

  isloginned: boolean;
  onProceed() {
    this.merchantLogin.logout();
    sessionStorage.removeItem('JWT_TOKEN');
    sessionStorage.removeItem('REFRESH_TOKEN');
    if (this.authService.isLoggedIn()) {
      this.isloginned = true;
      this.dataService.checkoutSignupEmit(false);
    } else {
      this.isloginned = false;
      this.dataService.checkoutSignupEmit(true);
      this.dataService.emitOrderToken(this.orderToken);
      this.routes.navigateByUrl('/consumer/login');
    }
  }
}
