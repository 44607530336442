import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { LocalizationService } from 'src/app/internationalization/localization.service';
import { TextDirectionController } from 'src/app/utilities/textDirectionController';
import { SwiperOptions } from 'swiper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  navtabsClass: string[] = [];
  isFaqOpen: boolean[] = [];
  showSingupBtn: boolean = true;
  mobileWidth = window.matchMedia('(max-width: 991px)');

  directionController = new TextDirectionController();

  constructor(
    private dataService: DataServiceService,
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      this.showSingupBtn = sessionStorage.getItem('JWT_TOKEN') ? false : true;
    });
  }

  ngOnInit(): void {
    this.showSingupBtn = sessionStorage.getItem('JWT_TOKEN') ? false : true;
    this.navtabsClass = ['navTabActive', ''];
    this.isFaqOpen = [false, false, false];
  }
  ngOnDestroy() {}

  onAuth(mode) {
    if (mode == 'login') {
      this.dataService.headerClickLogin(true);
      if (sessionStorage.getItem('step') != null) {
        sessionStorage.removeItem('step');
        window.location.reload();
      }
    } else this.dataService.headerClickLogin(false);
  }

  onNavTab(num: number) {
    switch (num) {
      case 1:
        this.navtabsClass = ['navTabActive', ''];
        break;

      case 2:
        this.navtabsClass = ['', 'navTabActive'];
        break;

      default:
        break;
    }
  }

  onFaqItem(num: number) {
    switch (num) {
      case 1:
        if (!this.isFaqOpen[0]) {
          this.isFaqOpen[0] = true;
        } else {
          this.isFaqOpen[0] = false;
        }
        break;

      case 2:
        if (!this.isFaqOpen[1]) {
          this.isFaqOpen[1] = true;
        } else {
          this.isFaqOpen[1] = false;
        }
        break;

      case 3:
        if (!this.isFaqOpen[2]) {
          this.isFaqOpen[2] = true;
        } else {
          this.isFaqOpen[2] = false;
        }
        break;

      default:
        break;
    }
  }

  sensibleConfig: SwiperOptions = {
    navigation: {
      nextEl: '.nextNav',
      prevEl: '.prevNav',
    },
    spaceBetween: 30,
    slidesPerView: this.mobileWidth.matches ? 1 : 3,
    loop: true,
    keyboard: true,
    grabCursor: true,
    fadeEffect: {
      crossFade: true,
    },
  };

  removeWrapper(): void {
    const wrapper = this.el.nativeElement.querySelector('.swiper-wrapper');
    const parent = this.renderer.parentNode(wrapper);
    this.renderer.removeChild(parent, wrapper);
  }
}
