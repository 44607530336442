import { FooterComponent } from './footer/footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderHomeComponent } from './header-home/header-home.component';
import { CustomDirectivesModule } from '../Core/custom-directives/custom-directives.module';
import { DataServiceService } from '../Core/services/data-service.service';
import { LangTranslateModule } from '../lang-translate.module';
import { ComponentsModule } from '../Core/components/components.module';

@NgModule({
  declarations: [FooterComponent, HeaderHomeComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomDirectivesModule,
    LangTranslateModule,
    ComponentsModule,
  ],
  exports: [FooterComponent, HeaderHomeComponent],
  // providers: [
  //   DataServiceService
  // ]
})
export class LayoutModule {}
