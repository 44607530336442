import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService } from 'src/app/internationalization/localization.service';
import { DataServiceService } from '../../services/data-service.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit {
  constructor(
    private dataService: DataServiceService,
    private readonly translate: TranslateService,
    private localizationService: LocalizationService,
    private router: Router
  ) {}
  language: string = 'english';
  pageName: string;
  defaultPageURl: string;
  url: string;
  cutURL: string;
  pathName: string;
  arURL: string;

  ngOnInit(): void {
    this.translate.setDefaultLang(sessionStorage.getItem('lang'));
    sessionStorage.getItem('lang') == 'en-US'
      ? (this.language = 'english')
      : (this.language = 'arabic');

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.url = this.router.url;
        if (sessionStorage.getItem('lang') == 'ar-KW') {
          if (!this.url.includes('/ar/')) {
            this.arURL = this.url.substring(3);
            this.arURL = '/ar' + this.url;
          }
          this.router.navigate([this.arURL]);
        } else {
          this.defaultPageURl = this.arURL.substring(3);
        }
      }
    });
  }

  onLanguageSelect() {
    if (this.language == 'english') {
      sessionStorage.setItem('lang', 'en-US');
      this.localizationService.initService();
      this.dataService.languageControl.next('en-US');
    } else if (this.language == 'arabic') {
      sessionStorage.setItem('lang', 'ar-KW');
      this.localizationService.initService();
      this.dataService.languageControl.next('ar-KW');
    }

    window.location.reload();
  }
}
