import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { AuthenticationService } from 'src/app/Core/services/signin/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/Core/interceptors/token-storage.service';

@Component({
  selector: 'app-checkout-pages',
  templateUrl: './checkout-pages.component.html',
  styleUrls: ['./checkout-pages.component.scss'],
})
export class CheckoutPagesComponent implements OnInit {
  @ViewChild('template') template: TemplateRef<any>;
  modalRef?: BsModalRef;
  constructor(
    private toastr: ToastrService,
    private modalService: BsModalService,
    private dataService: DataServiceService,
    private authService: AuthenticationService,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private tokenStorageService: TokenStorageService
  ) {}

  isLoggedIn: boolean;
  isPaymentSuccess: string;
  ngOnInit(): void {
    const orderToken = this.activeRoute.snapshot.paramMap.get('id');

    if (orderToken) {
      this.activeRoute.queryParams.subscribe((params) => {
        this.isLoggedIn = this.authService.isLoggedIn();

        if (params['lg'] !== undefined && params['st'] !== undefined) {
          this.isLoggedIn = params['lg'];
          this.isPaymentSuccess = params['st'];
        } else if (params['lg'] !== undefined) {
          this.isLoggedIn = params['lg'];
        } else {
          if (this.tokenStorageService.getToken() != null) {
            //sessionStorage.removeItem('JWT_TOKEN');
          }
        }
      });

      if (
        sessionStorage.getItem('checkoutSignupEmit') != null ||
        sessionStorage.getItem('orderTokenForCheckout') != null ||
        sessionStorage.getItem('regis_number') != null
      ) {
        sessionStorage.removeItem('checkoutSignupEmit');
        //  sessionStorage.removeItem('orderTokenForCheckout');
        sessionStorage.removeItem('regis_number');
      }
    } else {
      this.toastr.error('Order Token is missing', 'Error');
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
}
