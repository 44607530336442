<div class="footerSec">
  <div class="footerLogo">
    <a class="logo__style" routerLink="/home"><img src="../../assets/images-new/Logo.svg" /></a>
  </div>
  <div class="vl"></div>
  <div class="footerLinksOuter">
    <ul class="footerStyle">
      <li class="footerHeading">{{
        "footer.menus.0.title" | translate
        }}</li>
      <!-- <li class="footerHeading"><a href="/about" class="ftrLinks">{{'footer.menus.2.link1' |translate}}</a></li> -->
      <li class="footerHeading">
        <a routerLink="/how" class="ftrLinks">{{
          "footer.menus.0.link1" | translate
          }}</a>
      </li>
      <li class="footerHeading" *ngIf="showSingupBtn">
        <a routerLink="/consumer/login" class="ftrLinks">{{
          "footer.menus.0.link2" | translate
          }}</a>
      </li>
      <li class="footerHeading" >
        <app-language-select></app-language-select>
      </li>
      <!-- <li class="footerHeading">
        <a routerLink="/consumer/help-support" class="ftrLinks">
          Help & Support
        </a>
      </li> -->
    </ul>
    <ul class="footerStyle">
      <li class="footerHeading">{{"general.merchant" |translate}}</li>
      <li class="footerHeading">
        <a routerLink="/forbusiness" class="ftrLinks">{{"footer.menus.2.link7" | translate}}</a>
      </li>

      <li class="footerHeading">
        <a class="ftrLinks" target="_blank" href="https://taly.io/merchant/login">
          {{"merchant.loginPage.title" | translate}}
        </a>
      </li>
      <li class="footerHeading">
        <a href="https://docs.taly.io/" target="_blank" class="ftrLinks docs">Developer Documents</a>
      </li>
      <!-- <li class="footerHeading"><a class="ftrLinks">Testimonials</a></li> -->
    </ul>
    <ul class="footerStyle">
      <li class="footerHeading">{{
        "footer.menus.2.link1" | translate
        }}</li>
      <!-- <li class="footerHeading"><a class="ftrLinks">Partners</a></li> -->

      <li class="footerHeading">
        <a routerLink="/faq" class="ftrLinks">FAQ</a>
      </li>
      <!-- <li class="footerHeading"><a href="/contactus" class="ftrLinks">{{'footer.menus.2.link2' |translate}}</a></li> -->
      <!-- <li class="footerHeading">
          <a href="/privacy" class="ftrLinks">{{
            "footer.menus.2.link4" | translate
          }}</a>
        </li> -->
      <li class="footerHeading">
        <a routerLink="/terms-and-conditions" class="ftrLinks">{{
          "footer.menus.2.link5" | translate
          }}</a>
      </li>
      <li class="footerHeading">
        <a href="assets/docs/sharia-compliant.pdf" target="_blank" class="ftrLinks">
          {{
          "footer.menus.2.link10" | translate
          }}
        </a>
      </li>
      <li class="footerHeading">
        <a href="https://boards.eu.greenhouse.io/taly" target="_blank" class="ftrLinks">
          {{
          "footer.menus.2.link9" | translate
          }}
        </a>
      </li>
      <!-- <li class="footerHeading">
        <a href="/terms-of-use" class="ftrLinks">{{
          "footer.menus.2.link6" | translate
        }}</a>
      </li> -->
    </ul>
  </div>
  <div class="copyrightWrapper d-flex">
    <div class="copyrightTxt">
      <span>
        <a routerLink="/privacy" class="ftrLinks">{{
          "footer.menus.2.link4" | translate
          }}</a>
      </span>
      <span class="vr vertical-line"></span>
      <span>
        <a routerLink="/terms-of-use" class="ftrLinks">{{
          "footer.menus.2.link6" | translate
          }}</a>
      </span>
    </div>
    <div class="copyrightBottom">
      <span class="copyright-content">
        Taly {{ year }} All rights reserved
      </span>
    </div>
  </div>
</div>
