<!-- merchant header  -->
<div class="text-center text-primary">
  <img
    routerLink="/home"
    src="assets/images/logo/taly_logo.png"
    class="img-fluid mb-1"
    width="120"
    height="100"
    alt="taly"
  />

  <h4 class="text-primary">Secure Checkout</h4>
</div>
