import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  oneAtATime = true;
  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);
  customClass = 'customClass';
  isArrow: boolean = false;
  public menuList: any[] = [
    // {
    //   title: 'Introduction',
    //   link: '/docs/introduction',
    //   icon: 'fa-home',
    //   isChild: false,
    // },
    // {
    //   title: 'Overview',
    //   link: '/docs',
    //   icon: 'fa-home',
    //   isChild: false,
    // },

    {
      title: 'Quickstart',
      link: '',
      icon: '',
      isChild: true,
      children: [
        // {
        //   title: 'Merchant login',
        //   link: '/docs/merchant-login',
        //   icon: '',
        //   menthod: 'GET',
        // },
        {
          title: 'Checkout overview',
          link: '/docs/checkout-overview',
          icon: '',
          menthod: 'POST',
        },
        {
          title: 'Merhcant account',
          link: '/docs/merchant-account',
          icon: '',
          menthod: 'PUT',
        },
        // {
        //   title: 'Order Status',
        //   link: '/docs/order-status',
        //   icon: '',
        //   menthod: 'PATCH',
        // },
        // {
        //   title: 'Calculate installment using orderToken',
        //   link: '/docs/calculate-installment',
        //   icon: '',
        //   menthod: 'DELETE',
        // },
        // {
        //   title: 'Get Order By Order Id',
        //   link: '/docs/get-order-by-orderid',
        //   icon: '',
        //   menthod: 'DELETE',
        // },
        // {
        //   title: 'Refresh Token',
        //   link: '/docs/refresh-token',
        //   icon: '',
        //   menthod: 'DELETE',
        // },
      ],
    },
    {
      title: 'Website Integration',
      link: '',
      icon: '',
      isChild: true,
      children: [
        {
          title: 'Integration flow',
          link: '/docs/integration-flow',
          icon: '',
          menthod: 'GET',
        },
        {
          title: 'API Authentication',
          link: '/docs/api-authentication',
          icon: '',
          menthod: 'GET',
        },
        // {
        //   title: 'Payment plans',
        //   link: '/docs/payment-plans',
        //   icon: '',
        //   menthod: 'GET',
        // },
        // {
        //   title: 'Calculate the payments',
        //   link: '/docs/calculate-payments',
        //   icon: '',
        //   menthod: 'GET',
        // },
        {
          title: 'Create Order',
          link: '/docs/create-orders',
          icon: '',
          menthod: 'GET',
        },
        {
          title: 'Get order details',
          link: '/docs/get-order-details',
          icon: '',
          menthod: 'GET',
        },
        {
          title: 'Error Codes',
          link: '/docs/error-code',
          icon: '',
          menthod: 'GET',
        },
      ],
    },
    // {
    //   title: 'Platforms',
    //   link: '',
    //   icon: '',
    //   isChild: false,
    //   children: [],
    // },
  ];

  constructor() {}

  ngOnInit(): void {}
  toggleAccordian(event, index) {
    const element = event.target;

    // console.log(element.children.event.target);
    element.classList.toggle('down');

    // element.childNodes[0].classList.toggle('fa-angle-down');

    // element.children.classList.toggle('arrow');
    element.parentElement.classList.toggle('active');
    if (this.menuList[index].isActive) {
      this.menuList[index].isActive = false;
    } else {
      // element.children.classList.remove('arrow');
      this.menuList[index].isActive = true;
    }
    const panel = element.parentElement.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
    this.isArrow = !this.isArrow;
  }
}
