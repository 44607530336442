import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mapTo, tap, timeout } from 'rxjs/operators';
import { EnvService } from 'src/app/env.service';
import { environment } from 'src/environments/environment';
import { AuthProfile } from '../../models/auth.model';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../interceptors/token-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private isAuthenticatedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(this.hasToken());

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

  private currentUserProfileSubject: BehaviorSubject<AuthProfile>;
  public currentUserProfile: Observable<AuthProfile>;

  constructor(
    private http: HttpClient,
    private environment: EnvService,
    private storageService: StorageService,
    private router: Router,
    private tokenStorageService: TokenStorageService
  ) {
    this.currentUserProfileSubject = new BehaviorSubject<AuthProfile>(
      JSON.parse(sessionStorage.getItem('authProfile'))
    );
    this.currentUserProfile = this.currentUserProfileSubject.asObservable();
  }
  public getCurrentUserProfile(): Observable<AuthProfile> {
    if (this.currentUserProfile != null) return this.currentUserProfile;
  }

  public getCurrentUserName() {
    var profile = this.currentUserProfileSubject.value;
    if (profile != null)
      return (
        (profile.firstName == null ? '' : profile.firstName) +
        ' ' +
        (profile.lastName == null ? '' : profile.lastName)
      );
  }
  isLoggedIn() {
    return !!this.getJWTToken();
  }

  counter: number = 1;
  public getJWTToken() {
    // let JWT_TOKEN: any;
    // if (sessionStorage.getItem(this.JWT_TOKEN) == null) {
    //   JWT_TOKEN = this.cookieService.get(this.JWT_TOKEN);
    // } else {
    //   JWT_TOKEN = sessionStorage.getItem(this.JWT_TOKEN);
    // }
    return sessionStorage.getItem(this.JWT_TOKEN);
  }
  public getJWTRefreshToken() {
    // let REFRESH_TOKEN;

    // if (sessionStorage.getItem(this.REFRESH_TOKEN) == null) {
    //   REFRESH_TOKEN = this.cookieService.get(this.REFRESH_TOKEN);
    // } else {
    //   REFRESH_TOKEN = sessionStorage.getItem(this.REFRESH_TOKEN);
    // }

    return sessionStorage.getItem(this.REFRESH_TOKEN);
  }

  refreshToken(token?: any) {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic YnJvd3NlcjpzZWNyZXQ=',
      refreshtoken: 'true',
    });

    const body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', this.getRefreshToken());
    if (this.validateToken()) {
      return this.http
        .post<any>(
          `${this.environment.ApiUrl}/uaa/oauth/token`,
          body.toString(),
          {
            headers: header,
          }
        )
        .pipe(
          tap(
            (tokens: any) => {
              // this.storeJwtToken(tokens.access_token);
              this.storeTokens(tokens);
            },
            (err) => this.logout()
          )
        );
    } else {
      this.logout();
    }
  }

  private validateToken() {
    if (this.getJWTToken() == null) {
      return false;
    }
    return this.http
      .get(
        `${
          this.environment.ApiUrl
        }/uaa/oauth/check_token?token=${this.getJWTToken()}`
      )
      .subscribe(
        () => {
          return true;
        },
        (error) => {
          //this.router.navigate(['/consumer/login']);
          return false;
        }
      );
  }

  private getRefreshToken() {
    return sessionStorage.getItem(this.REFRESH_TOKEN);
  }
  private storeJwtToken(jwt: string) {
    sessionStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: any, type?: string) {
    sessionStorage.setItem(this.JWT_TOKEN, tokens.access_token);
    sessionStorage.setItem(this.REFRESH_TOKEN, tokens.refresh_token);
    sessionStorage.setItem('login', 'consumer');

    window.sessionStorage.setItem(this.JWT_TOKEN, tokens.access_token);
    window.sessionStorage.setItem(this.REFRESH_TOKEN, tokens.refresh_token);
  }

  private removeTokens() {
    sessionStorage.removeItem(this.JWT_TOKEN);
    sessionStorage.removeItem(this.REFRESH_TOKEN);
  }
  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(
    username: string,
    password: string,
    rememberMe: boolean = false,
    type?: string
  ) {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic YnJvd3NlcjpzZWNyZXQ=',
      requestFor: 'consumer',
    });

    const body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('grant_type', 'password');
    body.set('scope', 'ui');

    return this.http
      .post<any>(
        `${this.environment.ApiUrl}/uaa/oauth/token`,
        body.toString(),
        {
          headers: header,
        }
      )
      .pipe(
        tap((token) => {
          if (token != null) {
            const currUserToken = token;
            this.storeTokens(token, type);
            this.isAuthenticatedSubject.next(true);

            this.tokenStorageService.saveToken(token.access_token);
            this.tokenStorageService.saveRefreshToken(token.refresh_token);
            sessionStorage.setItem('username', token.username);
            sessionStorage.setItem('rememberme', JSON.stringify(rememberMe));

            return currUserToken;
          } else {
            this.isAuthenticatedSubject.next(false);
            throw new Error('401');
          }
        }),
        mapTo(true),
        catchError((error) => {
          this.refreshToken();
          return of(false);
        })
      );
  }

  private hasToken(): boolean {
    return !!this.getJWTToken();
  }

  getAuthProfile() {
    return this.http
      .get<AuthProfile>(`${this.environment.ApiUrl}/accounts/profile`)
      .pipe(
        map((profile) => {
          sessionStorage.removeItem('authProfile');

          sessionStorage.setItem('authProfile', JSON.stringify(profile));
          this.currentUserProfileSubject.next(profile);
          return profile;
        })
      );
  }
  resetPassword(email: string) {}

  /**
   * Logout the user
   */
  logout() {
    // logout the user

    if (this.validateToken()) {
    } else {
    }

    return this.http.get(`${this.environment.ApiUrl}/uaa/user/logout`).pipe(
      map(
        () => {
          this.isAuthenticatedSubject.next(false);
          this.localClear();
        },
        (err) => {
          console.warn('error logging out...' + err);
        }
      )
    );
  }

  localClear(type?: any) {
    var lang = sessionStorage.getItem('lang');
    var country = sessionStorage.getItem('country');
    this.storageService.clearEverything();
    sessionStorage.setItem('lang', lang);
    sessionStorage.setItem('country', country);
  }
}
