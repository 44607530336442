<section class="section-inner">
  <div class="container text-primary">
    <h2 class="mb-4 mob-textcenter">Terms and Conditions</h2>

    <p class="mb-3">
      FOR THE PERIOD OF THE SANDBOX PHASE (DEFINED AS A TESTING PHASE UNDER THE
      REGULATORY OVERSIGHT OF THE CENTRAL BANK AND/OR ANY OTHER REGULATORY
      AUTHORITIES), THE CUSTOMER ACKNOWLEDGES BY ACCEPTING THESE TERMS AND
      CONDITIONS THAT IT SHALL BE CONSTRUED AS A VOLUNTEER CUSTOMER WHO WILL BE
      PERMITTED TO TEST THE PRODUCT AND COOPERATE WITH TALY TO COMMUNICATE
      FEEDBACK AND IMPROVEMENT SUGGESTIONS TO THE TALY BNPL PLATFORM. THE
      CUSTOMER ACKNOWLEDGES THAT THE SERVICES PROVIDED WITHOUT ANY LIABILITY TO
      THE CENTRAL BANK OF KUWAIT, AND TALY SHALL BE RESPONSIBLE FOR ANY DAMAGES
      THAT MAY BE INCURRED BY THE CUSTOMER, AND THE CUSTOMER UNDERSTANDS,
      ACKNOWLEDGES AND ASSUMES THE RISKS ASSOCIATED WITH PARTICIPATING IN THESE
      SERVICES DURING THE PERIOD OF THE SANDBOX PHASE.
    </p>

    <p class="mb-3">
      Please read the following terms of service ("Terms of Service") carefully.
      By using, accessing and/or activating Taly's Buy Now, Pay Later service as
      well as the related website, software, programs, documentation, tools,
      portal and/or platform (collectively the "BNPL"), you agree to be bound by
      these Terms of Service as may be published at
      {{ host }}/terms-and-conditions.
    </p>
    <p class="mb-3">
      Please do not use the BNPL if you do not agree to these Terms of Service.
      These Terms of Service are a legally binding agreement between you ("you"
      or "your") and Taly General Trading Company WLL and its affiliates and
      subsidiaries (individually and collectively, "Company", "we", "our" or
      "us").
    </p>

    <ol class="tc-ul">
      <li class="mb-3">
        <strong>Definitions and Interpretation</strong>

        <p class="mb-3 mt-3">
          For the purpose of these Terms of Service, the following definitions
          shall apply:
        </p>

        <ol class="list-style-none">
          <li>
            "Contract for Sale" means the contract for sale of goods and/or
            services between you and the Merchant which is eligible for the
            BNPL; and
          </li>
          <li>
            "Merchant" means participating sellers, retailers or merchants
            providing goods and/or services for purchase and offering the BNPL
            as a mode of payment.
          </li>
        </ol>
      </li>
      <li class="mb-3">
        <strong> Processing and Application for the BNPL</strong>

        <ol class="mt-3">
          <li>
            All applications for the BNPL are subject to our approval and such
            approval is to be determined in our absolute discretion.
          </li>
          <li>
            You will be asked to and shall provide your personal information
            such as name, identification card number, mailing address, phone
            number, email address, and contact preferences details in order to
            allow us to assess your eligibility for the BNPL.
          </li>
          <li>
            You understand and agree that your personal information may be used
            by us or by any of our appointed third parties to process your
            application for the BNPL and all related products and/or for
            services risk assessment and verification purposes.
          </li>
          <li>
            By submitting your information to us, you hereby consent and
            authorize us to obtain from any third party, and to verify, any
            information about you that we may require in connection with your
            application for the BNPL and any related products and/or services.
          </li>
          <li>
            Such consent and authorization herein will extend to any information
            obtained in connection with any of the account(s) presently
            maintained for you, any new application for any type of services
            rendered or products provided by us, any historical financial or
            credit records, data or information (whether or not provided by you
            personally or by any other sources) however collected, received,
            captured, complied, secured and/or obtained by us.
          </li>
        </ol>
      </li>
      <li class="mb-3">
        <strong> Payment Structure </strong> The Customer hereby consents to the
        automatic deduction of its debit or credit card for purposes of
        satisfying its financial obligations owed to the BNPL and in accordance
        with the terms and conditions of this Agreement.

        <ol class="mt-3">
          <li>
            The Merchant shall provide you the option to make payment due under
            the Contract for Sale, including payment of any sales and service
            tax (if applicable), delivery costs and other applicable fees the
            Merchant may impose on the goods and/or services ("Total Purchase
            Price"), on an installment basis via the BNPL. Subject to the terms
            herein and our approval, you can apply for any of the installment
            plans:

            <ol class="list-style-alph" type="a">
              <li>
                (a) you may agree to make full payment within the next thirty
                days; or
              </li>
              <li>
                (b) you may agree to make payment in four equal installments
                over the next ninety days, as follows:
                <ol class="mb-2 mt-2">
                  <li>First installment of 25%: Upfront</li>
                  <li>Second installment of 25%: after 30 days</li>
                  <li>Third installment of 25%: after 60 days</li>
                  <li>Fourth installment of 25%: after 90 days</li>
                </ol>
              </li>
              <li>
                (c) any other plan offered by the Company from time-to-time.
              </li>
            </ol>

            You will be notified of whether your request has been approved via
            email to your registered email address and/or via SMS (short message
            service) to your registered mobile phone number.
          </li>
          <li>
            Upon your acceptance of these Terms of Service (by checking the box
            below), you will be directed to the relevant information page
            containing information about the installment plan, the monthly
            installment payment and all other relevant information relating to
            the BNPL. You may be required to confirm that you have reviewed and
            accepted the information contained therein. A user dashboard
            containing information about the monthly installment payment, the
            due date(s), and all other relevant information including any other
            sums due to the Company pursuant to the BNPL ("Dashboard") will be
            provided by the Company and will be available for view on our portal
            at taly.io/consumer/login. You acknowledge and agree that it is your
            responsibility to log into the portal to view your Dashboard. All
            payments due under the BNPL shall be payable by the due date(s) set
            out in the Dashboard and you agree and shall ensure that payments
            are made in accordance with the Dashboard. If any payment is made in
            excess of the monthly installment amount, then such excess (after
            deducting all other sums due to the Company) shall be allocated to
            the following month's installment, and in the event of insufficient
            payment or default in payment, we reserve all rights in law and in
            equity to claim damages or pursue any other remedy available to us
            in a court of competent jurisdiction.
          </li>
          <li>
            You unconditionally and irrevocably agree and oblige yourself to pay
            to the Company the Total Purchase Price and such other sums as may
            be due to the Company in accordance with the Dashboard and these
            Terms of Service. For the avoidance of doubt, the BNPL does not
            constitute a lending or credit facility and the Company does not
            provide any credit to you. The BNPL has the discretion to suspend or
            terminate a customer account with BNPL in the event of a violation
            or threatened violation of these terms and conditions or a violation
            of applicable law and the Customer acknowledges that it shall have
            no recourse to the BNPL or the merchant for any claim of harm or
            damage.
          </li>

          <li>
            By opting for the BNPL, you expressly agree, covenant and authorize
            and direct the Company to pay the Total Purchase Price to the
            Merchant on your behalf in consideration for your agreement and
            obligation to repay the Company at the times and in the manner
            stated in the Monthly Statement and these Terms of Service.
          </li>
          <li>
            The BNPL has the right to set off any and all amounts due under
            these Terms of Service to the BNPL from amounts related to any of
            your accounts.
          </li>
        </ol>
      </li>
      <li class="mb-3">
        <strong>Refunds</strong>
        <ol>
          <li>
            Any issues with the delivery of your order or condition of goods
            should be raised directly with the merchant. You can normally find a
            merchant's contact details on their website.
          </li>
          <li>
            Returns are always subject to the Merchant's policy, which can
            typically be found on their website or by contacting them directly.
            If you make a return and the Merchant processes a refund, it will
            automatically be reflected in your account.
          </li>
          <li>
            Refunds are applied starting with your final payment and working
            backwards to earlier payments.
          </li>
          <li>
            For full refunds, if you have returned an order in its entirety and
            the full amount of the order is refunded, any upcoming payments will
            be cancelled and any payments you have already made will be refunded
            back to the card you paid with.
          </li>
          <li>
            For partial refunds, the same principles apply (as to full refunds
            in 4.4) if you've returned a portion of an order and received a
            partial refund.
          </li>
          <li>
            For refunds, it will be your responsibility to maintain the original
            accounts and cards as active.
          </li>
          <li>
            All refunds will be made without any deductions or setoff on other
            amounts that you may owe to Taly.
          </li>
        </ol>
      </li>
      <li class="mb-3">
        <strong>Representation and Warranties</strong>

        <div class="mt-3">
          By using the BNPL, you expressly represent and warrant that: (a) you
          are at least twenty-one (21) years of age and/or otherwise legally
          entitled and recognized as being capable of entering into a legally
          binding contract,; (b) all the information provided by you is true,
          accurate and complete, and you agree and undertake to maintain and
          update your information in a timely manner; (c) you will provide to us
          in a timely manner all documentation relating to your identity if
          requested by us; (d) you shall not use or allow any third party to use
          the BNPL for any unlawful, fraudulent or improper activity; and (e)
          nothing in these Terms of Service shall constitute a partnership,
          joint venture, agency or principal-agent relationship between you and
          the Company nor does it authorize you to incur any costs or
          liabilities on our behalf.
        </div>
      </li>

      <li class="mb-3">
        <strong>Disputes and Disclaimer</strong>

        <ol class="mt-3">
          <li>
            The Company makes no warranty, representation or guarantee
            whatsoever of any of the Merchant's goods and/or services, nor is
            the Company responsible or liable for any merchantability,
            suitability, fitness, quality or standard of the goods and/or
            services provided by the Merchant. The Merchant is solely
            responsible and liable for any liabilities incurred and/or
            obligation derived from and in the course of providing the goods
            and/or services to you, and you acknowledge and agree that the
            Company is not a party to and shall have no such liability,
            obligation or responsibility, whether under the Contract for Sale or
            any other agreement between you and the Merchant.
          </li>
          <li>
            You shall file any dispute or complaint directly with the Merchant.
            You acknowledge and agree that the dispute will not affect the
            Company's rights and remedies against you or your obligation and
            liability to make any payments due to the Company in accordance with
            these Terms of Service.
          </li>
          <li>
            The BNPL is provided strictly on an "as is" basis and the Company
            does not warrant or guarantee continuous, uninterrupted or secure
            access to any part of the BNPL or that the BNPL is free of viruses,
            malware or other harmful components.
          </li>
          <li>
            The Company expressly disclaims all representations, warranties or
            conditions of any kind, whether written or oral, express, implied or
            statutory, including without limitation any implied warranty of
            title, noninfringement, merchantability or fitness for a particular
            purpose, and the Company shall not be held responsible or liable for
            any claims, losses, damage, costs or expenses incurred, suffered or
            sustained by you arising from or in connection with your use of the
            BNPL.
          </li>
        </ol>
      </li>
      <li class="mb-3">
        <strong> Indemnification </strong> You agree to indemnify the BNPL
        against all costs, expenses, damages and liabilities incurred by the
        BNPL directly or indirectly either as result of: (a) Your breach of
        these Terms and Conditions; (b) Any claim brought by you or third
        parties arising from your breach of these Terms and Conditions; (c) Any
        breach of its representations or warranties in clause 5; (d) Your
        violation of applicable laws.

        <ol class="mt-3">
          <li>
            You acknowledge that the BNPL is a Shari'ah compliant platform and
            understand that the BNPL does not support nor condone transactions
            or dealings with items or activities that are prohibited under
            Islamic Shari'ah (i.e., haram), and you agree that you shall be
            fully responsible for any damages, costs and expenses arising out of
            or in connection with any of your dealings with such prohibited
            items or activities or any disputes relating to such items or
            activities, and hereby agree to hold the BNPL harmless and indemnify
            BNPL including its offices, agents, partners and affiliates against
            any harm caused by you in violation of this clause. The BNPL will
            not, in any way, take part or be a party to such disputes between
            the you and any third party.
          </li>
          <li>
            You will not bring a claim against the Company for any losses or
            damages whatsoever suffered or incurred by you in using the BNPL and
            you agree to indemnify and hold harmless the Company and its
            subsidiaries, affiliates, directors, shareholders, partners,
            employees, officers, agents, representatives and/or any third
            parties providing services for and/or on behalf of the Company
            ("Indemnified Parties") from and against any and all claims,
            actions, liabilities, fines, damages, losses and expenses, including
            without limitation legal and accounting fees and any other dispute
            resolution expenses, arising out of or in any way related to these
            Terms of Service and the BNPL.
          </li>
        </ol>
      </li>

      <li class="mb-3">
        <strong>Assignment</strong>

        <ol class="mt-3">
          <li>
            You shall not transfer or assign any rights and/or obligations you
            may have under these Terms of Service without our prior written
            consent.
          </li>
          <li>
            The Company can transfer or assign these Terms of Service and any
            rights and/or obligations therein to any third party without your
            consent or notice.
          </li>
        </ol>
      </li>

      <li class="mb-3">
        <strong> Notices and Communications </strong>

        <ol class="mt-3">
          <li>
            All notices or other communications herein shall be given in writing
            in English and shall be delivered: (a) to you, via electronic mail,
            (b) through the Taly's platform, or (c) via SMS (short message
            service) to your registered mobile phone number; and (a) to us at
            <a href="mailto:contact@taly.io">contact@taly.io.</a>
          </li>
          <li>
            All notices shall be deemed received upon successful transmission.
          </li>
        </ol>
      </li>

      <li class="mb-3">
        <strong>Governing Laws</strong>
        <div class="mt-3">
          These Terms of Service shall be construed and enforced in accordance
          with the laws of the State of Kuwait, and the parties hereto submit to
          the exclusive jurisdiction of the Courts of the State of Kuwait.
        </div>
      </li>
      <li class="mb-3">
        <strong>General</strong>

        <ol class="mt-3">
          <li>
            Any amendment to these Terms of Service is subject to your prior
            consent and approval.
          </li>
          <li>
            The invalidity or unenforceability of any provision of these Terms
            of Service shall not affect the validity or enforceability of any
            other provision. Any invalid or unenforceable provision shall be
            deemed severed from these Terms of Service to the extent of its
            invalidity or unenforceability and these Terms of Service shall be
            construed and enforced as if the Terms of Service did not contain
            that particular provision to the extent of its invalidity or
            unenforceability.
          </li>
          <li>
            No failure or delay on our part in exercising any right or remedy
            under these Terms of Service will operate as a release or waiver
            thereof, nor will any single or partial exercise of any right or
            remedy prevent the further exercise of the right or remedy or the
            exercise of another right or remedy.
          </li>
          <li>
            The headings in these Terms of Service are for convenience only and
            shall not affect the interpretation of these Terms of Service.
          </li>
          <li>
            These Terms of Service shall be binding upon the parties hereto and
            their respective heirs, personal representatives, successors in
            title and permitted assigns.
          </li>
        </ol>
      </li>
      <li>
        <strong>Questions</strong>

        <div class="mt-3">
          If you have any questions or concerns about these Terms of Service or
          any issues raised in these Terms of Service, please contact us at
          <a href="mailto:contact@taly.io">contact@taly.io.</a>
        </div>
      </li>
    </ol>
  </div>
</section>
