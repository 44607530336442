<div class="container section-inner">
    <div class="mb-5 w-50 mx-auto">
        <h1 class="text-center mb-3 display-3"><strong>Contact Us</strong>  </h1>
        <h6 class="text-muted text-center">We're happy to answer any questions you may have, just send us a message via our website chat support.</h6>
    </div>

    <div class="row mb-5">
        <div class=" col-lg-6 pe-3">
            <div class="border py-5 px-5 d-flex flex-column align-items-center rounded-3 h-100">
                <div class="icon-oval d-flex justify-content-center align-items-center mb-4"><i class="fal fa-phone-alt fa-3x text-white"></i></div>
                
                <h4 class="mb-3 text-center"> Give us a call</h4>
                <h6 class="text-muted mb-4 text-center">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veritatis reprehenderit voluptate sint atque officia labore.</h6>
                <a href=""><b>Call now</b> </a>
            </div>
        </div>

        <div class=" col-lg-6 pe-3">
            <div class="border py-5 px-5 d-flex flex-column align-items-center rounded-3 h-100">
                <div class="icon-oval d-flex justify-content-center align-items-center mb-4"><i class="fal fa-comment-alt-dots fa-3x text-white"></i></div>
                
                <h4 class="mb-3 text-center">Live chat</h4>
                <h6 class="text-muted mb-1 text-center">Mon-Fri: 24 hours</h6>
                <h6 class="text-muted mb-4 text-center">Sat-Sun: 24 hours</h6>
                <a href=""><b>Start chatting</b> </a>
            </div>
        </div>
    </div>
</div>
