import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/env.service';

@Injectable({
  providedIn: 'root',
})
export class LoginOtpService {
  constructor(private http: HttpClient, private environment: EnvService) {}

  postNumber(phoneNumber: any): Observable<any> {
    return this.http.post(
      `${this.environment.ApiUrl}/uaa/signin/otp`,
      phoneNumber
    );
  }

  signinInit(signupModel: any) {
    return this.http.post<any>(
      `${this.environment.ApiUrl}/accounts/signup/init`,
      signupModel
    );
  }

  resendOtp(signupModel: any) {
    return this.http.post<any>(
      `${this.environment.ApiUrl}/accounts/signup/resendOtp`,
      signupModel
    );
  }

  storeLoginResp(reqid: any, otpRef) {
    sessionStorage.setItem('reqID', reqid);
    sessionStorage.setItem('otpRef', otpRef);
  }

  verifyOTP(otp) {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/signup/verifyOtp`,
      otp,
      { responseType: 'text' }
    );
  }
}
