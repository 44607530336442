import { Component, OnInit } from '@angular/core';
import { CheckoutService } from 'src/app/Core/services/customer-checkout/checkout.service';
import { MerchantCheckoutService } from './merchant-checkout.service';
import { MerchantLoginService } from './merchant-login.service';

@Component({
  selector: 'app-secure-checkout',
  templateUrl: './secure-checkout.component.html',
  styleUrls: ['./secure-checkout.component.scss'],
})
export class SecureCheckoutComponent implements OnInit {
  constructor(
    private checkoutService: CheckoutService,
    private merchantLogin: MerchantLoginService,
    private merchantCheckout: MerchantCheckoutService
  ) {}

  plans: boolean[];
  installments: any[];
  ngOnInit(): void {
    this.plans = [true, false];
    this.merchantLogin.isLoggedIn()
      ? ''
      : this.merchantLogin
          .login('xcite@talypay.net', '12345')
          .subscribe(() => {});
  }

  onPlan(planNum: number) {
    this.plans = [false, false];
    switch (planNum) {
      case 1:
        {
          this.plans[0] = true;
          this.createOrder('ONE_MONTH');
        }
        break;
      case 2:
        {
          this.plans[1] = true;
          this.createOrder('THREE_MONTHS');
        }
        break;
    }
  }

  createOrder(plan) {
    let id = Math.floor(10000000 + Math.random() * 90000000);

    let order: any = {
      merchantOrderId: id,
      amount: 120.0,
      currency: 'KWD',
      redirectUrl: 'https://xcite.com/order',
      languageCode: 'en',
      paymentPlanId: plan,
    };

    this.initiateOrder(order);
  }

  orderDetails: any;
  initiateOrder(order) {
    this.merchantCheckout.initiateOrder(order).subscribe((res) => {
      this.orderDetails = res;
      this.calcPayment(res.orderToken);
    });
  }

  calcPayment(orderToken) {
    this.merchantCheckout.getCalcPayment(orderToken).subscribe((res) => {
      this.installments = res;
    });
  }

  clearmerchantLogin() {
    sessionStorage.removeItem('JWT_TOKEN');
    sessionStorage.removeItem('REFRESH_TOKEN');
  }
}
