import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConsumerLovService } from 'src/app/Core/services/lov/consumer-lovs.service';
import { TextDirectionController } from 'src/app/utilities/textDirectionController';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
})
export class CountrySelectComponent implements OnInit {
  @Output() selectedCountryEmit = new EventEmitter<any>();
  constructor(private lovService: ConsumerLovService) {}

  countries: any[];
  directionController = new TextDirectionController();
  ngOnInit(): void {
    this.lovService.getCountries().subscribe((res) => (this.countries = res));
    // sessionStorage.setItem('')
  }

  onSelectCountry(i) {
    this.selectedCountryEmit.emit(this.countries[i]);
    sessionStorage.setItem('country', this.countries[i].nameEn);
  }
}
