import {
  SignupModel,
  SignupResponseModel,
  OtpRequest,
  SignupCompleteRequest,
  ResendOtpRequest,
} from './../../models/signup.model';
import { CaptchaModel } from './../../models/capcha.model';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvService } from 'src/app/env.service';

@Injectable({ providedIn: 'root' })
export class SignupService {
  public _consumerDetails = new BehaviorSubject<any>({});
  public _consumerDetails$ = this._consumerDetails.asObservable();

  constructor(private http: HttpClient, private environment: EnvService) { }

  setConsumerDetails(userDetails: any) {
    this._consumerDetails.next(userDetails);
  }

  getConsumerDetails() {
    return this._consumerDetails$;
  }

  getCaptcha(): Observable<CaptchaModel> {
    return this.http.get<CaptchaModel>(
      `${this.environment.ApiUrl}/accounts/captcha`
    );
  }

  signupInit(signupModel: any) {
    return this.http.post<any>(
      `${this.environment.ApiUrl}/accounts/signup/init`,
      signupModel
    );
  }

  verifyOtp(otpRequest: any) {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/signup/verifyOtp`,
      otpRequest
    );
  }
  resendOtp(resendOtpRequest: ResendOtpRequest) {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/signup/resendOtp`,
      resendOtpRequest
    );
  }
  storeOtpResponse(signupResponse: any) {
    sessionStorage.setItem('signupResponse', JSON.stringify(signupResponse));
  }

  getOtpResponse(): any {
    return JSON.parse(sessionStorage.getItem('signupResponse'));
  }

  signupComplete(signupCompleteRequest: SignupCompleteRequest) {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/signup`,
      signupCompleteRequest
    );
  }

  verifyNationalId(nationalId: any): Observable<any> {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/signup/verifyNationalId`,
      nationalId
    );
  }

  storeCustomerInfo(customerData: any): Observable<any> {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/signup/customerInfo`,
      customerData
    );
  }
  verifyMobileIdStatus(requestIdDStatus: any): Observable<any> {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/signup/nationalId/status`,
      requestIdDStatus
    );
  }
  paymentCancel(orderId:any): Observable<any> {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/payment/cancel/${orderId}`, []
    )

  }

  private otpResponseEmit = new BehaviorSubject(null);
  getOTPResponse = this.otpResponseEmit.asObservable();
  otpResponseEmitter(item) {
    this.otpResponseEmit.next(item);
  }
}
