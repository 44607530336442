<div class="text-primary">
  <div class="py-1 px-3 px-lg-4 d-flex flex-column">
    <!-- back button here -->
    <!-- <a role="button" id="back-btn" data-bs-toggle="modal" data-bs-target="#backModal">
        <i class="fal fa-chevron-left me-2"></i>Back
      </a> -->

    <h4 class="mt-1 mb-4">Verification Code</h4>

    <h6>Enter the 6-digit code we sent to your mobile no.</h6>
    <!--   user mobile number here -->
    <span class="mb-4 text-info" #mobileNumber>+965 {{ mobNumber }}</span>

    <div
      id="input-otp-div mb-4"
      class="d-flex flex-column align-items-center justify-content-center"
    >
      <!-- intput OTP text box here  -->
      <!-- <otp [setting]="settings" (onValueChange)="onInputChange($event)" OtpDesign LoginOtp></otp> -->
      <ng-otp-input
        (onInputChange)="onInputChange($event)"
        class="mb-5"
        [config]="settings"
      ></ng-otp-input>

      <app-otp-counter></app-otp-counter>
    </div>

    <div class="d-flex w-100 flex-column align-items-center mt-5 mb-4">
      <h6 class="text-primary mb-1">Accepted payment methods</h6>
      <span class="text-muted mb-1">No interest, No hidden fees</span>
      <div class="d-flex">
        <img
          class="img-fluid me-2 ms-0"
          src="assets/images/icons/knet.png"
          alt=""
          style="height: 26px; width: 35px"
        />
        <img
          class="img-fluid me-2 ms-0"
          src="assets/images/icons/visa.png"
          alt=""
          style="height: 26px; width: 35px"
        />
        <img
          class="img-fluid"
          src="assets/images/icons/mc.png"
          alt=""
          style="height: 26px; width: 35px"
        />
      </div>
    </div>
    <!-- verify button  -->
    <button
      class="btn btn-dark rounded-pill my-3"
      [disabled]="disableSubmit"
      (click)="onSubmit()"
    >
      <span *ngIf="loading"
        ><i class="fa fa-spinner fa-spin me-1"></i>Loading...</span
      >
      <span *ngIf="!loading"
        >Verify<i class="fal fa-chevron-right ms-2"></i
      ></span>
    </button>

    <!-- back button here -->
    <button
      type="button"
      class="btn btn-dark rounded-pill"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
    >
      <i class="fal fa-chevron-left me-2"></i>Back
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Checkout OTP</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Are you sure, you want to abort verfication?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              (click)="this.moveStep.emit(1); isSubmit = false"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- end container  -->
</div>
