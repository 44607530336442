<!-- <div class="howBox" style="background-color: #f4f3f2; padding: 5rem 16rem;">
    <h3 class="mb-4 mob-textcenter">{{'content.howPage.section1.title' |translate}}</h3>
    <div class="row">
        <div class="col-lg-6 pe-lg-5 pe-0 mb-lg-0 mb-4 ps-0">
            <div class="rounded-3 p-4 text-white howHeight" style="background-color: #4586bf;">
                <label class="small fw-bold mb-3">Online purchase </label>
                <h4 class="mb-3">Choose your favorite brand</h4>
                <p class="mb-4">Add what you want to your cart and choose Taly at
                    checkout. Sign up, link any card and get approved
                    instantly.</p>
                <h4 class="mb-3">Choose Taly at the checkout</h4>
                <p class="mb-4">Pay only 25% upfront and we'll remind you about the
                    rest. Completely free, no interest.</p>
                <h4 class="mb-3">Pay in 4 or in 30 days</h4>
                <p class="mb-4">Put your desired items in your shopping cart and
                    select Taly at the checkout. Sign up, link any card,
                    and get quick approval.</p>
            </div>
            
        </div>
        <div class="col-lg-6 px-0 ">
                <div class="rounded-3 p-4 text-white howHeight" style="background-color: #53a1e6;">
                    <label class="small fw-bold mb-3">In-store purchase </label>
                    <h4 class="mb-3">Download our app</h4>
                    <p class="mb-4">Add what you want to your cart and choose Taly at 
                        checkout. Sign up, link any card and get approved 
                        instantly.</p>
                    <h4 class="mb-3">Scan your QR code</h4>
                    <p class="mb-4">Pay only 25% upfront and we'll remind you about the
                        rest. Completely free, no interest.</p>
                    <h4 class="mb-3">Pay and have fun.</h4>
                    <p class="mb-4">Put your desired items in your shopping cart and
                        select Taly at the checkout. Sign up, link any card,
                        and get quick approval.</p>
                </div>            
        </div>
    </div>
</div> -->

<div class="howItwork__style">
    <h3 class="common_heading_txt">{{"content.howPage.section1.title"|translate}}</h3>

    <div class="row">
        <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="howItWork__details">
                <div class="roundBorders__style">
                    <span class="numbers__style">1</span>
                </div>
                <p class="detailsTxt__style">{{"home.section7.title4" | translate}}<br />
                </p>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="howItWork__details">
                <div class="roundBorders__style">
                    <span class="numbers__style">2</span>
                </div>
                <p class="detailsTxt__style">{{"home.section7.title5" | translate}}<br />
                </p>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="howItWork__details">
                <div class="roundBorders__style">
                    <span class="numbers__style">3</span>
                </div>
                <p class="detailsTxt__style">
                    {{"home.section7.title2" | translate}}<br /> </p>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="howItWork__details">
                <div class="roundBorders__style">
                    <span class="numbers__style">4</span>
                </div>
                <p class="detailsTxt__style">{{"home.section7.title3" | translate}}<br />
                </p>
            </div>
        </div>
    </div>
</div>