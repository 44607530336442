<!-- <p>pay30 works!</p> -->
<ul class="progressbar my-4">
    <li class="active">
        <h5 class="fw-bold text-black-50">BHD {{''}}</h5>
        <p class="fw-bold small text-black-50">Due today</p>
    </li>
    
    <li>
        <h5 class="fw-bold text-black-50">BHD {{''}}</h5>
        <p class="fw-bold small text-black-50">Final Installment</p>
        <span class="fw-bold text-info">22 Nov 2021</span>
    </li>
</ul>
