<nav class="navbar d-flex justify-content-end">
  <input
    class="form-control me-2 w-25"
    type="search"
    autocomplete="off"
    placeholder="Enter name to search"
    aria-label="Search"
    [(ngModel)]="searchItem"
    (ngModelChange)="onSearch(dataArray, searchKey)"
  />
</nav>
