<section class="mb-3 mt-4" *ngIf="!isPaymentSuccess">
  <div class="border p-4 my-3 container mx-auto" *ngIf="isLoggedIn">
    <app-merchant-header></app-merchant-header>

    <div class="mt-4">
      <app-payment-plan
        (successPayment)="isPaymentSuccess = $event"
      ></app-payment-plan>
    </div>
  </div>

  <app-login-signup
    *ngIf="!isLoggedIn"
    (loggedIn)="isLoggedIn = true"
  ></app-login-signup>
</section>

<div
  *ngIf="isPaymentSuccess"
  class="border p-4 container justify-content-center align-items-center rounded-3"
>
  <app-merchant-header
    *ngIf="isPaymentSuccess"
    class="mb-5"
  ></app-merchant-header>
  <app-payment-success *ngIf="isPaymentSuccess == 'true'"></app-payment-success>
  <app-payment-failed *ngIf="isPaymentSuccess == 'false'"></app-payment-failed>
</div>
<div class="wrapper"></div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Modal</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">This is a modal.</div>
</ng-template>
