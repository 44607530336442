import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/env.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MerchantCheckoutService {

  constructor(private http: HttpClient, private environment: EnvService) { }

  initiateOrder(order:any): Observable<any>{
    return this.http.post<any>(`${this.environment.ApiUrl}/accounts/payment/initiate`,order)
  }

  getCalcPayment(orderToken): Observable<any[]>{
    return this.http.get<any[]>(`${this.environment.ApiUrl}/accounts/payment/calcInstallments/${orderToken}`)
  }
}
