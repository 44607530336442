
<section class="section-inner mx-5">
        <h6 class="display-4 mb-3 fw-bold text-center text-lg-start">Talypay International</h6>
        <p class="mb-3 text-center text-lg-start">Choose your country below.</p>
        <!-- countries  -->
        <div class="row mt-5">
            <div class="col-lg-2 col-6 p-3" *ngFor="let country of countries; let i=index">
                <div class="border rounded-3 p-3 selector" (click)="onSelectCountry(i)">
                    <img src="{{country.imageUrl}}" alt="" class=" mb-4 d-block img-fluid" style="height: 4.5rem !important; width: 12rem !important;">
                    <h5 class="m-0 fw-bold">{{directionController.isLTR()? country.nameEn:country.nameAr}}</h5>
                </div>
            </div>
           
        </div>
</section>
