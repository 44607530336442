<div class="container text-primary section-inner">
  <section class="mb-5">
    <h4 class="mb-1">Top Deals</h4>

    <swiper [config]="sensibleConfig">
      <!-- Add Arrows -->
      <div class="d-flex justify-content-end mb-3 mt-lg-0 mt-2">
        <button class="me-3 bg-white border-0 prevNav">
          <i
            class="text-primary"
            [ngClass]="
              directionController.isLTR()
                ? 'fal fa-arrow-left'
                : 'fal fa-arrow-right'
            "
          ></i>
        </button>
        <button class="bg-white border-0 nextNav">
          <i
            class="text-primary"
            [ngClass]="
              directionController.isLTR()
                ? 'fal fa-arrow-right'
                : 'fal fa-arrow-left'
            "
          ></i>
        </button>
      </div>
      <!-- swiper  -->
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div>
            <img
              src="../../../../assets/images/images shop/bath-and-body-works.jpg"
              class="mb-3 img-fluid"
              alt=""
            />
            <h5>Bath and Body Works</h5>
            <p class="small text-muted mt-2">
              <i class="fal fa-check-circle text-success me-1 ms-0"></i
              >4-Installments
            </p>
          </div>
        </div>
        <div class="swiper-slide">
          <div>
            <img
              src="../../../../assets/images/images shop/dermalogica.jpg"
              class="mb-3 img-fluid"
              alt=""
            />
            <h5>Dermalogica</h5>
            <p class="small text-muted mt-2">
              <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
              30
            </p>
          </div>
        </div>
        <div class="swiper-slide">
          <div>
            <img
              src="../../../../assets/images/images shop/west-elm.jpg"
              class="mb-3 img-fluid"
              alt=""
            />
            <h5>West-elm</h5>
            <p class="small text-muted mt-2">
              <i class="fal fa-check-circle text-success me-1 ms-0"></i
              >4-Installments
            </p>
          </div>
        </div>
        <div class="swiper-slide">
          <div>
            <img
              src="../../../../assets/images/images shop/pottery-barn.jpg"
              class="mb-3 img-fluid"
              alt=""
            />
            <h5>Pottery-barn</h5>
            <p class="small text-muted mt-2">
              <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
              30 & 4-Installments
            </p>
          </div>
        </div>
      </div>
    </swiper>
  </section>
  <section>
    <h4 class="mb-1">Taly Featured</h4>

    <swiper [config]="sensibleConfig">
      <!-- Add Arrows -->
      <div class="d-flex justify-content-end mb-3 mt-lg-0 mt-2">
        <button class="me-3 bg-white border-0 prevNav">
          <i
            class="text-primary"
            [ngClass]="
              directionController.isLTR()
                ? 'fal fa-arrow-left'
                : 'fal fa-arrow-right'
            "
          ></i>
        </button>
        <button class="bg-white border-0 nextNav">
          <i
            class="text-primary"
            [ngClass]="
              directionController.isLTR()
                ? 'fal fa-arrow-right'
                : 'fal fa-arrow-left'
            "
          ></i>
        </button>
      </div>
      <!-- swiper  -->
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div>
            <img
              src="../../../../assets/images/images shop/bath-and-body-works.jpg"
              class="mb-3 img-fluid"
              alt=""
            />
            <h5>Bath and Body Works</h5>
            <p class="small text-muted mt-2">
              <i class="fal fa-check-circle text-success me-1 ms-0"></i
              >4-Installments
            </p>
          </div>
        </div>
        <div class="swiper-slide">
          <div>
            <img
              src="../../../../assets/images/images shop/dermalogica.jpg"
              class="mb-3 img-fluid"
              alt=""
            />
            <h5>Dermalogica</h5>
            <p class="small text-muted mt-2">
              <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
              30
            </p>
          </div>
        </div>
        <div class="swiper-slide">
          <div>
            <img
              src="../../../../assets/images/images shop/west-elm.jpg"
              class="mb-3 img-fluid"
              alt=""
            />
            <h5>West-elm</h5>
            <p class="small text-muted mt-2">
              <i class="fal fa-check-circle text-success me-1 ms-0"></i
              >4-Installments
            </p>
          </div>
        </div>
        <div class="swiper-slide">
          <div>
            <img
              src="../../../../assets/images/images shop/pottery-barn.jpg"
              class="mb-3 img-fluid"
              alt=""
            />
            <h5>Pottery-barn</h5>
            <p class="small text-muted mt-2">
              <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
              30 & 4-Installments
            </p>
          </div>
        </div>
      </div>
    </swiper>
  </section>

  <!-- search  -->
  <div class="input-group mt-4">
    <input
      autocomplete="off"
      class="form-control border-end-0 border rounded-pill text-primary"
      type="search"
      id="example-search-input"
      placeholder="Search..."
    />
    <span class="input-group-append">
      <button
        class="btn border-primary bg-white rounded-pill ms-n5"
        type="button"
      >
        <i class="fa fa-search text-primary"></i>
      </button>
    </span>
  </div>

  <div class="border-swiper">
    <swiper [config]="navTabSwipper" class="mt-3">
      <!-- Add Arrows -->
      <div class="d-flex justify-content-end mb-3 mt-lg-0 mt-2">
        <button class="me-3 bg-white border-0 prevNav"><i></i></button>
        <button class="bg-white border-0 nextNav"><i></i></button>
      </div>
      <!-- swiper  -->
      <div
        class="nav nav-tabs flex-nowrap swiper-wrapper"
        id="nav-tab"
        role="tablist"
      >
        <div
          class="swiper-slide nav-link active"
          data-bs-toggle="tab"
          data-bs-target="#nav-all"
          type="button"
          role="tab"
          aria-controls="nav-all"
          aria-selected="true"
        >
          All
        </div>
        <div
          class="swiper-slide nav-link"
          data-bs-toggle="tab"
          data-bs-target="#nav-electronic"
          type="button"
          role="tab"
          aria-controls="nav-electronic"
          aria-selected="false"
        >
          Electronics
        </div>
        <div
          class="swiper-slide nav-link"
          data-bs-toggle="tab"
          data-bs-target="#nav-Fashion"
          type="button"
          role="tab"
          aria-controls="nav-Fashion"
          aria-selected="false"
        >
          Fashion
        </div>
        <div
          class="swiper-slide nav-link"
          data-bs-toggle="tab"
          data-bs-target="#nav-Beauty"
          type="button"
          role="tab"
          aria-controls="nav-Beauty"
          aria-selected="false"
        >
          Beauty
        </div>
        <div
          class="swiper-slide nav-link"
          data-bs-toggle="tab"
          data-bs-target="#nav-Home"
          type="button"
          role="tab"
          aria-controls="nav-Home"
          aria-selected="false"
        >
          Home & Furniture
        </div>
        <div
          class="swiper-slide nav-link"
          data-bs-toggle="tab"
          data-bs-target="#nav-Kids"
          type="button"
          role="tab"
          aria-controls="nav-Kids"
          aria-selected="false"
        >
          Kids
        </div>
        <div
          class="swiper-slide nav-link"
          data-bs-toggle="tab"
          data-bs-target="#nav-Sports"
          type="button"
          role="tab"
          aria-controls="nav-Sports"
          aria-selected="false"
        >
          Sports & Fitness
        </div>
        <div
          class="swiper-slide nav-link"
          data-bs-toggle="tab"
          data-bs-target="#nav-Entertainment"
          type="button"
          role="tab"
          aria-controls="nav-Entertainment"
          aria-selected="false"
        >
          Entertainment
        </div>
      </div>
    </swiper>
  </div>

  <div class="tab-content mt-3" id="nav-tabContent">
    <div
      class="tab-pane fade show active"
      id="nav-all"
      role="tabpanel"
      aria-labelledby="nav-all-tab"
    >
      <section class="mb-4">
        <h4 class="mb-1">New stores</h4>

        <swiper [config]="sensibleConfig">
          <!-- Add Arrows -->
          <div
            class="d-flex justify-content-lg-end justify-content-center mb-3 mt-lg-0 mt-2"
          >
            <button class="me-3 bg-white border-0 prevNav">
              <i
                class="text-primary"
                [ngClass]="
                  directionController.isLTR()
                    ? 'fal fa-arrow-left'
                    : 'fal fa-arrow-right'
                "
              ></i>
            </button>
            <button class="bg-white border-0 nextNav">
              <i
                class="text-primary"
                [ngClass]="
                  directionController.isLTR()
                    ? 'fal fa-arrow-right'
                    : 'fal fa-arrow-left'
                "
              ></i>
            </button>
          </div>
          <!-- swiper  -->
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div>
                <img
                  src="../../../../assets/images/images shop/bath-and-body-works.jpg"
                  class="mb-3 img-fluid"
                  alt=""
                />
                <h5>Bath and Body Works</h5>
                <p class="small text-muted mt-2">
                  <i class="fal fa-check-circle text-success me-1 ms-0"></i
                  >4-Installments
                </p>
              </div>
            </div>
            <div class="swiper-slide">
              <div>
                <img
                  src="../../../../assets/images/images shop/dermalogica.jpg"
                  class="mb-3 img-fluid"
                  alt=""
                />
                <h5>Dermalogica</h5>
                <p class="small text-muted mt-2">
                  <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay
                  in 30
                </p>
              </div>
            </div>
            <div class="swiper-slide">
              <div>
                <img
                  src="../../../../assets/images/images shop/west-elm.jpg"
                  class="mb-3 img-fluid"
                  alt=""
                />
                <h5>West-elm</h5>
                <p class="small text-muted mt-2">
                  <i class="fal fa-check-circle text-success me-1 ms-0"></i
                  >4-Installments
                </p>
              </div>
            </div>
            <div class="swiper-slide">
              <div>
                <img
                  src="../../../../assets/images/images shop/pottery-barn.jpg"
                  class="mb-3 img-fluid"
                  alt=""
                />
                <h5>Pottery-barn</h5>
                <p class="small text-muted mt-2">
                  <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay
                  in 30 & 4-Installments
                </p>
              </div>
            </div>
          </div>
        </swiper>
      </section>
      <section>
        <h3 class="mb-4">Discover</h3>

        <div class="row">
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/bath-and-body-works.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Bath and Body Works</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/pottery-barn.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Pottery-barn</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/bath-and-body-works.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Bath and Body Works</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/bath-and-body-works.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Bath and Body Works</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/pottery-barn.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Pottery-barn</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/bath-and-body-works.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Bath and Body Works</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/pottery-barn.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Pottery-barn</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="tab-pane fade"
      id="nav-electronic"
      role="tabpanel"
      aria-labelledby="nav-electronic-tab"
    >
      <section>
        <div class="row">
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/bath-and-body-works.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Bath and Body Works</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/pottery-barn.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Pottery-barn</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/bath-and-body-works.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Bath and Body Works</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="tab-pane fade"
      id="nav-Fashion"
      role="tabpanel"
      aria-labelledby="nav-Fashion-tab"
    >
      <section>
        <div class="row">
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="tab-pane fade"
      id="nav-Beauty"
      role="tabpanel"
      aria-labelledby="nav-Beauty-tab"
    >
      <section>
        <div class="row">
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/bath-and-body-works.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Bath and Body Works</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/pottery-barn.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Pottery-barn</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/bath-and-body-works.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Bath and Body Works</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/bath-and-body-works.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Bath and Body Works</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/pottery-barn.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Pottery-barn</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="tab-pane fade"
      id="nav-Home"
      role="tabpanel"
      aria-labelledby="nav-Home-tab"
    >
      <section>
        <div class="row">
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/pottery-barn.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Pottery-barn</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="tab-pane fade"
      id="nav-Kids"
      role="tabpanel"
      aria-labelledby="nav-Kids-tab"
    >
      <section>
        <div class="row">
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/pottery-barn.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Pottery-barn</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="tab-pane fade"
      id="nav-Sports"
      role="tabpanel"
      aria-labelledby="nav-Sports-tab"
    >
      <section>
        <div class="row">
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/pottery-barn.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Pottery-barn</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/dermalogica.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>Dermalogica</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i>Pay in
                30 & 4-Installments
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-6 pe-2 mb-4">
            <div>
              <img
                src="../../../../assets/images/images shop/west-elm.jpg"
                class="mb-3 img-fluid"
                alt=""
              />
              <h5>West-elm</h5>
              <p class="small text-muted mt-2">
                <i class="fal fa-check-circle text-success me-1 ms-0"></i
                >4-Installments
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="tab-pane fade"
      id="nav-Entertainment"
      role="tabpanel"
      aria-labelledby="nav-Sports-tab"
    >
      <div class="text-center p-5 text-primary">Not found!</div>
    </div>
  </div>
</div>
