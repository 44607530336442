import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './content/about/about.component';
import { ContactUsComponent } from './content/contact-us/contact-us.component';
import { FaqComponent } from './content/faq/faq.component';
import { HowItWorksComponent } from './content/how-it-works/how-it-works.component';
import { HomeComponent } from './home/home.component';

import { TalyBusinessComponent } from './content/taly-business/taly-business.component';
import { WhatIsTalyComponent } from './content/faq/what-is-taly/what-is-taly.component';
import { PluginHowComponent } from './plugin-how/plugin-how.component';
import { ShopComponent } from './content/shop/shop.component';
import { PrivacyComponent } from './content/privacy/privacy.component';
import { TermsAndConditionsComponent } from './content/terms-and-conditions/terms-and-conditions';
import { TermsOfUseComponent } from './content/terms-of-use/terms-of-use.component';
import { RequestForm1Component } from './merchant/merchant-forms/request-form1/request-form1.component';
import { ThankYouComponent } from './content/thank-you/thank-you.component';
const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: '/home' },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      isHomePageOfThePage: true,
    },
  },
  { path: 'how', component: HowItWorksComponent, data: { state: 'how' } },
  { path: 'about', component: AboutComponent, data: { state: 'about' } },
  { path: 'privacy', component: PrivacyComponent, data: { state: 'privacy' } },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    data: { state: 'terms' },
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
    data: { state: 'terms' },
  },
  {
    path: 'contactus',
    component: ContactUsComponent,
    data: { state: 'contactus' },
  },
  {
    path: 'merchant-request-form',
    component: RequestForm1Component,
    data: { state: 'contactus' },
  },
  { path: 'faq', component: FaqComponent, data: { state: 'faq' } },
  {
    path: 'forbusiness',
    component: TalyBusinessComponent,
    data: { isHomePageOfThePage: true },
  },
  {
    path: 'faqanswers',
    component: WhatIsTalyComponent,
    data: { state: 'faqanswers' },
  },
  {
    path: 'howitworks',
    component: PluginHowComponent,
    data: { state: 'howitworks' },
  },
  { path: 'shop', component: ShopComponent, data: { state: 'shop' } },
  { path: 'payment/thankyou', component: ThankYouComponent, data: { state: 'thankyou' } },
  {
    path: 'consumer',
    loadChildren: () =>
      import('./consumer/consumer.module').then((m) => m.ConsumerModule),
  },
  // {
  //   path: 'merchants',
  //   loadChildren: () =>
  //     import('./merchant/merchant.module').then((m) => m.MerchantModule),
  // },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./merchant-checkout/merchant-checkout.module').then(
        (m) => m.MerchantCheckoutModule
      ),
  },
  { path: '**', redirectTo: '/home' },

  //ar routes
  { path: 'ar/home', component: HomeComponent },
  {
    path: 'ar/consumer',
    loadChildren: () =>
      import('./consumer/consumer.module').then((m) => m.ConsumerModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
