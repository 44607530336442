import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
  HostListener,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
  RouterOutlet,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Feather from 'feather-icons';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { threadId } from 'worker_threads';
import { EventBusService } from './Core/services/shared/event-bus.service';
import { AuthenticationService } from './Core/services/signin/auth.service';
import { routerTransition } from './utilities/animations';
import { TextDirectionController } from './utilities/textDirectionController';
import { StorageService } from './Core/services/storage/storage.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { UserIdleComponent } from './user-idle/user-idle.component';
import { NgxHttpLoaderService, NgxLoader } from 'ngx-http-loader';
import { TokenStorageService } from './Core/interceptors/token-storage.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routerTransition,
    // animation triggers go here
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  sidebarExpanded = true;
  orderId: any = null;
  public loader = NgxLoader;
  public filterHttpMethods: string[] = ['GET', 'DELETE', 'POST', 'PUT'];

  directionController = new TextDirectionController();
  isIdleOpen: boolean = false;
  bsModalRef?: BsModalRef;

  title = 'TalyPay Website';
  IsHomePage: boolean = false;
  isHomePage: boolean = false;
  isHowitWorksPage: boolean = false;
  pageSection = new BehaviorSubject('home');
  urlParam: string = '';
  languageSelected: string = '';
  timeOutCount: number;

  eventBusSub?: Subscription;
  isLoggedIn: boolean = false;
  isDeveloperDocs: boolean = false;

  constructor(
    private storageService: StorageService,
    public route: Router,
    public translate: TranslateService,
    private eventBusService: EventBusService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private ngxngxhttploaderhttploader: NgxHttpLoaderService,
    private idle: Idle,
    private keepalive: Keepalive,
    @Inject(DOCUMENT) private document: Document,
    private render: Renderer2,
    public tokenStorageService: TokenStorageService
  ) {
    sessionStorage.setItem('lang', 'en-US');
    sessionStorage.setItem('country', 'Kuwait');
    // sets an idle timeout of 30 seconds, for testing purposes.
    idle.setIdle(1920);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(1900);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => (this.idleState = 'No longer idle.'));
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.openModalWithComponent();
    });
    idle.onIdleStart.subscribe(() => (this.idleState = "You've gone idle!"));
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.languageSelected =
      sessionStorage.getItem('lang') == 'en-US' ? 'en' : 'ar';
    this.changeCssFile(this.languageSelected);
  }
  reset() {
    this.idle.stop();
    this.idleState = 'Not started.';
    this.timedOut = false;
    this.lastPing = null;
  }
  onActivateRoute(outlet: RouterOutlet) {
    outlet.activatedRoute.data
      .pipe(
        map((data) => {
          this.isDeveloperDocs = data?.isDeveloperDocs == true ? true : false;
          this.isHomePage = !data?.state ? true : false;
          this.isHowitWorksPage = data?.state === 'how' ? true : false;
        })
      )
      .toPromise()
      .then();
  }

  @Output() moveStep = new EventEmitter<number>();

  @ViewChild('load') load: ElementRef;
  isCountrySelected: boolean;

  isPluginPage: boolean;

  idleLogoutHandler() {
    if (this.tokenStorageService.getToken() != null) {
      //this.openModalWithComponent();
      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
    }
  }

  ngOnInit(): void {
    this.eventBusService.hideHeaderBehaviour.subscribe((data) => {
      if (data) {
        this.orderId = true;
      } else {
        this.orderId = false;
      }
    });

    this.idleLogoutHandler();
    // this.orderId = this.activatedRoute.snapshot.paramMap.get('orderid');
    this.storageService.watchStorage().subscribe((data: string) => {
      if (data === 'changed' && this.tokenStorageService.getToken()) {
        this.idleLogoutHandler();
      } else {
        this.reset();
      }
    });

    this.route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        let url = event.url;
        if (url.includes('home')) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = 'assets/js/header-home.js';

          this.IsHomePage = true;
          this.pageSection.next('home');

          document.head.appendChild(script);
        } else {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = 'assets/js/header.js';
          document.head.appendChild(script);

          this.IsHomePage = false;
          this.pageSection.next('pages');
        }

        if (url.includes('howitworks')) {
          this.isPluginPage = true;
        } else {
          this.isPluginPage = false;
        }
      }
    });

    this.eventBusSub = this.eventBusService.on('logout', () => {
      //this.logout();
    });

    sessionStorage.getItem('country')
      ? (this.isCountrySelected = true)
      : this.setdefaultCountry();
  }

  setdefaultCountry() {
    this.isCountrySelected = true;
    sessionStorage.setItem('country', 'Kuwait');
  }
  ngAfterViewInit() {
    Feather.replace();
    if (this.isCountrySelected)
      setTimeout(() => {
        this.render.addClass(this.load.nativeElement, 'd-none');
      }, 1000);
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  changeCssFile(lang: string) {
    let headTag = this.document.getElementsByTagName(
      'head'
    )[0] as HTMLHeadElement;

    let bodyTag = this.document.getElementsByTagName(
      'body'
    )[0] as HTMLBodyElement;

    let existingLink = this.document.getElementById(
      'langCss'
    ) as HTMLLinkElement;

    let bundleName = 'arBootstrap.css';
    let mainStyle = 'mainStyle.css';

    if (lang == 'ar') {
      let newLink = this.document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.type = 'text/css';
      newLink.id = 'langCss';
      newLink.href = bundleName;
      document.getElementsByTagName('head')[0].appendChild(newLink);
    }

    let mainLink = this.document.createElement('link');
    mainLink.rel = 'stylesheet';
    mainLink.type = 'text/css';
    mainLink.id = 'langCss';
    mainLink.href = mainStyle;
    document.getElementsByTagName('head')[0].appendChild(mainLink);
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/consumer/login']);

    // this.isLoggedIn = false;
    // this.userIdle.stopTimer();
    // this.userIdle.resetTimer();
    setTimeout(function () {
      sessionStorage.setItem('country', 'Kuwait');
      sessionStorage.getItem('country');
      sessionStorage.setItem('lang', 'en-US');
      sessionStorage.getItem('lang');
    }, 2000);
  }

  onCountrySelected(event) {
    window.location.reload();
  }

  openModalWithComponent() {
    // this.modalService?.hide();

    const initialState = {
      idleState: this.idleState,
      timedOut: this.timedOut,
    };

    this.bsModalRef = this.modalService.show(UserIdleComponent, {
      backdrop: 'static',
      class: 'modal-dialog-centered',
    });

    this.bsModalRef.content.event.subscribe((res) => {
      this.reset();
      this.logout();
      this.isIdleOpen = false;
    });
  }
}
