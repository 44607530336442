import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit {

 @Input() alertShow:boolean;
 @Output() isdismiss= new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {}

  onDismiss(){
   this.isdismiss.emit(true);
  }
}
