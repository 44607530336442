import { Component, OnInit } from '@angular/core';
import { ConsumerLovService } from 'src/app/Core/services/lov/consumer-lovs.service';
import { TextDirectionController } from 'src/app/utilities/textDirectionController';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  directionController = new TextDirectionController();
  constructor(private lovService: ConsumerLovService, private router: Router) {
    this.router.events.subscribe((event) => {
      this.showSingupBtn = sessionStorage.getItem('JWT_TOKEN') ? false : true;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  }
  year: number = new Date().getFullYear();
  showSingupBtn: boolean = true;
  countries: any[];
  country;

  ngOnInit(): void {
    this.showSingupBtn = sessionStorage.getItem('JWT_TOKEN') ? false : true;
    this.lovService.getCountries().subscribe((res) => {
      this.countries = res;
      this.country = this.getSelectedCountry(
        this.countries,
        sessionStorage.getItem('country')
      )[0];
    });
  }

  onSelectCountry(i) {
    this.country = this.countries[i];
    sessionStorage.setItem('country', this.countries[i].nameEn);
    window.location.reload();
  }

  getSelectedCountry(countries: any[], countryName) {
    return countries.filter((elem) => elem.nameEn == countryName);
  }
}
