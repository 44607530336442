

  <div class="row gap-12 d-flex justify-content-center m-0">
    <div class="col-lg-12 border p-4 approval">
      <a role="button" id="back-btn" (click)="backOnNationalId()"  *ngIf="showReject" class="btn-close">
       
      </a>
      <h4 class="mt-2 text-center">Approval Request</h4>
      <p  *ngIf="!showReject"
        [ngClass]="
          showMessage
            ? 'alert alert-success text-center mt-3'
            : 'text-center desc mt-3'
        "
      >
        {{
          showMessage
            ? "Request Approved Successfully!"
            : "Please approve the request in your Kuwait Mobile ID app"
        }}
      </p>
      <p class="mt-3 text-center desc mt-3 text-danger"
      >
        {{
          showReject
            ? "Request Declined!"
            : ""
        }}
      </p>

      <div class="row mt-4" *ngIf="!showReject">
        <div class="">
          <img
            *ngIf="!showMessage"
            src="../../assets/images-new/request.png"
            class="card-img-top"
            alt="Request wallpaper"
          />
          <img
            *ngIf="showMessage"
            src="../../assets/images-new/approval.png"
            class="card-img-top"
            alt="Approval wallpaper"
          />
        </div>
        <span class="text-center timer" *ngIf="count != 0">{{ displayTimer }} </span>
      </div>
     
    </div>
  </div>
