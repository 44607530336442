<!-- Marchant request form step-2 page here  -->
<div class="container section-inner">
  <div class="section-pad d-flex flex-column mx-auto border shadow">
    <!-- back button here -->
    <a href="javascript:void(0)" role="button" id="back-btn">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-chevron-left mb-2"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
        /></svg
      >Back
    </a>

    <h3 class="mt-5 mb-4">Contact details</h3>

    <label class="small text-dark mb-1"
      >Business email <span class="text-danger">*</span></label
    >
    <input
      autocomplete="off"
      type="email"
      name="marchant-email"
      class="input-text mb-4"
    />

    <label class="small text-dark mb-1"
      >Mobile number <span class="text-danger">*</span></label
    >
    <input
      autocomplete="off"
      type="text"
      inputmode="numeric"
      maxlength="12"
      name="marchant-number"
      class="input-text mb-4"
    />

    <!-- Area  -->
    <label class="small text-dark mb-1"
      >Area <span class="text-danger">*</span></label
    >
    <select name="Marchant-area" class="input-text form-select mb-4">
      <option selected>Kuwait City</option>
      <option>Area xyz</option>
      <option>Area xyz</option>
      <option>Area xyz</option>
    </select>

    <!-- address here  -->
    <div class="row d-flex mb-2">
      <div class="col-sm-6 mb-3">
        <!-- block  -->
        <div class="d-flex flex-column">
          <label class="small text-dark mb-1">Block</label>
          <input
            autocomplete="off"
            type="text"
            name="marchant-block"
            class="input-text"
          />
        </div>
      </div>

      <div class="col-sm-6">
        <!-- street  -->
        <div class="d-flex flex-column">
          <label class="small text-dark mb-1">Street</label>
          <input
            autocomplete="off"
            type="text"
            name="marchant-street"
            class="input-text"
          />
        </div>
      </div>
    </div>

    <!-- building  -->
    <label class="small text-dark mb-1">Building</label>
    <input
      autocomplete="off"
      type="text"
      name="marchant-building"
      class="input-text mb-4"
    />

    <!-- Buiding info here  -->
    <div class="row d-flex mb-4">
      <div class="col-sm-6 mb-3">
        <!-- floor  -->
        <div class="d-flex flex-column">
          <label class="small text-dark mb-1">Floor</label>
          <input
            autocomplete="off"
            type="text"
            name="marchant-floor"
            class="input-text"
          />
        </div>
      </div>

      <div class="col-sm-6">
        <!-- office no.  -->
        <div class="d-flex flex-column">
          <label class="small text-dark mb-1">Office no.</label>
          <input
            autocomplete="off"
            type="text"
            name="marchant-office-num"
            class="input-text"
          />
        </div>
      </div>
    </div>

    <!-- Next btn  -->
    <a href="javascript:void(0)" role="button" id="next-btn">Next</a>
  </div>
</div>
