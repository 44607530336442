import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-in-table',
  templateUrl: './search-in-table.component.html',
  styleUrls: ['./search-in-table.component.scss']
})
export class SearchInTableComponent implements OnInit {

  constructor() { }

  @Output() newArray= new EventEmitter<any[]>();
  @Output() clearSearch= new EventEmitter<boolean>();
  @Input() dataArray:any[];
  @Input() searchKey:any[];
  ngOnInit(): void {
  }

  searchItem: any;
  onSearch(dataArray:any[],key) {
    if (this.searchItem == '') {
      this.clearSearch.emit(true);
    }
    else {
      let newDataArray= dataArray.filter(elem => elem[key].toString().toLocaleLowerCase().match(this.searchItem.toString().toLocaleLowerCase()));
      this.newArray.emit(newDataArray);
    }
  }
}
