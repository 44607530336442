<!-- contact us form page  -->

<section class="section-inner container section-pad">
  <!-- contact-form section  -->
  <div class="p-4 d-flex flex-column mx-auto text-primary">
    <!-- heading  -->
    <h3 class="mb-1 text-primary">
      {{ "general.contact.submitReq" | translate }}
    </h3>
    <p class="mb-4 text-primary">
      {{ "general.contactUsCaption" | translate }}
    </p>

    <!-- form start from here -->
    <form autocomplete="off" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-lg-6">
          <!-- full name  -->
          <!-- <label class="small text-dark mb-1">Merchant Name</label> -->
          <input autocomplete="off" type="text" name="contact-full-name" placeholder="Merchant Name" id="name"
            class="txtbox mb-4" formControlName="name" />
        </div>
        <div class="col-lg-6">
          <!-- email  -->
          <!-- <label class="small text-dark mb-1">Email Address</label> -->
          <input autocomplete="off" type="email" name="contact-email" id="email" placeholder="Email Address"
            class="txtbox mb-4" formControlName="email" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <!-- full name  -->
          <!-- <label class="small text-dark mb-1">Enter your Name</label> -->
          <input autocomplete="off" type="text" name="full-name" placeholder="Enter your Name" id="name"
            class="txtbox mb-4" formControlName="yourname" />
        </div>
        <div class="col-lg-6">
          <!-- email  -->
          <!-- <label class="small text-dark mb-1">Enter your Mobile </label> -->
          <input autocomplete="off" type="text" name="contact-mobile" id="mobile" placeholder="Enter your Mobile number"
            class="txtbox mb-4" formControlName="mobile" />
        </div>
      </div>

      <!-- mobile number  -->
      <!-- <label class="small text-dark mb-1">Mobile number <span class="text-danger">*</span></label>
                    <input  autocomplete="off" type="text" inputmode="numeric" maxlength="12" name="mobile-number" class="txtbox mb-4"> -->

      <!-- catogory 
      <label class="small mb-1" for="category">Category</label>
      <select
        autocomplete="off"
        name="contact-subject"
        id="category"
        class="txtbox form-select mb-4"
        formControlName="category"
      >
        <option selected>Customer</option>
        <option>Merchant</option>
      </select> -->
      <!-- Subject  -->
      <input autocomplete="off" type="text" name="contact-full-name" placeholder="Subject" id="subject"
        class="txtbox mb-4" formControlName="subject" />

      <!-- message  -->
      <textarea name="contact-message" cols="30" rows="8" id="message" placeholder="Message" class="txtbox"
        formControlName="message"></textarea>

      <!-- proceed-btn  -->
      <button type="submit" role="button" class="btn btn-dark rounded-pill mt-4" [disabled]="contactForm.invalid">
        <i class="fal fa-arrow-right me-2 ms-0"></i>{{ "general.contact.submitReq" | translate }}
      </button>
    </form>
  </div>

  <!-- <img src="assets/images/forbusiness/Path 1388.png" class="position-absolute img-fluid" style="top: 44% !important; right: 19%; object-fit: contain !important;" alt=""> -->
</section>