import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/env.service';
import { environment } from 'src/environments/environment';
import { CaptchaModel } from '../../models/capcha.model';
import { MerchantProfile } from '../../models/merchantProfile/signup.model';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(private http: HttpClient, private environment: EnvService) { }

  postInterestRequest(signupData: MerchantProfile) {
    return this.http.post(`${this.environment.ApiUrl}/merchants/interestRequest`, signupData);
  }

  getCaptcha(): Observable<CaptchaModel> {
    return this.http.get<CaptchaModel>(`${this.environment.ApiUrl}/merchants/captcha`);
  }
}
