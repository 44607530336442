<div class="section-inner">
  <section class="p-5 row" style="padding-bottom: 5rem !important; color: #fff">
    <div class="col-lg-12 col-12 d-flex flex-column align-items-lg-start align-items-center container">
      <h1 class="mb-3 mob-textcenter" style="white-space: break-spaces;">

        {{"content.forBusinessPage.hero.title"|translate}}
      </h1>
      <h5 class="mob-textcenter px-4 p-lg-0">

        {{"content.forBusinessPage.hero.caption1"|translate}}

      </h5>

      <button class="btn btn-success mt-3 rounded-pill" routerLink="/merchant-request-form">
        <i class="fal fa-arrow-right me-2 ms-0"></i>{{"footer.menus.1.link1" | translate}}
      </button>
    </div>
  </section>

  <section class="container mb-5" style="color: #fff">
    <div class="ms-0 rounded-3 row businessbox2">
      <div class="col-lg-4 mb-3 p-0">
        <div style="background-color: #c6e7e7; border-radius: 15px; height: 100%"
          class="p-5 d-flex flex-column justify-content-center align-items-center text-center">
          <img src="assets/images/forbusiness/Path 1415.png" alt="" class="mb-3 img-fluid" style="
              height: 35px !important;
              width: 61px !important;
              margin: 0 auto;
            " />
          <h4 class="text-primary mb-1 w-100 text-center">
            {{"content.forBusinessPage.hero.box"|translate}}
          </h4>
          <p class="text-primary">{{"content.forBusinessPage.hero.boxcontent"|translate}}
          </p>
        </div>
      </div>

      <div class="col-lg-4 mb-3 p-0">
        <div style="
            background-color: #f2ced8;
            border-radius: 15px;
            margin: 0px 3px;
            height: 100%;
          " class="p-5 d-flex flex-column justify-content-center align-items-center text-center">
          <img src="assets/images/forbusiness/Path 1417.png" alt="" class="mb-3 img-fluid" style="
              height: 34px !important;
              width: 34px !important;
              margin: 0 auto;
            " />

          <h4 class="text-primary mb-1 mb-1 w-100 text-center">
            {{"content.forBusinessPage.hero.box1"|translate}}
          </h4>
          <p class="text-primary">
            {{"content.forBusinessPage.hero.boxcontent1"|translate}}

          </p>
        </div>
      </div>

      <div class="col-lg-4 mb-3 p-0">
        <div style="background-color: #71c5d8; border-radius: 15px; height: 100%"
          class="p-5 d-flex flex-column justify-content-center align-items-center text-center">
          <img src="assets/images/forbusiness/Path 1419.png" alt="" class="mb-3 img-fluid" style="
              height: 34px !important;
              width: 34px !important;
              margin: 0 auto;
            " />

          <h4 class="text-primary mb-1 mb-1 w-100 text-center">
            {{"content.forBusinessPage.hero.box2"|translate}}
          </h4>
          <p class="text-primary">
            {{"content.forBusinessPage.hero.boxcontent2"|translate}}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="container mb-5" style="color: #fff">
    <div class="businessbox text-lg-start text-center">
      <h2 class="mb-3 col-lg-6">{{"content.forBusinessPage.hero.content"|translate}}</h2>
      <h5>
        {{"content.forBusinessPage.hero.dec"|translate}}
      </h5>

      <button class="btn btn-success mt-3 rounded-pill" routerLink="/merchant-request-form">
        <i class="fal fa-arrow-right me-2 ms-0"></i>{{"footer.menus.1.link1" | translate}}
      </button>
    </div>
  </section>
</div>
<!-- <div class=" py-5 mb-5" style="background-color: #f4f3f2;">
  <section class=" container">
    <div class="row">
      <div class="col-lg-6">
        <h6 class="small mb-3">3 Steps</h6>
        <h4 class="mb-3">While browsing</h4>
        <p class="mb-5">Shoppers learn that they can buy now and spread
          the cost over 4 instalments at no additional
          complexity or cost at checkout.</p>

        <h4 class="mb-3">At checkout</h4>
        <p class="mb-5">After selecting 4 instalments, shoppers can enter
          their debit orcredit card details. No signup required.</p>

        <h4 class="mb-3">On shipment</h4>
        <p class="mb-5">Once the order is shipped, we pay you up front and
          in full and collect your customer’s first payment.</p>
      </div>
      <div class="col-lg-6 text-center">
        <img src="assets/images/forbusiness/amy-shamblen-euqiHwS38Rw-unsplash.png" alt="" class=" img-fluid">
      </div>
    </div>

  </section>
</div> -->

<section class="container mb-5" style="color: #fff">
  <h2 class="mb-2">{{"content.forBusinessPage.hero.desc1"|translate}} </h2>
  <p class="mb-4">
    {{"content.forBusinessPage.section5.p"|translate}}
  </p>
  <div class="d-flex flex-wrap justify-content-start align-items-center">
    <div
      class="rounded-3 business_module bg-white mx-3 shadow d-flex flex-column justify-content-center align-items-center mb-2"
      style="height: 148px; width: 160px">
      <img src="assets/images/forbusiness/android icon.png" alt="" class="img-fluid mb-3" />
      <p for="" class="fw-bold text-center text-primary custom_components">
        android
      </p>
    </div>
    <div
      class="rounded-3 business_module bg-white mx-3 shadow d-flex flex-column justify-content-center align-items-center mb-2"
      style="height: 148px; width: 160px">
      <img src="assets/images/forbusiness/woocommerce icon.png" alt="" class="img-fluid mb-3" />
      <p for="" class="fw-bold text-center text-primary custom_components">
        woocommerce
      </p>
    </div>
    <div
      class="rounded-3 business_module bg-white mx-3 shadow d-flex flex-column justify-content-center align-items-center mb-2"
      style="height: 148px; width: 160px">
      <img src="assets/images/forbusiness/shopify icon.png" alt="" class="img-fluid mb-3" />
      <p for="" class="fw-bold text-center text-primary custom_components">
        shopify
      </p>
    </div>
    <div
      class="rounded-3 business_module bg-white mx-3 shadow d-flex flex-column justify-content-center align-items-center mb-2"
      style="height: 148px; width: 160px">
      <img src="assets/images/forbusiness/magento icon.png" alt="" class="img-fluid mb-3" />
      <p for="" class="fw-bold text-center text-primary custom_components">
        magento
      </p>
    </div>
  </div>
</section>

<section class="faq-section m-0 text-primary" style="padding: 45px 0px">
  <div class="container">
    <!-- faq section  -->
    <div>
      <h3 class="mb-4 mob-textcenter" style="text-align: center; color: #fff">
        {{ "content.forBusinessPage.section6.title" | translate }}
      </h3>
      <div class="accordion accordion-flush" id="accordionFlushExample" style="width: 100%">
        <div class="accordion-item faqs-section">
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              {{ "home.section4.faq.0.ques" | translate }}
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              {{ "home.section4.faq.0.ans" | translate }}
            </div>
          </div>
        </div>
        <div class="accordion-item faqs-section">
          <h2 class="accordion-header" id="flush-headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
              {{"content.faqPage.tabs.tab2Content.0.ques2" | translate}}
              
             
            </button>
          </h2>
          <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              {{"content.faqPage.tabs.tab2Content.0.ans2" | translate}}
            </div>
          </div>
        </div>
        <div class="accordion-item faqs-section">
          <h2 class="accordion-header" id="flush-headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
             
              {{"content.faqPage.tabs.tab2Content.1.ques1" | translate}}
            </button>
          </h2>
          <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              {{ "home.section4.faq.2.ans" | translate }} Click
              <a routerLink="/merchant-request-form">here</a> to apply
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>