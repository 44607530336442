import { CreditCardRequest } from './../../models/profile/creditcard.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UserProfile } from '../../models/profile/userprofile.model';
import { Address } from '../../models/profile/address.model';
import { ContactDetails } from '../../models/profile/contact.model';
import { ChangePassword } from '../../models/profile/changepassword.model';
import { ChangeContact } from '../../models/profile/changeContact.model';
import { changeMobile } from '../../models/profile/changeMobile.model';
import { EnvService } from 'src/app/env.service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(private http: HttpClient, private environment: EnvService) { }

  saveCreditCard(creditCardRequest: CreditCardRequest) {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/profile/creditCard`,
      creditCardRequest
    );
  }

  saveProfile(userProfile: any): any {
    return this.http.put(
      `${this.environment.ApiUrl}/accounts/profile`,
      userProfile
    );
  }

  getUserProfile() {
    return this.http.get<UserProfile>(
      `${this.environment.ApiUrl}/accounts/profile`
    );
  }

  getUserProfileAddress() {
    return this.http.get<Address>(
      `${this.environment.ApiUrl}/accounts/profile/address`
    );
  }

  saveProfileAddress(address: Address): any {
    return this.http.put(
      `${this.environment.ApiUrl}/accounts/profile`,
      address
    );
  }

  getContact() {
    return this.http.get<ContactDetails>(
      `${this.environment.ApiUrl}/accounts/profile/contact`
    );
  }
  getCardDetails(): Observable<CreditCardRequest[]> {
    return this.http.get<CreditCardRequest[]>(
      `${this.environment.ApiUrl}/accounts/profile/creditCard`
    );
  }
  postCardDetails(cardDetails: CreditCardRequest): Observable<any> {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/profile/creditCard`,
      cardDetails
    );
  }
  deleteCardDetails(id: number) {
    return this.http.delete(
      `${this.environment.ApiUrl}/accounts/profile/creditCard/${id}`
    );
  }

  editCardDetails(id: number, cardDetails): Observable<any> {
    return this.http.put(
      `${this.environment.ApiUrl}/accounts/profile/creditCard/${id}`,
      cardDetails
    );
  }

  changePassword(changePassword: ChangePassword) {
    return this.http.put(
      `${this.environment.ApiUrl}/accounts/profile/changePassword`,
      changePassword
    );
  }

  postIdentity(formData, documentType, documentPage) {
    return this.http.post(
      `${this.environment.ApiUrl}/accounts/profile/identityDoc?documentType=${documentType}&documentPage=${documentPage}`,
      formData
    );
  }

  getIdentity() {
    return this.http.get(
      `${this.environment.ApiUrl}/accounts/profile/identityDoc`
    );
  }

  putChangeNumber(newNumber: changeMobile, contactType: string) {
    return this.http.put(
      `${this.environment.ApiUrl}/accounts/profile/${contactType}`,
      newNumber
    );
  }
  putChangeEmail(email: any): Observable<any> {
    return this.http.put(
      `${this.environment.ApiUrl}/accounts/profile/changeEmailAddress`,
      email
    );
  }

  confirmOtp(otp, contactType: string) {
    return this.http.put(
      `${this.environment.ApiUrl}/accounts/profile/${contactType}/confirm`,
      otp
    );
  }

  confirmEmail(otp) {
    return this.http.put(
      `${this.environment.ApiUrl}/accounts/profile/changeEmailAddress/confirm`,
      otp
    );
  }

  setDefaultCard(id, creditcard) {
    return this.http.put(
      `${this.environment.ApiUrl}/accounts/profile/creditCard/${id}/default`,
      creditcard
    );
  }

  // retrieveCharge(chrId: any, paymentType: any): Observable<any> {
  //   const url =
  //     paymentType == 'knet'
  //       ? 'accounts/payment/retrieve-charge'
  //       : 'accounts/profile/retrieve-charge';
  //   return this.http.get(`${this.environment.ApiUrl}/${url}/${chrId}`);
  // }
  retrieveCharge(chrId: any, paymentType: any): Observable<any> {
    const url =
      paymentType == 'knet' ? 'accounts/payment/status' : 'accounts/profile/retrieve-charge';
    return this.http.get(`${this.environment.ApiUrl}/${url}/${chrId}`);
  }
  retrieveInvoice(tapId) {
    return this.http.get(
      `${this.environment.ApiUrl}/accounts/payment/retrieve-invoice/${tapId}`,

    );
  }

}
