import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConsumerLovService } from '../../services/lov/consumer-lovs.service';

@Component({
  selector: 'app-countycode-selector',
  templateUrl: './countycode-selector.component.html',
  styleUrls: ['./countycode-selector.component.scss'],
})
export class CountycodeSelectorComponent implements OnInit {
  constructor(private lovService: ConsumerLovService) {}

  @Output() countrycode = new EventEmitter<any>();
  country;
  ngOnInit(): void {
    let countryName = sessionStorage.getItem('country');
    this.lovService.getCountries().subscribe((res) => {
      this.getCountry(res, countryName);
      this.countrycode.emit('+' + this.country.countryCode.toString());
    });
  }

  getCountry(countries: any[], countryName: string) {
    this.country = countries.filter(
      (elem) =>
        elem.nameEn.toLocaleLowerCase() == countryName.toLocaleLowerCase()
    )[0];
  }
}
