<div>
  <select
    autocomplete="off"
    class="form-select fw-bold  border-dark background-white"
    name="language"
    style="width: 5.57rem"
    [(ngModel)]="language"
    (change)="onLanguageSelect()"
  >
    <option value="english" class="text-center">Eng</option>
    <option value="arabic" class="text-center">عربى</option>
  </select>
</div>
