import { Component, OnInit } from '@angular/core';
import { TextDirectionController } from 'src/app/utilities/textDirectionController';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-shop-categories',
  templateUrl: './shop-categories.component.html',
  styleUrls: ['./shop-categories.component.scss']
})
export class ShopCategoriesComponent implements OnInit {

  constructor() { }
  mobileWidth = window.matchMedia(("(max-width: 991px)"));

  directionController= new TextDirectionController();
  ngOnInit(): void {
  }

  sensibleConfig: SwiperOptions = {
    navigation: {
      nextEl: '.nextNav',
      prevEl: '.prevNav'
    },
    spaceBetween: 30,
    slidesPerView: this.mobileWidth.matches ? 1 : 3,
    loop: true,
    keyboard: true,
    grabCursor: true,
    fadeEffect: {
      crossFade: true
    },
  };
}
