import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAlertMsg]',
})
export class AlertMsgDirective {
  @Input() alertText: any;
  constructor(private elem: ElementRef) {
    setTimeout(() => {
      this.elem.nativeElement.childNodes[0].childNodes[1].innerHTML =
        this.alertText;
    }, 100);
  }
}
