import { Component, Input, OnInit } from '@angular/core';
import { TextDirectionController } from 'src/app/utilities/textDirectionController';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-featured-merchant',
  templateUrl: './featured-merchant.component.html',
  styleUrls: ['./featured-merchant.component.scss'],
})
export class FeaturedMerchantComponent implements OnInit {
  constructor() {}
  @Input() isDashboardPage: boolean;
  sensibleConfig: SwiperOptions;

  featuredMerchants: any[] = [
    { logo: 'CAVARATY.png', url: 'https://www.cavaraty.com' },
  ];

  mobileWidth = window.matchMedia('(max-width: 991px)');

  directionController = new TextDirectionController();
  ngOnInit(): void {
    this.sensibleConfig = {
      navigation: {
        nextEl: '.nextNav',
        prevEl: '.prevNav',
      },
      // spaceBetween: 20,
      // slidesPerView: this.mobileWidth.matches
      //   ? 1
      //   : this.isDashboardPage
      //   ? 3
      //   : 4,
      loop: true,
      keyboard: true,
      grabCursor: true,
      fadeEffect: {
        crossFade: true,
      },
      observer: true,
      centeredSlides: this.mobileWidth.matches ? true : false,
    };
  }
}
