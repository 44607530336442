import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  navtabsClass: string[] = [];
  constructor() { }

  ngOnInit(): void {
    this.navtabsClass = ["navTabActive", ""]
  }
  
  onNavTab(num: number) {
    switch (num) {
      case 1:
        this.navtabsClass = ["navTabActive", ""]
        break;

      case 2:
        this.navtabsClass = ["", "navTabActive"]
        break;

      default:
        break;
    }
  }
}
