<div class="text-primary">
  <div class="py-1 px-3 px-lg-4 d-flex flex-column">
    <h4 class="mt-4">{{ "general.creatAcc" | translate }}</h4>
    <p>{{ "general.fillMandatory" | translate }}</p>

    <div class="row mt-4">
      <form autocomplete="off" [formGroup]="customerForm">
        <div class="mb-3">
          <label for="firstName" class="form-label">
            {{ "general.contact.firstName" | translate }}
            <span class="text-danger"> * </span>
          </label>
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            id="firstName"
            formControlName="firstName"
            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
            maxlength="25"
            noSpecialCharacter
            avoidNumbers
          />
          <div
            *ngIf="submitted && f.firstName.invalid"
            class="invalid-feedback"
          >
            <div>First Name is required</div>
          </div>
        </div>
        <div class="mb-3">
          <label for="lastName" class="form-label">
            {{ "general.contact.lastName" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            id="lastName"
            formControlName="lastName"
            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
            maxlength="26"
            noSpecialCharacter
            avoidNumbers
          />
          <div *ngIf="submitted && f.lastName.invalid" class="invalid-feedback">
            <div>Last Name is required</div>
          </div>
        </div>
        <div class="mb-3">
          <label for="emailAddress" class="form-label">
            {{ "general.contact.email" | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            autocomplete="off"
            type="email"
            class="form-control"
            id="emailAddress"
            formControlName="emailAddress"
            [ngClass]="{ 'is-invalid': submitted && f.emailAddress.errors }"
          />
          <div
            *ngIf="submitted && f.emailAddress.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.emailAddress.errors.required">
              Email Address is required
            </div>
            <div *ngIf="f.emailAddress.errors.email">
              Email Address must be a valid
            </div>
          </div>
        </div>

        <label class="form-label">
          Gender <span class="text-danger">*</span>
        </label>
        <div class="radio-inline d-flex">
          <div class="me-2 ms-0 d-flex align-items-center">
            <input
              autocomplete="off"
              id="gender"
              class="me-1 ms-0"
              type="radio"
              name="gender"
              value="Male"
              formControlName="gender"
            />
            <label class="radio-label">Male</label>
          </div>
          <div class="d-flex align-items-center">
            <input
              autocomplete="off"
              id="gender"
              type="radio"
              class="me-1 ms-0"
              name="gender"
              value="Female"
              formControlName="gender"
            />
            <label class="radio-label">Female</label>
          </div>
        </div>
        <div *ngIf="submitted && f.gender.invalid" class="mt-1">
          <div *ngIf="f.gender.errors.required" class="small text-danger">
            Gender is required
          </div>
        </div>

        <div class="mb-3 mt-3">
          <label for="dob" class="mb-1">
            Date of birth <span class="text-danger">*</span>
          </label>

          <input
            type="text"
            placeholder="DD-MM-YYYY"
            formControlName="dob"
            class="form-control ms-0 me-2 date-pk"
            bsDatepicker
            id="dob"
            [bsConfig]="bsConfig"
            [maxDate]="dobCurrentDate"
            [readonly]="true"
          />

          <div *ngIf="submitted && f.dob.invalid">
            <div *ngIf="f.dob.errors.required" class="text-danger small">
              Date of birth is required
            </div>
          </div>
        </div>

        <div class="d-grid mt-4">
          <button
            (click)="onSubmit()"
            class="btn btn-dark rounded-pill mb-2"
            [disabled]="customerForm.invalid"
          >
            {{ "general.proceed" | translate }}
          </button>

          <button
            type="button"
            class="btn btn-dark rounded-pill"
            (click)="moveStep.emit(13)"
          >
            <i class="fal fa-chevron-left me-2"></i>Back
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
