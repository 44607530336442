import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from 'src/app/Core/services/customer-checkout/checkout.service';
import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { AuthenticationService } from 'src/app/Core/services/signin/auth.service';
import { LoginOtpService } from 'src/app/Core/services/signin/login-otp.service';
import { SignupService } from 'src/app/Core/services/signup/signup.service';
import { TextDirectionController } from 'src/app/utilities/textDirectionController';
import { ValidateMobileNumber } from 'src/app/utilities/validate-number';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss'],
})
export class LoginSignupComponent implements OnInit {
  @ViewChild('captchaElem', { static: false })
  captchaElem: InvisibleReCaptchaComponent;

  @Output() moveStep = new EventEmitter<number>();
  @Output() loggedIn = new EventEmitter<any>();
  constructor(
    private loginOtpService: LoginOtpService,
    private signupService: SignupService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dataService: DataServiceService,
    private checkoutService: CheckoutService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  numberForm: FormGroup;
  error: string;
  OrderId: any;
  fullOrderDetails: any;
  paymentCancelRes: any;

  ngOnInit(): void {
    this.OrderId = this.route.snapshot.paramMap.get('id');
    this.captcha();
    this.numberForm = new FormGroup({
      number: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      recaptcha: new FormControl(''),
    });

    this.translate.get('toastr.error').subscribe((text) => (this.error = text));
    this.numberForm.valueChanges.subscribe(() => (this.isInvalid = false));

    if (
      sessionStorage.getItem('checkoutSignupEmit') != null &&
      sessionStorage.getItem('orderTokenForCheckout') != null &&
      sessionStorage.getItem('regis_number') != null
    ) {
      sessionStorage.removeItem('checkoutSignupEmit');
      //  sessionStorage.removeItem('orderTokenForCheckout');
      sessionStorage.removeItem('regis_number');
    }

    let orderToken = this.router.url.slice(
      this.router.url.lastIndexOf('/') + 1
    );
    this.getCalcPayment(orderToken);

    this.checkoutService
      .getOrderDetails(
        this.router.url.slice(this.router.url.lastIndexOf('/') + 1)
      )
      .subscribe((res) => (this.fullOrderDetails = res));
    this.getTodaysDate();

    this.numberForm.get('number').valueChanges.subscribe((res) => {
      if (this.f.number.valid) {
        this.execute();
      }
    });
  }

  get f() {
    return this.numberForm.controls;
  }

  todaysDate: any;
  getTodaysDate() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = parseInt('0' + dd);
    }
    if (mm < 10) {
      mm = parseInt('0' + mm);
    }
    this.todaysDate =
      (dd < 9 ? 0 + '' + dd : dd) +
      '/' +
      (mm < 9 ? 0 + '' + mm : mm) +
      '/' +
      yyyy;
  }

  clearmerchantLogin() {
    sessionStorage.removeItem('JWT_TOKEN');
    sessionStorage.removeItem('REFRESH_TOKEN');
    sessionStorage.removeItem('step');
    sessionStorage.removeItem('login');
  }

  directionController = new TextDirectionController();
  siteKey: string;
  captchaLang: string;
  captcha() {
    this.siteKey = environment.siteKey;
    this.captchaLang = this.directionController.isLTR() ? 'en' : 'ar';
  }

  captchaResponse: any;
  countryCode: any;

  isInvalid: boolean = false;
  validateMobileNumber = new ValidateMobileNumber();
  mobileNumber: string;
  onSubmit() {
    if (
      !this.validateMobileNumber.isNumberValidatedWithCountry(
        this.numberForm.get('number').value
      )
    ) {
      this.isInvalid = true;
      this.toastr.error(
        'Invalid ' + sessionStorage.getItem('country') + ' number',
        this.error
      );
      return;
    }

    this.mobileNumber = this.numberForm.get('number').value;
    this.login(this.countryCode + this.mobileNumber, this.captchaResponse);
  }

  login(username, recaptchaResponse) {
    // this.loginOtpService.postNumber({ username: username, recaptchaResponse: recaptchaResponse }).subscribe(res => {  }, err => { })
    const login: any = {
      countryCode: this.countryCode,
      mobileNumber: this.mobileNumber,
      languageCode: 'en',
      recaptchaResponse: this.captchaResponse,
    };
    this.loginOtpService.signinInit(login).subscribe(
      (res) => {
        this.dataService.checkoutActionLoginEmit(true);
        this.loginOtpService.storeLoginResp(res.requestId, res.otpReference);
        this.showScreen(2);
      },
      (err) => {
        this.dataService.checkoutActionLoginEmit(false);
        this.signup();
      }
    );
  }

  signup() {
    let signupModel: any = {
      countryCode: this.countryCode,
      mobileNumber: this.numberForm.get('number').value,
      recaptchaResponse: this.captchaResponse,
      languageCode: 'en',
    };
    this.signupService.signupInit(signupModel).subscribe((resp) => {
      this.loginOtpService.storeLoginResp(resp.requestId, resp.otpReference);
      this.showScreen(2);
    });
  }

  showLoginScreen = true;
  showOtpScreen = false;
  showNationalIDScreen = false;
  showCustomerInfoScreen = false;
  showMobileIdScree = false;
  showAddCardScreen = false;

  showScreen(steps: number) {
    this.showLoginScreen = false;
    this.showOtpScreen = false;
    this.showNationalIDScreen = false;
    this.showCustomerInfoScreen = false;
    this.showMobileIdScree = false;
    this.showAddCardScreen = false;
    switch (steps) {
      case 1: {
        this.showLoginScreen = true;
        break;
      }
      case 2: {
        this.showOtpScreen = true;
        break;
      }
      case 10: {
        this.showNationalIDScreen = true;
        break;
      }
      case 11: {
        this.showMobileIdScree = true;
        break;
      }
      case 12: {
        this.showCustomerInfoScreen = true;
        break;
      }
      case 13: {
        this.showAddCardScreen = true;
        break;
      }
    }
  }

  installments: any[];
  getCalcPayment(orderToken) {
    this.checkoutService.getCalcPayment(orderToken).subscribe((res) => {
      this.installments = res;
    });
  }
  redirectToPlugin() {
    this.checkoutService
      .getOrderDetails(
        this.router.url.slice(this.router.url.lastIndexOf('/') + 1)
      )
      .subscribe((res) => {
        this.fullOrderDetails = res;
        //  console.log(this.fullOrderDetails.redirectUrl);
        window.location.href = this.fullOrderDetails.redirectUrl;
      });
  }
  cancelPayment() {
    this.signupService.paymentCancel(this.OrderId).subscribe((res) => {
      this.paymentCancelRes = res;
      window.location.href = this.paymentCancelRes.redirectUrl;
      console.log(this.paymentCancelRes);
    });
  }

  execute(): void {
    this.captchaElem.execute();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
    console.log(captchaResponse);
  }
}
