export class ValidateMobileNumber {
  isNumberValidatedWithCountry(number: string): boolean {
    let country: string = sessionStorage.getItem('country');
    switch (country.toLocaleLowerCase()) {
      case 'bahrain':
        switch (number.charAt(0)) {
          case '3':
            return true;
          case '6':
            return true;
          default:
            return true;
        }
      case 'kuwait':
        switch (number.charAt(0)) {
          case '9':
            return true;
          case '6':
            return true;
          case '5':
            return true;
          default:
            return true;
        }
    }
  }
}
