<div class="modal-header">
  <h4 class="modal-title pull-left">Session timeout</h4>
  <button type="button" class="btn-close close pull-right" (click)="closeMe()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body" style="padding: 3rem 1rem">
  Your session has expired. please
  <a (click)="closeMe()" routerLink="/consumer/login">Log in</a>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="closeMe()">
    Cancel
  </button>
</div>
