<div
  class="d-flex justify-content-center align-items-center"
  style="height: 35rem !important"
>
  <div class="d-flex flex-column align-items-center">
    <div
      class="tick-oval bg-danger d-flex justify-content-center align-items-center mb-4"
    >
      <i class="fal fa-exclamation-circle text-white fa-3x"></i>
    </div>

    <h4>Your payment is cancelled.</h4>
    <h6 class="text-muted text-center">Your payment is cancelled.</h6>

    <!-- <button class="btn btn-primary w-100 mt-5" (click)="onRedirect()">
      REDIRECT TO MERCHANT WEBSITE
    </button> -->
  </div>
</div>
