import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  navtabsClass:string[]=[];

  constructor() { }

  ngOnInit(): void {
    this.navtabsClass=["navTabActive",""]
  }


  onNavTab(num:number){
    switch (num) {
      case 1:
        this.navtabsClass=["navTabActive",""]
        break;

      case 2:
        this.navtabsClass=["","navTabActive"]
        break;
    
      default:
        break;
    }
  }
}
