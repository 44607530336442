import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from 'src/app/Core/services/customer-checkout/checkout.service';
import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { ProfileService } from 'src/app/Core/services/profile/profile.service';
import { CreditCardValidators, CreditCard } from 'angular-cc-library';
import { TokenStorageService } from 'src/app/Core/interceptors/token-storage.service';
import { log } from 'console';

@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss'],
})
export class PaymentPlanComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private checkoutService: CheckoutService,
    private dataService: DataServiceService,
    private router: Router,
    private tokenStorageService: TokenStorageService
  ) {}
  editCardForm: FormGroup;
  addCardForm: FormGroup;
  defaultCard: any;

  link: any = `${location.origin}/terms-and-conditions`;

  successtext: string;
  success: string;
  error: string;

  orderToken: string;
  fullOrderDetails: any;
  type: any;
  isCardPosted: boolean = false;
  isCardVerified: boolean = false;
  cardVerifiedMsg: string;
  isButtonDisable:boolean = false
  ngOnInit(): void {
    this.editCardForm = new FormGroup({
      expiry: new FormControl(null, [
        Validators.required,
        this.validateExpiry,
        CreditCardValidators.validateExpDate,
      ]),
      cvv: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ]),
    });

    this.addCardForm = new FormGroup({
      holder: new FormControl(null, [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      number: new FormControl(null, [
        Validators.required,
        CreditCardValidators.validateCCNumber,
      ]),
      expiry: new FormControl(null, [
        Validators.required,
        this.validateExpiry,
        CreditCardValidators.validateExpDate,
      ]),
      cvv: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ]),
      defaultCard: new FormControl(true),
    });

    this.getCards();

    this.translate
      .get('toastr.billingpage.success2')
      .subscribe((text) => (this.successtext = text));
    this.translate
      .get('toastr.success')
      .subscribe((text) => (this.success = text));
    this.translate.get('toastr.error').subscribe((text) => (this.error = text));

    this.onValueChanges();

    this.dataService.getOrderToken().subscribe((res) => {
      if (res.length > 0) {
        this.orderToken = res;
        this.getCalcPayment(res);
      } else {
        let orderToken = this.router.url.slice(
          this.router.url.lastIndexOf('/') + 1
        );
        this.getCalcPayment(orderToken);
      }
    });
    this.createForm();

    this.checkoutService
      .getOrderDetails(
        this.router.url.slice(this.router.url.lastIndexOf('/') + 1)
      )
      .subscribe((res) => (this.fullOrderDetails = res));

    this.getTodaysDate();

    this.addCardForm.get('number').valueChanges.subscribe((num) => {
      if (num) {
        this.type = CreditCard.cardType(num);
      }
    });
  }

  todaysDate: any;
  getTodaysDate() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = parseInt('0' + dd);
    }
    if (mm < 10) {
      mm = parseInt('0' + mm);
    }
    this.todaysDate =
      (dd < 9 ? 0 + '' + dd : dd) +
      '/' +
      (mm < 9 ? 0 + '' + mm : mm) +
      '/' +
      yyyy;
  }

  validateExpiry(control: FormControl): ValidationErrors | null {
    if (control.value != null) {
      let expiryDate: string[] = control.value.split('/');
      let expiryMonth = parseInt(expiryDate[0].trim());

      if (expiryDate[1] != null && expiryDate[1].length > 2) {
        let expiryYear = parseInt('20' + expiryDate[1].trim());
        let date = new Date();

        if (expiryYear > date.getFullYear()) {
          return null;
        } else if (expiryYear === date.getFullYear()) {
          if (expiryMonth > date.getMonth() + 1 && expiryMonth < 12) {
            return null;
          }
        }
        return { expiryNotValid: true };
      }
    }
    return null;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  isEditCvvInvalid: boolean = false;
  isCvvInvalid: boolean = false;
  isClickOutSide: boolean = false;
  onClickOutside(form, inputName) {
    if (form == 'edit')
      this.editCardForm.get('cvv').hasError('minlength')
        ? (this.isEditCvvInvalid = true)
        : (this.isEditCvvInvalid = false);
    else {
      switch (inputName) {
        case 'cNum':
          this.isClickOutSide = true;
          break;
        case 'cvv':
          this.addCardForm.get('cvv').hasError('minlength')
            ? (this.isCvvInvalid = true)
            : (this.isCvvInvalid = false);
          break;
      }
    }
  }

  onValueChanges() {
    this.addCardForm
      .get('number')
      .valueChanges.subscribe(() => (this.isClickOutSide = false));
    this.editCardForm
      .get('cvv')
      .valueChanges.subscribe(() => (this.isCvvInvalid = false));
  }

  cards: any[];
  defaultCardIndex: number = 0;
  getCards() {
    this.profileService.getCardDetails().subscribe((res) => {
      this.cards = res;
      this.defaultCard = this.cards.filter(
        (elem) => elem.defaultCard == true
      )[0];
      this.getDefaultCardIndex();
    });
  }

  setValuesToEditForm() {
    let expMonth =
      this.defaultCard.expiryMonth.toString().length > 1
        ? this.defaultCard.expiryMonth
        : '0' + this.defaultCard.expiryMonth;
    let expDate =
      expMonth + '/' + this.defaultCard.expiryYear.toString().slice(2, 4);

    this.editCardForm.get('expiry').setValue(expDate);
    this.editCardForm.get('cvv').setValue(this.defaultCard.cvv);
  }

  @ViewChild('editCloseModel') editCloseModel: ElementRef;
  @ViewChild('listCloseModel') listCloseModel: ElementRef;
  @ViewChild('addCloseModel') addCloseModel: ElementRef;
  onEditSave() {
    let expiryDate = this.editCardForm.get('expiry').value.split('/');
    let expiryMonth = parseInt(expiryDate[0].trim());
    let expiryYear = parseInt('20' + expiryDate[1].trim());
    const cardData: any = {
      cvv: this.editCardForm.get('cvv').value,
      expiryMonth: expiryMonth,
      expiryYear: expiryYear,
    };
    this.profileService
      .editCardDetails(this.defaultCard.id, cardData)
      .subscribe(
        (res) => {
          this.toastr.success(this.successtext, this.success);
          this.getCards();
          this.editCloseModel.nativeElement.click();
        },

        (err) => this.toastr.error(err.error.message, this.error)
      );
  }

  backToCard() {
    this.isCardPosted = false;
    this.isCardVerified = false;
    this.cardVerifiedMsg = undefined;
    this.addCardForm.reset();
  }

  onAdd() {
    if (this.addCardForm.invalid) {
      return;
    }
    let expiryDate = this.addCardForm.get('expiry').value.split('/');
    let expiryMonth = parseInt(expiryDate[0].trim());
    let expiryYear = parseInt('20' + expiryDate[1].trim());
    const cardData: any = {
      holderName: this.addCardForm.get('holder').value,
      creditCardNumber: this.addCardForm.get('number').value.replace(/\s/g, ''),
      cvv: this.addCardForm.get('cvv').value,
      expiryMonth: expiryMonth,
      expiryYear: expiryYear,
      defaultCard: this.addCardForm.get('defaultCard').value,
      key: this.tokenStorageService.getToken(),
      TYPE: 'ADD_NEW_CARD',
      FROM: 'SECURE_CHECKOUT_PAGE',
      orderId: sessionStorage.getItem('orderTokenForCheckout'),
    };

    this.isCardPosted = true;

    this.profileService.postCardDetails(cardData).subscribe(
      (res) => {
        if (res.response?.code == '000' && res.transaction?.url == null) {
          this.isCardVerified = true;
          this.cardVerifiedMsg = res.message;

          setTimeout(() => {
            this.getCards();
            this.addCloseModel.nativeElement.click();
          }, 300);
          this.toastr.success(this.cardVerifiedMsg, this.success);
        } else if (
          res?.response?.code == '100' &&
          res?.transaction?.url != null
        ) {
          window.location = res.transaction.url;
          //window.open(res.transaction.url, '_self');
        } else {
          // this.toastr.error(res.response.message, this.error);
          this.isCardVerified = true;

          this.cardVerifiedMsg = res.message;
          // this.toastr.success(this.cardVerifiedMsg, this.success);
        }
      },
      (err) => {
        this.isCardVerified = false;
        this.cardVerifiedMsg = err.error.message;
        //  this.toastr.error(err.error.message, this.error);
      }

      // (res) => {
      //   this.toastr.success(this.successtext, this.success);
      //   this.getCards();
      //   this.addCloseModel.nativeElement.click();
      //   this.addCardForm.reset();
      // },
      // (err) => this.toastr.error(err.error.message, this.error)
    );
  }

  setCardDefaultId: number;
  isExpired: boolean = false;
  onSelectCard(i: number) {
    this.defaultCardIndex = i;
    this.setCardDefaultId = this.cards[i].id;
    this.isExpired = this.cards[i].status == 'EXPIRED' ? true : false;
  }

  setCardAsDefault() {
    this.profileService
      .setDefaultCard(this.setCardDefaultId, {})
      .subscribe((res) => {
        this.listCloseModel.nativeElement.click();
        this.getCards();
        this.toastr.success('Card got selected', 'Success!');
      });
  }

  getDefaultCardIndex() {
    for (
      this.defaultCardIndex = 0;
      this.defaultCardIndex < this.cards.length;
      this.defaultCardIndex++
    ) {
      if (this.cards[this.defaultCardIndex].defaultCard == true) {
        break;
      }
    }
  }

  installments: any[];
  getCalcPayment(orderToken) {
    this.checkoutService.getCalcPayment(orderToken).subscribe((res) => {
      if (res.length > 2) {
        this.paymentForm.get('plan').setValue('THREE_MONTHS');
        this.onPay30 = false;
      } else {
        this.paymentForm.get('plan').setValue('ONE_MONTH');
        this.onPay30 = true;
      }
      this.installments = res;
    });
  }

  paymentForm: FormGroup;
  createForm() {
    this.paymentForm = new FormGroup({
      plan: new FormControl(null, [Validators.required]),
      tnc: new FormControl(null, [
        Validators.required,
        Validators.requiredTrue,
      ]),
      paymentMethod: new FormControl(null),
    });
  }

  onPay30: boolean;
  @Output() successPayment = new EventEmitter<string>();
  isProcessPayment: boolean = false;
  onProcessPayment() {
    this.isProcessPayment = true;
    this.isButtonDisable = true
    const order = {
      orderToken: this.router.url.slice(this.router.url.lastIndexOf('/') + 1),
      paymentPlanId: this.paymentForm.get('plan').value,
      creditCardId: this.defaultCard?.id,
      paymentMethod: 'CREDIT',
      paymentType: 'DOWNPAYMENT',
      key: this.tokenStorageService.getToken(),
    };
    this.checkoutService.processOrderPayment(order).subscribe(
      (res) => {
        if (res.orderStatus == 'CONFIRMED' && res.paymentStatus == 'CAPTURED') {
          this.successPayment.emit('true');
          this.isProcessPayment = false;
          this.isButtonDisable = false
          sessionStorage.setItem('redUrl', res.redirectUrl);
        }
        // else if (!res.paymentFailed) this.successPayment.emit('false')
        else {
          this.successPayment.emit(null);
          this.toastr.error('Payment is not successful!', this.error);
          this.isProcessPayment = false;
          this.isButtonDisable = false
        }
      },
      (err) => {
        this.isProcessPayment = false;
        this.isButtonDisable = false
        this.successPayment.emit(null);
        const errMsg =
          'Something went wrong, please review and update your card details or choose a different method of payment.';
        this.toastr.error(err.error.message, this.error)
      }
    );
  }

  payByKnet() {
    this.isProcessPayment = true;
    this.isButtonDisable = true
    const installment = {
      orderToken: this.router.url.slice(this.router.url.lastIndexOf('/') + 1),
      paymentPlanId: this.paymentForm.get('plan').value,
      paymentMethod: 'DEBIT',
      paymentType: 'DOWNPAYMENT',
      key: this.tokenStorageService.getToken(),
    };

    this.checkoutService.payInstallmentOnKnet(installment).subscribe(
      (res) => {
        if (res.transaction?.url !== null) {
          window.location = res.transaction.url;
        } else {
          this.successPayment.emit(null);
          this.toastr.error('Payment is not successful!', this.error);
          this.isProcessPayment = false;
          this.isButtonDisable = false
        }
      },
      (err) => {
        this.isProcessPayment = false;
        this.isButtonDisable = false;
        this.successPayment.emit(null);
        this.toastr.error(err.error.message, this.error);
      }
    );
  }

  navtabsClass: string[] = [];

  onOpenHowModel() {
    this.navtabsClass = ['navTabActive', ''];
  }

  onNavTab(num: number) {
    switch (num) {
      case 1:
        this.navtabsClass = ['navTabActive', ''];
        break;

      case 2:
        this.navtabsClass = ['', 'navTabActive'];
        break;

      default:
        break;
    }
  }

  onPlanChange(isPay30: boolean) {
    this.onPay30 = isPay30;
    this.paymentForm.get('paymentMethod').setValue(null);

    if (isPay30) {
      this.paymentForm.get('paymentMethod').clearValidators();

      var orderPlan: any = {
        paymentPlanId: 'ONE_MONTH',
        amount: this.fullOrderDetails.amount,
        currency: this.fullOrderDetails.currency,
        merchantId: this.fullOrderDetails.merchantId,
      };
    } else {
      // this.paymentForm.get('paymentMethod').setValidators(Validators.required);
      var orderPlan: any = {
        paymentPlanId: 'THREE_MONTHS',
        amount: this.fullOrderDetails.amount,
        currency: this.fullOrderDetails.currency,
        merchantId: this.fullOrderDetails.merchantId,
      };
    }

    this.checkoutService.getExpectedOrderDetails(orderPlan).subscribe((res) => {
      setTimeout(() => {
        if (isPay30) {
          this.paymentForm.get('paymentMethod').clearValidators();
        } else {
          this.paymentForm
            .get('paymentMethod')
            .setValidators(Validators.required);
        }
      }, 10);
      this.installments = res;
    });
  }

  setPaymentType(val: string) {
    this.paymentForm.get('paymentMethod').setValue(val);
  }

  payNow() {
    this.isButtonDisable = true
    if (this.paymentForm.valid) {
      const type = this.paymentForm.get('paymentMethod').value;

      if (type == 'knet') {
        this.payByKnet();
      } else if (
        type == 'credit' ||
        this.paymentForm.get('plan').value == 'ONE_MONTH'
      ) {
        this.onProcessPayment();
      } else {
        this.toastr.error('Please select your payment method.', this.error);
      }
    } else {
      this.toastr.error('Please select your payment method.', this.error);
    }
  }

  openNewTab() {
    window.open(`${location.origin}/terms-and-conditions`, '_blank');
  }
}
