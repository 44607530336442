import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthProfile } from 'src/app/Core/models/auth.model';
import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { AuthenticationService } from 'src/app/Core/services/signin/auth.service';
import { TextDirectionController } from 'src/app/utilities/textDirectionController';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss'],
})
export class HeaderHomeComponent implements OnInit {
  @Input() isHomePage: boolean;
  directionController = new TextDirectionController();
  displayMenu: boolean = false;

  user$: Observable<AuthProfile>;
  browserLang;
  someSubscription: any;
  currentUrl: string;
  isLoginPage: boolean = false;
  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private dataService: DataServiceService
  ) { }
  isMerchant: boolean;
  ngOnInit(): void {


    this.someSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.url
        if (this.currentUrl === '/consumer/login') {
          this.isLoginPage = true
        } else {
          this.isLoginPage = false
        }

        this.router.navigated = false;
      }
    });
    this.dataService.personalInfoGotUpdate(false);
    this.getUserData();
    this.dataService
      .isPersonalInfoGotUpdate()
      .subscribe((res) => (res ? this.getUserData() : ''));

    this.router.events.subscribe((event: Event) => {
      this.displayMenu = false;
    });
  }

  myFunction() {
    this.displayMenu = !this.displayMenu;
  }

  hideMenu() {
    this.displayMenu = false;
  }

  getUserData() {
    this.user$ = this.authenticationService.getAuthProfile();
  }

  @ViewChild('colNav') navbarCollapseElem: ElementRef;
  @ViewChild('toggleBtn') toggleBtnELem: ElementRef;
  onClickOutside() {
    if (this.navbarCollapseElem.nativeElement.classList.contains('show')) {
      this.toggleBtnELem.nativeElement.click();
    }
  }

  logout() {
    this.authenticationService.logout().subscribe((a) => {
      var lang = sessionStorage.getItem('lang');
      var country = sessionStorage.getItem('country');

      sessionStorage.clear();
      sessionStorage.setItem('lang', lang);
      sessionStorage.setItem('country', country);
      this.router.navigate(['/consumer/login']);
    });

    this.displayMenu = false;
  }

  onAuth(mode) {
    if (mode == 'login') {
      this.dataService.headerClickLogin(true);
      if (sessionStorage.getItem('step') != null) {
        sessionStorage.removeItem('step');
        window.location.reload();
      }
    } else this.dataService.headerClickLogin(false);

    this.displayMenu = false;
  }
  ngOnDestroy() {
    if (this.someSubscription) {
      this.someSubscription.unsubscribe();
    }
  }
}
