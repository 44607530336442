import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VersionCheckComponent } from './version-check/version-check.component';
import { TalyOverviewComponent } from './Core/components/taly-overview/taly-overview.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: 'version', component: VersionCheckComponent },
  { path: 'how-it-works', component: TalyOverviewComponent },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  // {
  //   path: 'docs',
  //   loadChildren: () =>
  //     import('./developer-docs/developer-docs.module').then(
  //       (m) => m.DeveloperDocsModule
  //     ),
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
