import { LayoutModule } from './layout/layout.module';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { PreloaderComponent } from './preloader/preloader.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from './utilities/ngb-date-parse-formatter';
import { TokenInterceptor } from './Core/interceptors/token.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { LangTranslateModule } from './lang-translate.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InternationalizationModule } from './internationalization/internationalization.module';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { SentryErrorHandler } from './Core/services/error.service';
import { EnvServiceProvider } from './env.service.provider';
import { UserIdleComponent } from './user-idle/user-idle.component';
import { ModalModule } from 'ngx-bootstrap/modal';
// import { NgxHttpLoaderModule } from 'ngx-http-loader';

import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { ProfileService } from 'src/app/Core/services/profile/profile.service';
import { SignupService } from 'src/app/Core/services/signup/signup.service';
import { DatePipe } from '@angular/common';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';

import { ClipboardModule } from 'ngx-clipboard';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from 'ngx-ui-loader';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SidebarComponent } from './developer-docs/sidebar/sidebar.component';
import { VersionCheckComponent } from './version-check/version-check.component';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsType: 'circle',
  fgsColor: '#fff',
  overlayColor: 'rgba(40, 40, 40, 0.5)',
  text: 'Loading',
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    UserIdleComponent,
    SidebarComponent,
    VersionCheckComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ClipboardModule,
    AccordionModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 6000,
      preventDuplicates: true,
    }),
    LayoutModule,
    PagesModule,
    NgxUiLoaderRouterModule,
    HttpClientModule,
    InternationalizationModule.forRoot({ locale_id: 'en-US' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LangTranslateModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    ModalModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgIdleKeepaliveModule.forRoot(),
    LoadingBarModule,
  ],

  providers: [
    DataServiceService,
    ProfileService,
    DatePipe,
    SignupService,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    EnvServiceProvider,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'KWD ' },
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    {
      provide: LOADING_BAR_CONFIG,
      useValue: { latencyThreshold: 100, color: '#DD0031' },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
