import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { EnvService } from 'src/app/env.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MerchantLoginService {
  private isAuthenticatedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(this.hasToken());

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

  constructor(private http: HttpClient, private environment: EnvService) {}

  //when user login
  login(email: string, password: string, rememberMe: boolean = false) {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic bWVyY2hhbnQ6c2VjcmV0',
      requestFor: 'merchant',
    });

    const body = new URLSearchParams();
    body.set('username', email);
    body.set('password', password);
    body.set('grant_type', 'password');
    body.set('scope', 'ui');

    return this.http
      .post<any>(
        `${this.environment.ApiUrl}/uaa/oauth/token`,
        body.toString(),
        { headers: header }
      )
      .pipe(
        tap((token) => {
          if (token != null) {
            const currUserToken = token;
            this.storeTokens(token);
            this.isAuthenticatedSubject.next(true);

            // sessionStorage.setItem('username', token.username);
            sessionStorage.setItem('rememberme', JSON.stringify(rememberMe));

            return currUserToken;
          } else {
            this.isAuthenticatedSubject.next(false);
            throw new Error('401');
          }
        }),
        mapTo(true),
        catchError((error) => {
          this.refreshToken();
          return of(false);
        })
      );
  }

  getAuthProfile() {
    return this.http
      .get<any>(`${this.environment.ApiUrl}/merchants/merchantUserProfile/1`)
      .pipe(
        map((profile) => {
          sessionStorage.removeItem('authProfile');

          sessionStorage.setItem('authProfile', JSON.stringify(profile));
          // this.currentUserProfileSubject.next(profile);
          return profile;
        })
      );
  }

  private hasToken(): boolean {
    return !!this.getJWTToken();
  }

  public getJWTToken() {
    return sessionStorage.getItem(this.JWT_TOKEN);
  }

  isLoggedIn() {
    return !!this.getJWTToken();
  }

  public getJWTRefreshToken() {
    return sessionStorage.getItem(this.REFRESH_TOKEN);
  }

  private getRefreshToken() {
    return sessionStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    sessionStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: any) {
    sessionStorage.setItem(this.JWT_TOKEN, tokens.access_token);
    sessionStorage.setItem(this.REFRESH_TOKEN, tokens.refresh_token);
    sessionStorage.setItem('login', 'merchant');
  }

  private validateToken() {
    return this.http
      .get(
        `${
          this.environment.ApiUrl
        }/uaa/oauth/check_token?token=${this.getJWTToken()}`
      )
      .subscribe(
        () => {
          return true;
        },
        (error) => {
          return false;
        }
      );
  }
  refreshToken() {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic bWVyY2hhbnQ6c2VjcmV0',
      refreshtoken: 'true',
    });

    const body = new URLSearchParams();

    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', this.getRefreshToken());
    if (this.validateToken()) {
      return this.http
        .post<any>(
          `${this.environment.ApiUrl}/uaa/oauth/token`,
          body.toString(),
          {
            headers: header,
          }
        )
        .pipe(
          tap((tokens: any) => {
            this.storeTokens(tokens);
          })
        );
    } else {
      this.logout();
    }
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    if (this.validateToken()) {
      return this.http
        .get(`${this.environment.ApiUrl}/uaa/merchant/logout`)
        .pipe(
          map(
            () => {
              this.isAuthenticatedSubject.next(false);
              this.localClear();
            },
            (err) => {}
          )
        );
    } else {
      this.isAuthenticatedSubject.next(false);
      this.localClear();
    }
  }

  localClear() {
    var lang = sessionStorage.getItem('lang');
    sessionStorage.clear();
    sessionStorage.setItem('lang', lang);
  }
}
