import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[LoginOtp]'
})
export class LoginOtpDirective {

  constructor(private otpElem: ElementRef, private renderer: Renderer2) { 
    setTimeout(() => {
      let otpContainer = otpElem.nativeElement.childNodes[0];
      let firstInput=otpContainer.firstElementChild;
      this.renderer.appendChild(otpContainer.childNodes[2], firstInput)
      let lastInput= otpContainer.firstElementChild.lastChild;
      this.renderer.addClass(lastInput, 'me-2')

    }, 100);
  }

}
