import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-taly-overview',
  templateUrl: './taly-overview.component.html',
  styleUrls: ['./taly-overview.component.scss'],
})
export class TalyOverviewComponent implements OnInit {
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    // Add a CSS class to the body tag
    this.renderer.addClass(document.body, 'custom-body');
  }

  ngOnDestroy() {
    // Remove the added CSS class from the body tag
    this.renderer.removeClass(document.body, 'custom-body');
  }
}
