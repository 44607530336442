<div class="">
  <div class="header__Style" id="stickyHeader">
    <div class="header__left--style">
      <a class="logo__style" routerLink="/home"><img class="logo_image_styles"
          src="../../assets/images-new/Logo.png" /></a>
    </div>
    <div class="toggledSection desktop_view" *ngIf="!this.authenticationService.isLoggedIn()">
      <div id="myDIV" class="infoLinks__style">
        <!-- <a routerLink="/about" class="links__style">  ABOUT</a> -->
        <a routerLink="/how" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLinkActive="active"
          href="javascript:void(0)" id="forBusiness" class="links__style">{{"general.headerLink1"| translate}}</a>
        <a routerLink="/forbusiness" class="ms-auto fw-bold text-primary nav-link text-lg-center text-start"
          data-bs-toggle="collapse" routerLinkActive="active" data-bs-target=".navbar-collapse.show"
          class="links__style">{{"general.headerLink2"| translate}}</a>
        <a routerLink="/faq" class="links__style">{{"general.headerLink3"| translate}}</a>
        <!-- <app-language-select></app-language-select> -->
        <!-- <a href="https://boards.eu.greenhouse.io/taly" target="_blank" class="links__style">JOB</a> -->
      </div>
      <div class="header__right--style" *ngIf="!isLoginPage">
        <a style="color: #fff" routerLink="/consumer/login" (click)="onAuth('login')" data-bs-toggle="collapse"
          routerLinkActive="active" data-bs-target=".navbar-collapse.show" [ngClass]="
            isHomePage ? 'Loginlinks__style' : 'Loginlinks__style_else_homePage'
          ">{{ "general.login" | translate }}</a>
        <!-- <a
          style="color: #fff"
          role="button"
          routerLink="/consumer/login"
          (click)="onAuth('signup')"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse.show "
          [ngClass]="
            isHomePage ? 'Loginlinks__style' : 'Loginlinks__style_else_homePage'
          "
          >{{ "general.signup" | translate }}</a
        > -->
      </div>
    </div>

    <div class="toggledSection mobile_view" *ngIf="!this.authenticationService.isLoggedIn()">
      <div id="myDIV" *ngIf="displayMenu" class="infoLinks__style__mobile">
        <!-- <a routerLink="/about" (click)="hideMenu()" class="links__style">  ABOUT</a> -->
        <a routerLink="/how" (click)="hideMenu()" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
          routerLinkActive="active" href="javascript:void(0)" id="forBusiness" class="links__style">{{"general.headerLink1"| translate}}</a>
        <a routerLink="/forbusiness" (click)="hideMenu()"
          class="ms-auto fw-bold text-primary nav-link text-lg-center text-start" data-bs-toggle="collapse"
          routerLinkActive="active" data-bs-target=".navbar-collapse.show" class="links__style">{{"general.headerLink2"| translate}}</a>
        <a routerLink="/faq" class="links__style" (click)="hideMenu()">{{"general.headerLink3"| translate}}</a>

        <!-- <app-language-select></app-language-select> -->

        <a routerLink="/consumer/login" (click)="onAuth('login')" data-bs-toggle="collapse" routerLinkActive="active"
          data-bs-target=".navbar-collapse.show" class="links__style">
          {{ "general.login" | translate }}
        </a>
        <!-- <a
          role="button"
          routerLink="/consumer/login"
          (click)="onAuth('signup')"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse.show "
          class="links__style"
          >{{ "general.signup" | translate }}</a
        > -->
      </div>
    </div>

    <!-- 'Desktop View/' -->
    <!-- navbar after customer login  -->
    <div class="toggledSection desktop_view" *ngIf="this.authenticationService.isLoggedIn()">
      <div id="myDIV" class="infoLinks__style">
        <a routerLink="/consumer/dashboard" class="links__style">{{
          "general.payment" | translate
          }}</a>

        <!-- <a
          routerLink="/consumer/orderhistory"
          data-bs-toggle="collapse"
          data-bs-target=""
          routerLinkActive="active"
          href="javascript:void(0)"
          id="forBusiness"
          class="links__style"
          >Orders</a
        > -->

        <a class="links__style profile_link_alignment dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fad fa-user-circle text-primary me-2 ms-0 fa-2x"></i>
          <span class="">{{ (user$ | async)?.firstName }}</span>
        </a>

        <ul class="dropdown-menu w-100 usermenu dropdown-menu-start" aria-labelledby="navbarDropdownMenuLink"
          style="top: 93.8% !important">
          <li routerLink="/consumer/profile">
            <a class="links__style dropdown-item text-dark" role="button" data-bs-toggle="collapse" data-bs-target=""><i
                class="bi bi-person"></i> {{"general.myprofile" | translate}}
            </a>
          </li>
          <li routerLink="/consumer/billing">
            <a o class="links__style dropdown-item text-dark" role="button" data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"><i class="bi bi-receipt"></i> {{"general.bankdetails" | translate}}
            </a>
          </li>
          <li routerLink="/consumer/help-support">
            <a class="links__style dropdown-item text-dark" role="button" data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"><i class="bi bi-info"></i> {{"general.helpsupport" | translate}}
            </a>
          </li>
          <li (click)="logout()">
            <a class="links__style dropdown-item text-dark" role="button" data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"><i class="bi bi-door-open"></i> {{"general.logout" | translate}}</a>
          </li>
        </ul>
        <li class="nav-link lang-select nav-item dropdown m-0">
          <!-- <app-language-select></app-language-select> -->
        </li>
      </div>
    </div>

    <!-- Mobile view -->
    <!-- navbar after customer login  -->
    <div class="toggledSection mobile_view" *ngIf="this.authenticationService.isLoggedIn()">
      <div id="myDIV" *ngIf="displayMenu" class="infoLinks__style__mobile">
        <!-- <a
          (click)="hideMenu()"
          routerLink="/consumer/dashboard"
          class="links__style"
          >Home</a
        > -->
        <a (click)="hideMenu()" routerLink="/consumer/dashboard" data-bs-toggle="collapse" data-bs-target=""
          routerLinkActive="active" href="javascript:void(0)" id="forBusiness" class="links__style">
          {{
          "general.payment" | translate
          }}
        </a>

        <a class="links__style profile_link_alignment dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fad fa-user-circle text-primary me-2 ms-0 fa-2x"></i>
          <span class="">{{ (user$ | async)?.firstName }}</span>
        </a>

        <ul class="dropdown-menu w-100 usermenu dropdown-menu-start" aria-labelledby="navbarDropdownMenuLink"
          style="top: 93.8% !important">
          <li routerLink="/consumer/profile" (click)="hideMenu()">
            <a class="links__style dropdown-item text-dark" role="button" data-bs-toggle="collapse" data-bs-target=""><i
                class="bi bi-person"></i> {{"general.myprofile" | translate}}
            </a>
          </li>
          <li routerLink="/consumer/billing" (click)="hideMenu()">
            <a class="links__style dropdown-item text-dark" role="button" data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"><i class="bi bi-receipt"></i> {{"general.bankdetails" | translate}}
            </a>
          </li>
          <li routerLink="/consumer/help-support">
            <a class="links__style dropdown-item text-dark" role="button" data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"><i class="bi bi-info"></i> {{"general.helpsupport" | translate}}
            </a>
          </li>
          <li (click)="logout()">
            <a class="links__style dropdown-item text-dark" role="button" data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"><i class="bi bi-door-open"></i> {{"general.logout" | translate}}</a>
          </li>
          <li>
            <!-- <app-language-select></app-language-select> -->
          </li>
        </ul>

      </div>
    </div>

    <button class="toggleBtn" (click)="myFunction()">
      <img src="../../assets/images-new/Toggle.svg" />
    </button>
  </div>
</div>