import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sort-table',
  templateUrl: './sort-table.component.html',
  styleUrls: ['./sort-table.component.scss']
})
export class SortTableComponent implements OnInit {

  constructor() { }

  @Input() key: string;
  @Input() objectArray: any[];

  @ViewChild('fistSort') fistSortButton: ElementRef;
  ngOnInit(): void {
    setTimeout(() => {
      this.fistSortButton.nativeElement.click();
    }, 100);
  }

  direction: boolean = true;
  onSort(key, objectArray) {
    this.direction ? this.direction = false : this.direction = true;
    objectArray.sort((a, b) => {
      return this.direction ? this.ascToDesc(a, b, key) : this.descToAsc(a, b, key);
    });
  }

  private ascToDesc(a, b, key) {
    let objA = a[key];
    let objB = b[key];
    if (typeof a[key] === "string" && typeof b[key] === "string") {
      objA = a[key].toUpperCase();
      objB = b[key].toUpperCase();
    }

    let comparison = 0;
    // smaller to bigger 

    if (objA > objB) {
      comparison = 1;
    } else if (objA < objB) {
      comparison = -1;
    }

    return comparison;
  }

  private descToAsc(a, b, key) {
    let objA = a[key];
    let objB = b[key];
    if (typeof a[key] === "string" && typeof b[key] === "string") {
      objA = a[key].toUpperCase();
      objB = b[key].toUpperCase();
    }


    let comparison = 0;
    if (objA < objB) {
      comparison = 1;
    } else if (objA > objB) {
      comparison = -1;
    }

    return comparison;
  }
}
