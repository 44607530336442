import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appSuccessMsg]',
})
export class SuccessMsgDirective {
  @Input() successText: any;
  constructor(private elem: ElementRef) {
    setTimeout(() => {
      this.elem.nativeElement.childNodes[0].childNodes[1].innerHTML =
        this.successText;
    }, 100);
  }
}
