import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-loading',
  templateUrl: './data-loading.component.html',
})
export class DataLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
