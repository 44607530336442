<div class="container ">
    <div class="card rounded-3 ">
        <div class="text-center text-primary">
            <img src="assets/images/logo/taly_logo.png" class="img-fluid mb-1" width="120" height="100" alt="taly" />
        </div>
        <div class="card-body">
            <div class="d-flex flex-column align-items-center justify-content-center">
                <div class="tick-oval d-flex justify-content-center align-items-center mb-4"
                    style="background-color: #6edd9d">
                    <i class="fal fa-check-circle text-white fa-3x"></i>
                </div>

                <h4 class="text-center  mb-4">
                    Your payment has been successfully paid.
                </h4>
                <h6 class="text-center  mb-4"> <strong>OrderId:{{orderId}}</strong>
                    
                </h6>
                <h6 class="text-center mb-4">
                    <strong>Paid on: {{currentDateTime}}</strong>
                </h6>
                <button class="btn btn-primary w-30 mb-4" data-bs-dismiss="offcanvas" #closeModal aria-label="Close"
                    (click)="login()">
                    {{ "general.login" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>