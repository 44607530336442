import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AuthenticationService } from 'src/app/Core/services/signin/auth.service';
import { StorageService } from '../Core/services/storage/storage.service';
@Component({
  selector: 'app-user-idle',
  templateUrl: './user-idle.component.html',
  styleUrls: ['./user-idle.component.scss'],
})
export class UserIdleComponent implements OnInit {
  timer?: string;
  displayMenu: boolean = false;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    public authenticationService: AuthenticationService,
    private router: Router,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {}

  closeMe() {
    // this.event.emit(true);
    this.bsModalRef.hide();
    this.localClear();
    this.router.navigate(['/home']);
    setTimeout(() => {
      this.router.navigate(['/consumer/login']);
    }, 100);
  }

  localClear(type?: any) {
    var lang = sessionStorage.getItem('lang');
    var country = sessionStorage.getItem('country');
    this.storageService.clearEverything();

    sessionStorage.setItem('lang', lang);
    sessionStorage.setItem('country', country);
  }
}
