import { Injectable } from '@angular/core';

const TOKEN_KEY = 'JWT_TOKEN';
const REFRESHTOKEN_KEY = 'REFRESH_TOKEN';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  public TOKEN_KEY: string;

  constructor() {}

  signOut(): void {
    sessionStorage.clear();
  }

  public saveToken(token: string): void {
    this.TOKEN_KEY = token;
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveRefreshToken(token: string): void {
    sessionStorage.removeItem(REFRESHTOKEN_KEY);
    sessionStorage.setItem(REFRESHTOKEN_KEY, token);
  }

  public getRefreshToken(): string | null {
    return sessionStorage.getItem(REFRESHTOKEN_KEY);
  }
}
