import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-showbtn',
  templateUrl: './password-showbtn.component.html',
  styleUrls: ['./password-showbtn.component.scss']
})
export class PasswordShowbtnComponent implements OnInit {

  eyeShow:boolean;
  constructor() { }

  ngOnInit(): void {
    this.eyeShow=false;
  }

  eyeBtnToggle(){
    if(!this.eyeShow) {
      this.eyeShow=true;
    }
    else{
      this.eyeShow=false;
    }
  }
}
