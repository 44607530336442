import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  constructor() { }
  @Input() totalPages: number;
  @ViewChild('totalpage') noOfpages: ElementRef;

  pages: number[];
  currentPage: number = 1;
  ngOnInit(): void {
   
  }

  ngOnChanges() {
    setTimeout(() => {
      let numberOfpages: number = this.noOfpages.nativeElement.innerHTML;
      this.pages = Array.from({ length: numberOfpages }, (_, i) => i + 1)

    }, 1000);
  }

  @Output() onPageChange = new EventEmitter<number>()
  onChangePage() {
    this.onPageChange.emit(this.currentPage - 1);
  }

  onPrev() {
    if (this.currentPage != 1) {
      this.currentPage = this.currentPage - 1;
      this.onChangePage();
    }

  }
  onNext() {
    if (typeof this.currentPage == 'string') this.currentPage = parseInt(this.currentPage);
    if (this.noOfpages.nativeElement.innerHTML > this.currentPage) {
      this.currentPage = this.currentPage + 1;
      this.onChangePage();
    }
  }
}
