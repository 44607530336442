<div class="text-primary">
  <div class="py-1 px-3 px-lg-4 d-flex flex-column">
    <h4 class="mt-4">{{ "customerLogin.natinalId" | translate }}</h4>

    <form
      autocomplete="off"
      [formGroup]="nationalIdForm"
      (ngSubmit)="onSubmit(template)"
    >
      <!-- natinalId  -->
      <div class="mb-3">
        <!-- <label for="mobileNumber" class="form-label">{{'customerLogin.natinalId' |translate}}</label> -->
        <div class="input-group mb-3">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            id="nationalId"
            [ngClass]="{ 'is-invalid': submitted && f.nationalId.errors }"
            formControlName="nationalId"
            OnlyNumber
            maxlength="12"
          />
        </div>
      </div>
      <div class="my-4 d-flex">
        <input
          style="margin-top: 2px"
          autocomplete="off"
          type="checkbox"
          id="agree"
          class="me-2 ms-0 form-check-input"
          formControlName="tnc"
          (change)="isCheckboxSelect()"
        />
        <h6>
          I agree to the
          <a href="{{ link }}" target="_blank"> terms and conditions </a>
        </h6>
      </div>

      <div class="d-grid mt-4">
        <button
          type="submit"
          class="btn btn-dark rounded-pill mb-2"
          [disabled]="
            nationalIdForm.invalid || nationalIdForm.value.tnc == false
          "
        >
          {{ isLoading ? "Loading..." : ("general.proceed" | translate) }}
          <!-- {{ "general.proceed" | translate }} -->
        </button>

        <!-- <button type="button" class="btn btn-dark rounded-pill">
          <i class="fal fa-chevron-left me-2"></i>Back
        </button> -->
      </div>
    </form>
  </div>
</div>
