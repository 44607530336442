<!-- start hero -->
<img class="top_half_circle_styles" src="../assets/images-new/TopHalfCircle.png" />
<div class="position-relative">
  <div class="">
    <div class="buySection__style">
      <h2 class="buyText__style">
        {{"general.buyit" | translate}}. <span class="highlighted__style--text"> {{"general.splitit" |
          translate}}</span>.
        <br />{{"general.enjoyit" | translate}}.

      </h2>
      <button *ngIf="showSingupBtn" class="btn__style" routerLink="/consumer/login" (click)="onAuth('signup')">
        {{"general.signup"| translate}}
      </button>
    </div>

    <!-- <header class="header-bg d-flex align-items-lg-end justify-content-center overflow-hidden py-5 py-lg-0" id="home"
    [dir]="directionController.textDirection"> -->

    <!-- end container -->
    <!-- </header> -->
    <!-- end hero -->

    <!-- section action  -->
    <!-- <section class="d-flex justify-content-center mt-5 section2" style="padding-bottom: 10rem !important;"
    [dir]="directionController.textDirection">
    <div class="row w-75">
      <div class="col-lg-4 px-3 mb-2 section-action">
        <div class="p-4 pe-5 mob-textcenter">
          <img src="assets/images/home/download.png" class="mb-3 img-fluid" alt="">
          <h4 class=" fw-bold">{{'home.section1.title1' | translate}}</h4>
          <p class="text-muted">{{'home.section1.caption1' | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4 px-3 mb-2 section-action">
        <div class="p-4 pe-5 mob-textcenter">
          <img src="assets/images/home/download (2).png" class="mb-3 img-fluid" alt="">
          <h4 class=" fw-bold">{{'home.section1.title2' | translate}}</h4>
          <p class="text-muted">{{'home.section1.caption2' | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4 px-3 section-action">
        <div class="p-4 pe-5 mob-textcenter">
          <img src="assets/images/home/download (1).png" class="mb-3 img-fluid" alt="">
          <h4 class=" fw-bold">{{'home.section1.title3' | translate}}</h4>
          <p class="text-muted">{{'home.section1.caption3' | translate}}</p>
        </div>
      </div>
    </div>
  </section> -->

    <section class="how-does-it-work">
      <app-howitworks></app-howitworks>
      <!-- <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active howSlidebar d-flex flex-column align-items-center justify-content-center text-center slider">
          <img src="assets/images/home/crousalimg.png" class="w-100 h-100 img-fluid" alt="">
          <div class=" position-absolute text-start container my-4 text-how row">

              <div class="col-lg-6 text-white d-flex flex-column justify-content-center">
                <h3 class=" text-white text-small mb-auto">How it works</h3>
                <div class=" mb-auto">
                  <h2 class="mb-4 text-small">Start shopping</h2>
                  <h5 class=" text-small">Fill your basket and then choose Talypay as your
                    payment option at checkout.</h5>
                </div>

              </div>
              <div class="col-lg-6 text-end">
                <img src="assets/images/home/Shopping Screen.png" alt="" class=" d-none d-lg-block img-fluid">
              </div>
          </div>
        </div>

      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
        data-bs-slide="prev">
        <span class="" aria-hidden="true"><img src="assets/images/home/Right Button.png" class="prevImg img-fluid"
            alt=""></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
        data-bs-slide="next">
        <span class="" aria-hidden="true"> <img src="assets/images/home/Right Button.png" class=" img-fluid"
            alt=""></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div> -->
    </section>

    <section class="how-does-it-work" *ngIf="false" [dir]="directionController.textDirection">
      <div class="container">
        <div class="row flex-wrap-reverse">
          <!-- steps column  -->
          <div class="col-lg-6">
            <h2 class="mb-5 mob-textcenter">
              {{ "home.section2.title" | translate }}
            </h2>
            <!-- nav tab toggler -->
            <ul class="nav mb-4 d-flex justify-content-lg-start justify-content-center">
              <li class="nav-item me-4 ms-0" (click)="onNavTab(1)">
                <h6 class="pb-1 navTab" [ngClass]="navtabsClass[0]" data-bs-toggle="tab" data-bs-target="#tab-online"
                  type="button" role="tab" aria-selected="true">
                  <b>{{ "home.section2.tab1.tabName" | translate }}</b>
                </h6>
              </li>
              <li class="nav-item" (click)="onNavTab(2)">
                <h6 class="pb-1 navTab" [ngClass]="navtabsClass[1]" data-bs-toggle="tab" data-bs-target="#tab-offline"
                  type="button" role="tab" aria-selected="false">
                  <b>{{ "home.section2.tab2.tabName" | translate }}</b>
                </h6>
              </li>
            </ul>

            <!-- tab content here  -->
            <div class="tab-content">
              <!-- online tab -->
              <div class="tab-pane fade show active mb-5" id="tab-online" role="tabpanel">
                <!-- online tab content start here -->
                <div class="online-tab-content">
                  <div class="row">
                    <div class="col-md-9 how-section">
                      <span class="steps mb-4">{{
                        "home.section2.tab1.content.0.step" | translate
                        }}</span>
                      <h4>
                        {{ "home.section2.tab1.content.0.title" | translate }}
                      </h4>
                      <p>
                        {{ "home.section2.tab1.content.0.caption" | translate }}
                      </p>
                    </div>
                  </div>

                  <div class="row mt-5">
                    <div class="col-md-9 how-section">
                      <span class="steps">{{
                        "home.section2.tab1.content.1.step" | translate
                        }}</span>
                      <h4>
                        {{ "home.section2.tab1.content.1.title" | translate }}
                      </h4>
                      <p>
                        {{ "home.section2.tab1.content.1.caption" | translate }}
                      </p>
                    </div>
                  </div>

                  <div class="row mt-5">
                    <div class="col-md-9 how-section">
                      <span class="steps">STEP 3</span>
                      <h4>
                        {{ "home.section2.tab1.content.1.title" | translate }}
                      </h4>
                      <p>
                        {{ "home.section2.tab1.content.2.caption" | translate }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- online tab end  -->
              </div>

              <!-- offline tab -->
              <div class="tab-pane fade mb-5" id="tab-offline" role="tabpanel">
                <!-- offline tab content  -->
                <div class="online-tab-content">
                  <div class="row">
                    <div class="col-md-9 how-section">
                      <span class="steps mb-4">{{
                        "home.section2.tab2.content.0.step" | translate
                        }}</span>
                      <h4>
                        {{ "home.section2.tab2.content.0.title" | translate }}
                      </h4>
                      <p>
                        {{ "home.section2.tab2.content.0.caption" | translate }}
                      </p>
                    </div>
                  </div>

                  <div class="row mt-5">
                    <div class="col-md-9 how-section">
                      <span class="steps">{{
                        "home.section2.tab2.content.1.step" | translate
                        }}</span>
                      <h4>
                        {{ "home.section2.tab2.content.1.title" | translate }}
                      </h4>
                      <p>
                        {{ "home.section2.tab2.content.1.caption" | translate }}
                      </p>
                    </div>
                  </div>

                  <div class="row mt-5">
                    <div class="col-md-9 how-section">
                      <span class="steps">{{
                        "home.section2.tab2.content.2.step" | translate
                        }}</span>
                      <h4>
                        {{ "home.section2.tab2.content.2.title" | translate }}
                      </h4>
                      <p>
                        {{ "home.section2.tab2.content.2.caption" | translate }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- offline tab content end  -->
              </div>
            </div>
          </div>

          <!-- img column  -->
          <div class="col-lg-6 mb-5 d-flex justify-content-center align-items-center">
            <div class="thumbnail text-center d-flex flex-column w-75 h-100">
              <img src="assets/images/image_2.png" alt="" class="img-fluid h-100 w-75 ms-auto" />
              <div
                class="position-absolute h-100 d-flex justify-content-start align-items-center my-auto text-white py-3">
                <img src="assets/images/apple-iphoneX.png" alt="" class="img-fluid h-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sensible-spending" [dir]="directionController.textDirection">
      <app-featured-merchant></app-featured-merchant>
    </section>

    <!-- <section class="sensible-spending mb-5" [dir]="directionController.textDirection">
    <app-shop-categories></app-shop-categories>
  </section> -->

    <!-- <section class="faq-section mx-sm-3 rounded-3 mt-5" [dir]="directionController.textDirection"> -->
    <div class="faq__Style">
      <h2 class="faxHeading">FAQ</h2>
      <div class="accordian__Style">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item faqs-section">
            <h2 class="accordion-header" id="flush-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">

                {{"content.faqPage.tabs.tab1Content.0.ques1" | translate}}
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                {{"content.faqPage.tabs.tab1Content.0.ans1" | translate}}
              </div>
            </div>
          </div>
          <div class="accordion-item faqs-section">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">

                {{"content.faqPage.tabs.tab2Content.0.ques1" | translate}}
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                {{"content.faqPage.tabs.tab2Content.0.ans1" | translate}}


              </div>
            </div>
          </div>
          <div class="accordion-item faqs-section">
            <h2 class="accordion-header" id="flush-headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">

                {{"content.faqPage.tabs.tab1Content.0.ques2" | translate}}
              </button>
            </h2>
            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <p><strong>{{"content.faqPage.tabs.tab1Content.0.ans2.1" | translate}}: </strong></p>
                <p>
                  {{"content.faqPage.tabs.tab1Content.0.ans2" | translate}}
                </p>
                <p><strong>{{"content.faqPage.tabs.tab1Content.0.ans2.2" | translate}}:</strong></p>
                <p>
                  {{"content.faqPage.tabs.tab1Content.0.ans2.3" | translate}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item faqs-section">
            <h2 class="accordion-header" id="flush-headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                {{"content.faqPage.tabs.tab1Content.0.ques3" | translate}}
              </button>
            </h2>
            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                {{"content.faqPage.tabs.tab1Content.0.ans3" | translate}}
              </div>
            </div>
          </div>
          <div class="accordion-item faqs-section">
            <h2 class="accordion-header" id="flush-headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">

                {{"content.faqPage.tabs.tab1Content.0.ques4" | translate}}
              </button>
            </h2>
            <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <p>
                  {{"content.faqPage.tabs.tab1Content.0.ans4" | translate}}
                  {{"content.faqPage.tabs.tab1Content.0.ans4.1" | translate}}
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item faqs-section">
            <h2 class="accordion-header" id="flush-headingSix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                {{"content.faqPage.tabs.tab1Content.0.ques5" | translate}}
              </button>
            </h2>
            <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                {{"content.faqPage.tabs.tab1Content.0.ans5" | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btnSyle">
        <button class="btn__style" routerLink="/faq">GOT A QUESTION?</button>
      </div>
    </div>

    <!-- Buy It Bootom Section Starts Here -->
    <div class="buyItBtmSec">
      <div class="howItwork__style">
        <div class="buySection__style">
          <div class="dollorImgs">
            <img src="../../assets/images-new/DollersImg.svg" />
          </div>
          <!-- <h2 class="buyText__style">
            Buy It. <span class="highlighted__style--text">Split</span> It.
            <br />Enjoy It.
          </h2> -->
          <h2 class="buyText__style">
            {{"general.buyit" | translate}}. <span class="highlighted__style--text"> {{"general.splitit" |
              translate}}</span>.
            <br />{{"general.enjoyit" | translate}}.

          </h2>
        </div>
      </div>
    </div>

    <!-- <div class="container">
      <div class=" bg-white ">
        <h3 class="mb-4 mob-textcenter">{{'home.section4.title' | translate}}</h3>
        <div class="accordion mb-5" id="faq">
          <div class="faq-item border-0 p-0">
            <button class=" accordion-btn collapsed fw-bold text-primary" type="button" data-bs-toggle="collapse"
              data-bs-target="#ques-1" aria-expanded="true" aria-controls="collapseOne">
              {{'home.section4.faq.0.ques' | translate}}
            </button>
            <div id="ques-1" class="accordion-collapse collapse" data-bs-parent="#faq">
              <div class="accordion-body text-primary">
                {{'home.section4.faq.0.ans' | translate}}
              </div>
            </div>
          </div>

          <div class="faq-item border-0 p-0">
            <button class=" accordion-btn collapsed fw-bold text-primary" type="button" data-bs-toggle="collapse"
              data-bs-target="#ques-2" aria-expanded="true" aria-controls="collapseOne">
              {{'home.section4.faq.1.ques' | translate}}

            </button>
            <div id="ques-2" class="accordion-collapse collapse" data-bs-parent="#faq">
              <div class="accordion-body text-primary">
                {{'home.section4.faq.1.ans' | translate}}
              </div>
            </div>
          </div>

          <div class="faq-item border-0 p-0">
            <button class=" accordion-btn collapsed fw-bold text-primary" type="button" data-bs-toggle="collapse"
              data-bs-target="#ques-3" aria-expanded="true" aria-controls="collapseOne">
              {{'home.section4.faq.2.ques' | translate}}

            </button>
            <div id="ques-3" class="accordion-collapse collapse" data-bs-parent="#faq">
              <div class="accordion-body text-primary">
                {{'home.section4.faq.2.ans' | translate}}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div> -->
    <!-- </section> -->

    <!-- <section class="section3">
    <div class="mob-textcenter container" style="padding-bottom: 13rem !important;">
      <h4 class="mb-4">Were you unable to find what you were
        searching for?</h4>
      <button class="btn btn-success rounded-pill fw-bold" routerLink="/faq"> <i
          class="fal fa-arrow-right me-2 ms-0"></i>HELP
        CENTER</button>
    </div>
  </section> -->

    <!-- <section class="sell-more" style="background-color: #f2ced8;" [dir]="directionController.textDirection">
    <div class="container">
      <div class="row">

        <div class="col-lg-5 d-flex justify-content-center px-3 py-4 align-items-center">
          <div class="mob-textcenter">
            <h2>Boost your business
              with Taly.</h2>
            <p class="my-3 pe-lg-5 pe-0 ps-0"> Attract, convert, and retain customers with flexible payment options and performance-driven marketing.</p>
            <a role="button" class="btn btn-success rounded-pill fw-bold text-white" routerLink="/forbusiness">
              <i class="fal fa-arrow-right me-2 ms-0"></i>FOR BUSINESS</a>
          </div>
        </div>
        <div class="col-lg-5 mb-4 d-flex align-items-center justify-content-center justify-content-lg-start">
        </div>
      </div>
    </div>
  </section> -->

    <!-- <section class="section4 w-100 p-5" style="padding-bottom: 40rem !important;">

  </section> -->
  </div>
</div>