<!-- form 1 component -->

<div class="section-pad d-flex flex-column mx-auto border mb-4">
  <h3 class="mb-4">Merchant Request Form</h3>
  <p class="mb-4">
    Please provide your business details and Talypay representative will contact
    you shortly
  </p>

  <!-- business name  -->
  <label class="small text-dark mb-1"
    >Business name <span class="text-danger">*</span></label
  >
  <input
    autocomplete="off"
    type="text"
    name="marchant-business-name"
    class="input-text mb-4"
  />

  <!-- country of registration  -->
  <label class="small text-dark mb-1"
    >Country of registration <span class="text-danger">*</span></label
  >
  <select
    autocomplete="off"
    name="country-of-registration"
    class="input-text form-select mb-4"
  >
    <option selected>Kuwait</option>
    <option>country xyz</option>
    <option>country other</option>
  </select>

  <!-- industry  -->
  <label class="small text-dark mb-1"
    >Industry <span class="text-danger">*</span></label
  >
  <select
    autocomplete="off"
    name="Marchant-industry"
    class="input-text form-select mb-4"
  >
    <option selected>Retail</option>
    <option>Wholesales</option>
    <option>xyz</option>
  </select>

  <!-- Business type  -->
  <label class="small text-dark mb-1"
    >Business type <span class="text-danger">*</span></label
  >
  <select
    autocomplete="off"
    name="Marchant-Business-type"
    class="input-text form-select mb-4"
  >
    <option selected>Both</option>
    <option>XYZ</option>
    <option>xyz</option>
  </select>

  <!-- website URL  -->
  <label class="small text-dark mb-1"
    >Website URL <span class="text-danger">*</span></label
  >
  <input
    autocomplete="off"
    type="url"
    name="marchant-website"
    class="input-text mb-4"
  />

  <!-- Store address  -->
  <label class="small text-dark mb-1"
    >Store address <span class="text-danger">*</span></label
  >
  <input
    autocomplete="off"
    type="text"
    name="marchant-store-address"
    class="input-text"
  />

  <!-- platform  -->
  <label class="small text-dark mb-1"
    >Platform <span class="text-danger">*</span></label
  >
  <input
    autocomplete="off"
    type="text"
    name="marchant-platform"
    class="input-text"
  />

  <!-- proceed btn  -->
  <a href="javascript:void(0)" role="button" id="proceed-btn">Proceed</a>

  <!-- section/colume-1 end here  -->
</div>
