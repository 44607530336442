import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public storageSub = new Subject<String>();

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  getItem(key: string): any {
    const data = sessionStorage.getItem(key);
    this.storageSub.next(data);
  }

  setItem(key: string, data: any) {
    sessionStorage.setItem(key, data);
    this.storageSub.next('changed');
  }

  removeItem(key) {
    sessionStorage.removeItem(key);
    this.storageSub.next('changed');
  }

  clearEverything() {
    sessionStorage.clear();
    this.storageSub.next('changed');
  }
}
