import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { SignupService } from 'src/app/Core/services/signup/signup.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-national-id-form',
  templateUrl: './national-id-form.component.html',
  styleUrls: ['./national-id-form.component.scss'],
})
export class NationalIdFormComponent implements OnInit {
  @Output() moveStep = new EventEmitter<number>();

  showMobileId: boolean = false;
  nationalIdForm: FormGroup;
  submitted = false;
  showMessage: boolean = false;
  get f() {
    return this.nationalIdForm.controls;
  }
  modalRef?: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  interval: any;

  template: TemplateRef<any>;
  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private loaderService: NgxUiLoaderService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dataService: DataServiceService,
    private router: Router,
    private modalService: BsModalService
  ) {}
  otpResponse: any;
  error: string;
  errorText: string;
  link = `${location.origin}/terms-and-conditions`;
  ngOnInit(): void {
    // sessionStorage.setItem('step', '3');

    this.nationalIdForm = this.formBuilder.group({
      nationalId: ['', [Validators.required]],
      tnc: ['', [Validators.required]],
    });

    this.otpResponse = this.signupService.getOtpResponse();
    this.translate.get('toastr.error').subscribe((text) => (this.error = text));
    this.translate
      .get('toastr.errortext')
      .subscribe((text) => (this.errorText = text));
    // sessionStorage.setItem('checkoutSignupEmit', '');
  }

  isLoading: boolean = false;
  onSubmit(template: TemplateRef<any>) {
    this.submitted = true;
    this.isLoading = true;
    if (
      this.nationalIdForm.invalid ||
      this.nationalIdForm.get('nationalId').value.length != 12
    ) {
      this.toastr.error(this.errorText, this.error);
      this.isLoading = false;
      return;
    }
    this.loaderService.start();
    this.validateBirthDate();
    if (this.isDobValid) {
      sessionStorage.setItem('nationalId', this.f.nationalId.value);

      const formData: any = {
        requestId: sessionStorage.getItem('reqID'),
        nationalId: this.f.nationalId.value,
      };

      this.signupService.verifyNationalId(formData).subscribe(
        (resp) => {
          this.moveStep.emit(11);
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.loaderService.stop();
          this.toastr.error(error.error.message || error.message, this.error);
        },
        () => {
          this.isLoading = false;
          this.loaderService.stop();
        }
      );
    } else {
      this.loaderService.stop();
      this.toastr.error('Your national Id is Invalid!', this.error);
      this.isLoading = false;
      this.loaderService.stop();
      this.nationalIdForm.reset();
    }
  }

  isDobValid: boolean;
  validateBirthDate() {
    let id: string = this.nationalIdForm.get('nationalId').value;
    let a = id.slice(1, 7);
    let dateArr = [];
    for (let i = 0; i < a.length - 1; i += 2) {
      dateArr.push(Number(a[i] + '' + a[i + 1]));
    }

    let year = dateArr[0];
    let month = dateArr[1];
    let date = dateArr[2];

    this.validateMonth(month, date);
  }

  validateMonth(monthInput: number, dateInput: number) {
    if (monthInput <= 12) {
      this.isDobValid = true;
      let noFDaysInMonth = this.getNoOfDaysPerMonth(monthInput);
      this.validateDate(dateInput, noFDaysInMonth);
    } else this.isDobValid = false;
  }

  validateDate(dateInput: number, noFDaysInMonth) {
    if (dateInput <= noFDaysInMonth) {
      this.isDobValid = true;
    } else this.isDobValid = false;
  }

  getNoOfDaysPerMonth(monthInput: number): number {
    switch (monthInput) {
      case 1:
        return 31;
      case 2:
        return 29;
      case 3:
        return 31;
      case 4:
        return 30;
      case 6:
        return 31;
      case 5:
        return 30;
      case 7:
        return 31;
      case 8:
        return 31;
      case 9:
        return 30;
      case 10:
        return 31;
      case 11:
        return 30;
      case 12:
        return 31;
    }
  }

  onBack() {
    sessionStorage.removeItem('step');

    if (sessionStorage.getItem('checkoutSignupEmit') != '') {
      let orderToken = sessionStorage.getItem('orderTokenForCheckout');
      this.router.navigate(['/checkout/securecheckout', orderToken]);
    } else this.moveStep.emit(1);
  }

  openNewTab() {
    window.open(`${location.origin}/terms-and-conditions`, '_blank');
  }

  isChecked: boolean = false;
  isCheckboxSelect() {
    this.isChecked = !this.isChecked;
  }
}
