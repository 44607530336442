<section class="d-flex justify-content-end mt-3 w-100">
  <button
    class="btn text-nowrap"
    [disabled]="currentPage == 1"
    (click)="onPrev()"
  >
    <i class="fad fa-chevron-double-left me-1 ms-0"></i>Previous
  </button>
  <span
    class="bg-primary px-3 py-2 text-white mx-1 d-flex align-items-center"
    >{{ currentPage }}</span
  >
  <button
    class="btn text-nowrap"
    [disabled]="totalPages == currentPage"
    (click)="onNext()"
  >
    Next <i class="fad fa-chevron-double-right ms-1 me-0"></i>
  </button>
  <div style="float: right">
    <select
      autocomplete="off"
      name=""
      id=""
      class="form-select w-auto h-100"
      *ngIf="pages"
      [(ngModel)]="currentPage"
      (ngModelChange)="onChangePage()"
    >
      <option class="h-100" *ngFor="let page of pages" value="{{ page }}">
        {{ page }}
      </option>
    </select>
  </div>
  <span #totalpage class="d-none">{{ totalPages }}</span>
</section>
