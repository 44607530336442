<!-- <p>payment-success works!</p> -->

<div class="d-flex justify-content-center align-items-center" style="height: 35rem !important;">
    <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="tick-oval d-flex justify-content-center align-items-center mb-4" style="background-color: #6edd9d;">
            <i class="fal fa-check-circle text-white fa-3x"></i>
        </div>

        <h4 class=" text-center">Your order has been successfully placed.</h4>
        <h6 class=" text-muted text-center">Please wait while we are redirecting you back to the merchant</h6>

        <!-- <button class="btn btn-primary w-100 mt-5" (click)="onRedirect()">REDIRECT TO MERCHANT WEBSITE</button> -->
    </div>
</div>