<section class="border p-4 my-3 container mx-auto">
  <app-merchant-header></app-merchant-header>

  <ng-container *ngIf="fullOrderDetails">
    <div class="rounded-3 py-3 px-4 mb-3 timeline-bg">
      <div class="d-flex mb-4">
        <span class="fw-bold text-primary me-1 ms-0">Your payment plan</span>
        <label class="text-primary">
          {{
            fullOrderDetails.amount
              | currency : fullOrderDetails.currency : "code"
          }}
        </label>
      </div>

      <div class="my-5 w-100 d-flex flex-nowrap justify-content-center">
        <div
          class="timline"
          *ngFor="let installment of installments; let i = index"
        >
          <div class="d-flex flex-nowrap align-items-center">
            <div class="w-100" [ngClass]="i == 0 ? 'line-sp' : 'line-hr'"></div>
            <div
              style="
                height: 16px !important;
                width: 33px !important;
                border-radius: 16px !important;
              "
              [ngClass]="i == 0 ? 'bg-primary' : 'bg-info'"
            ></div>
            <div
              class="w-100"
              [ngClass]="
                i == this.installments.length - 1 ? 'line-sp' : 'line-hr'
              "
            ></div>
          </div>
          <div class="d-flex flex-column timline-textbox mt-3 text-center">
            <h6 class="text-primary mb-1 amount text-wrap">
              {{
                installment.amount | currency : installment.currency : "code"
              }}
            </h6>
            <label class="small text-info text-wrap">
              {{
                installment.dueDateDesc !== undefined
                  ? installment.dueDateDesc == "Due next month" ||
                    installment.dueDateDesc == "Due now"
                    ? installment.dueDateDesc
                    : (installment.dueDate | utcToLocalTime : "short")
                  : (installment.dueDate | utcToLocalTime : "short")
              }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showLoginScreen">
    <h5 class="text-primary mb-3">Log in/Sign up</h5>
    <h6 class="text-muted mb-4">
      Enter your mobile number to get the verification code
    </h6>

    <form autocomplete="off" [formGroup]="numberForm" (ngSubmit)="onSubmit()">
      <div class="mb-3 px-4">
        <div class="txtbox d-flex p-0">
          <app-countycode-selector
            (countrycode)="countryCode = $event"
          ></app-countycode-selector>
          <input
            autocomplete="off"
            type="text"
            formControlName="number"
            class="contactTxtbox txtbox"
            id="username"
            OnlyNumber
            maxlength="8"
            [ngClass]="isInvalid ? 'invalidInput' : ''"
          />
        </div>
      </div>

      <div class="mb-3 text-start px-lg-4 px-0">
        <!-- <label for="" class="form-label">{{
          "general.contact.captcha" | translate
        }}</label> -->
        <!-- <ngx-recaptcha2
          #captchaElem
          [siteKey]="siteKey"
          formControlName="recaptcha"
          [hl]="captchaLang"
          (success)="captchaResponse = $event"
        >
        </ngx-recaptcha2> -->

        <ngx-invisible-recaptcha
          #captchaElem
          [siteKey]="siteKey"
          (success)="handleSuccess($event)"
          [useGlobalDomain]="false"
          [type]="type"
          [theme]="theme"
          [badge]="badge"
          formControlName="recaptcha"
        >
        </ngx-invisible-recaptcha>

        <div
          class="text-danger"
          *ngIf="isInvalid && numberForm.get('recaptcha').invalid"
        >
          <small
            ><i class="fal fa-exclamation-circle"></i> Captcha is
            required!</small
          >
        </div>
        <button
          type="submit"
          class="btn btn-success w-100 mt-4"
          [disabled]="numberForm.invalid"
        >
          Continue
        </button>
        <button
          role="button"
          data-bs-toggle="modal"
          data-bs-target="#backModal"
          class="btn btn-success w-100 mt-4 dsfsd"
        >
          Cancel
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="showOtpScreen">
    <app-otp
      class="animate__animated animate__fadeInDown"
      [mobNumber]="mobileNumber"
      [countryCode]="countryCode"
      (moveStep)="showScreen($event)"
      (loggedIn)="loggedIn.emit()"
    ></app-otp>
  </ng-container>

  <ng-container *ngIf="showNationalIDScreen">
    <app-national-id-form
      class="animate__animated animate__fadeInDown"
      (moveStep)="showScreen($event)"
    >
    </app-national-id-form>
  </ng-container>

  <ng-container *ngIf="showMobileIdScree">
    <app-mobile-id-checkout
      class="animate__animated animate__fadeInDown"
      (moveStep)="showScreen($event)"
    >
    </app-mobile-id-checkout>
  </ng-container>

  <ng-container *ngIf="showCustomerInfoScreen">
    <app-customer-info-checkout
      class="animate__animated animate__fadeInDown"
      (moveStep)="showScreen($event)"
      (loggedIn)="loggedIn.emit()"
    >
    </app-customer-info-checkout>
  </ng-container>

  <ng-container *ngIf="showAddCardScreen">
    <app-card-info-checkout
      (loggedIn)="loggedIn.emit()"
      class="animate__animated animate__fadeInDown"
      (moveStep)="showScreen($event)"
    >
    </app-card-info-checkout>
  </ng-container>
  <!-- <a
  role="button"
  id="back-btn"
  data-bs-toggle="modal"
  data-bs-target="#backModal"
>
  <i class="fal fa-chevron-left me-2"></i>Back
</a> -->
  <!-- Modal -->
  <div
    class="modal fade"
    id="backModal"
    tabindex="-1"
    aria-labelledby="backModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h5 class="modal-title" id="backModal">Back to Login</h5> -->
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Are you sure you want to Cancel?</div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            No
          </button>
          <button
            type="button"
            class="btn btn-success"
            data-bs-dismiss="modal"
            (click)="cancelPayment()"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<!--  -->
