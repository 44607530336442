<div class="d-flex text-nowrap justify-content-center">
  <span class="text-primary">Don't receive the OTP?</span>
  <button
    class="bg-white border-0"
    (click)="onResend()"
    [disabled]="count != 0"
  >
    Resend OTP
  </button>
  <span *ngIf="count != 0">
    in
    <span class="couter-time"> {{ counter$ | async }} </span> seconds</span
  >
</div>
