import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/signin/auth.service';
import { Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { NgxHttpLoaderService } from 'ngx-http-loader';
import { TokenStorageService } from './token-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private loadingBar: LoadingBarService,
    public authService: AuthenticationService,
    private router: Router,
    private ngxhttploader: NgxHttpLoaderService,
    private tokenStorageService: TokenStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('check_token')) {
      request = this.addBasicToken(request);
    } else {
      if (request.headers.has('refreshtoken')) {
        request = this.addBasicToken(request);
      } else {
        if (request.url.indexOf('/accounts/profile/creditCard') > -1) {
          request = this.addToken(request, this.tokenStorageService.getToken());
        } else {
          if (this.tokenStorageService.getToken()) {
            request = this.addToken(
              request,
              this.tokenStorageService.getToken()
            );
          } else {
            request = this.addBasicToken(request);
          }
        }
      }
    }

    return next.handle(request).pipe(
      catchError((error) => {
        console.group('Interceptor');
        console.log(error);
        console.groupEnd();
        const errorDesc = error.error.error_description;
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          errorDesc != 'Password expired'
        ) {
          this.loadingBar.complete();
          return this.handle401Error(request, next);
        } else {
          this.loadingBar.complete();
          let errorMsg: any = error;
          if (errorMsg?.error?.errors != undefined) {
            errorMsg.error.message =
              errorMsg.error.message ||
              errorMsg?.error?.errors[0] ||
              errorMsg.message;
          }

          return throwError(errorMsg);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  private addBasicToken(request: HttpRequest<any>) {
    if (request.headers.has('requestFor')) {
      if (request.headers.get('requestFor') == 'merchant') {
        return request.clone({
          setHeaders: {
            Authorization: 'Basic bWVyY2hhbnQ6c2VjcmV0',
          },
        });
      } else {
        return request.clone({
          setHeaders: {
            Authorization: 'Basic YnJvd3NlcjpzZWNyZXQ=',
          },
        });
      }
    } else {
      return request.clone({
        setHeaders: {
          Authorization: 'Basic YnJvd3NlcjpzZWNyZXQ=',
        },
      });
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          return next.handle(this.addToken(request, token.access_token));
        }),
        catchError((err) => {
          this.authService.localClear('token-service');
          this.router.navigate(['/consumer/login']);
          // setTimeout(() => {
          //   this.ngxhttploader.hide();
          // }, 3000);
          return throwError(err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        }),
        catchError((err) => {
          const _err = err?.error?.error;
          this.authService.localClear('token-service');
          this.router.navigate(['/consumer/login']);
          // setTimeout(() => {
          //   this.ngxhttploader.hide();
          // }, 3000);
          return throwError(err);
        })
      );
    }
  }
}
