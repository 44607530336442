<div class="custom-modal-wrapper">
  <div class="custom-modal-header">
    <h1 class="title">Buy now, pay over time.</h1>
    <p class="desc">0% interest. 100% Sharia-compliant.</p>
  </div>

  <div class="custom-modal-body">
    <ul class="custom-list mb-0">
      <li>
        Select
        <img
          src="assets/images-new/footer-logos/Logo-Badge.png"
          style="height: 26.9px"
        />
        at checkout.
      </li>
      <li>Choose to split your payment into 4 or pay later in 30 days.</li>
      <li>
        You'll be redirected to
        <a target="_blank" href="https://taly.io">Taly's website</a>. Create an
        account with just your phone number.
      </li>
      <li>
        Complete your first order. We'll send SMS reminders before your next
        payment is due.
      </li>
    </ul>

    <div class="payment-option">
      <img
        src="assets/images-new/footer-logos/desktop-view.png"
        class="img-center m-none"
      />

      <img
        src="assets/images-new/footer-logos/mobile-view.png"
        class="m-block mobile-plan"
      />
    </div>
  </div>

  <div class="custom-modal-footer">
    <div class="d-flex flex-row align-items-center">
      <div class="mr-auto pd-20">
        Questions?
        <a target="_blank" href="https://taly.io/faq">Visit our FAQs</a>
      </div>
      <div>
        <div class="img-pd">
          <img
            src="assets/images-new/footer-logos/knet.png"
            class="img-center"
          />
        </div>

        <div class="img-pd">
          <img
            src="assets/images-new/footer-logos/apple-pay.png"
            class="img-center"
          />
        </div>

        <div class="img-pd">
          <img
            src="assets/images-new/footer-logos/visa-card.png"
            class="img-center"
          />
        </div>

        <div class="img-pd">
          <img
            src="assets/images-new/footer-logos/master-card.png"
            class="img-center"
          />
        </div>
      </div>
    </div>
  </div>
</div>
