<!-- <p>payment-plan works!</p> -->
<div class="text-primary">
  <div class="row" *ngIf="false">
    <div class="col-lg-8 mt-3 mt-lg-0">
      <div class="d-flex flex-wrap align-items-center my-4">
        <h6 class="me-3 ms-0 fw-bold w-25 tW">Order Amount</h6>
        <h5 class="fw-bold text-primary">
          <span class="me-2 ms-0">{{ fullOrderDetails.currency }}</span
          >{{
            fullOrderDetails.amount
              | currency : fullOrderDetails.currency : "code"
          }}
        </h5>
      </div>
      <div class="d-flex flex-wrap align-items-center my-4">
        <h6 class="me-3 ms-0 fw-bold w-25 tW">Merchant</h6>
        <h5 class="fw-bold text-secondary">
          {{ fullOrderDetails.merchant.businessName }}
        </h5>
      </div>
    </div>
    <div class="col-lg-4">
      <img src="" alt="" />
    </div>
  </div>

  <ng-container *ngIf="fullOrderDetails">
    <div class="rounded-3 py-3 px-4 mb-3 timeline-bg">
      <div class="d-flex mb-4">
        <span class="fw-bold text-primary me-1 ms-0"
          ><i class="fas fa-cart-arrow-down me-1 ms-0"></i>Your cart</span
        >
        <label class="text-primary ms-auto">{{
          fullOrderDetails.amount
            | currency : fullOrderDetails.currency : "code"
        }}</label>
      </div>

      <div class="my-5 w-100 d-flex flex-nowrap justify-content-center">
        <div
          class="timline"
          *ngFor="let installment of installments; let i = index"
        >
          <div class="d-flex flex-nowrap align-items-center">
            <div class="w-100" [ngClass]="i == 0 ? 'line-sp' : 'line-hr'"></div>
            <div
              style="
                height: 16px !important;
                width: 33px !important;
                border-radius: 16px !important;
              "
              [ngClass]="i == 0 ? 'bg-primary' : 'bg-info'"
            ></div>
            <div
              class="w-100"
              [ngClass]="
                i == this.installments.length - 1 ? 'line-sp' : 'line-hr'
              "
            ></div>
          </div>
          <div class="d-flex flex-column mt-3 text-center">
            <h6 class="text-primary mb-1 amount">
              {{
                installment.amount | currency : installment.currency : "code"
              }}
            </h6>
            <label class="small text-info text-wrap">
              {{
                installment.dueDateDesc !== undefined
                  ? installment.dueDateDesc == "Due next month" ||
                    installment.dueDateDesc == "Due now"
                    ? installment.dueDateDesc
                    : (installment.dueDate | utcToLocalTime : "short")
                  : (installment.dueDate | utcToLocalTime : "short")
              }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <form autocomplete="off" [formGroup]="paymentForm" (ngSubmit)="payNow()">
    <!-- plans  -->
    <div class="row mt-4">
      <h6 class="mb-3 fw-bold">Payment plan</h6>
      <div class="col-sm-6 d-flex mb-2 mb-lg-0">
        <input
          autocomplete="off"
          type="radio"
          name="plan"
          formControlName="plan"
          value="ONE_MONTH"
          class="me-2 ms-0"
          id="plan1"
          (change)="onPlanChange(true)"
        />
        <h6 class="text-dark"><label for="plan1">Pay later</label></h6>
      </div>
      <div class="col-sm-6 d-flex">
        <input
          autocomplete="off"
          type="radio"
          name="plan"
          formControlName="plan"
          value="THREE_MONTHS"
          class="me-2 ms-0"
          id="plan2"
          (change)="onPlanChange(false)"
        />
        <h6 class="text-dark"><label for="plan2">Split in 4</label></h6>
      </div>
    </div>

    <!-- card details  -->
    <div class="mt-5">
      <div class="row mb-3">
        <div class="col-sm-5 d-flex align-items-center mb-4 mb-lg-0">
          <!-- <img src="assets/images/icons8-cash_in_hand.png" class=" img-fluid" alt=""> -->
          <i class="fal fa-credit-card"></i>
          <h5 class="ms-3 me-0 mt-lg-0 mt-2 mb-0 fw-bold">Card Details</h5>
        </div>
        <div class="col-sm-7 d-flex align-items-center justify-content-end">
          <button
            class="btn btn-outline-success btn-xxs me-3 ms-0 px-2"
            [ngClass]="defaultCard ? 'ms-0' : 'ms-auto'"
            data-bs-toggle="modal"
            data-bs-target="#addcardModel"
            type="button"
          >
            <i class="fas fa-credit-card me-1 ms-0 text-primary"></i>Add
          </button>
          <button
            class="btn btn-outline-success btn-xxs px-2"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#cardlistModel"
            *ngIf="defaultCard"
          >
            <!-- <i class="fas fa-mouse-pointer me-1 ms-0 text-primary"></i> -->
            Change
          </button>
        </div>
      </div>
      <!-- <ng-container *ngIf="!defaultCard">
        <p class="text-danger mb-2">You don't have any default card!</p>
      </ng-container> -->

      <div class="form-check mb-3 p-0" *ngIf="defaultCard">
        <input
          autocomplete="off"
          class="form-check-input d-none"
          type="radio"
          value="credit"
          formControlName="paymentMethod"
          name="paymentMethod"
          id="creditcard"
        />
        <label class="form-check-label w-100" for="creditcard">
          <ng-container *ngIf="defaultCard">
            <p
              class="mt-2 mb-1"
              style="color: #dc3545"
              *ngIf="defaultCard.status == 'EXPIRED'"
            >
              Your card has expired. Please select or add a new card.
            </p>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button d-flex"
                    type="button"
                    (click)="setPaymentType('credit')"
                    [ngClass]="
                      paymentForm.value.paymentMethod == 'credit'
                        ? 'active'
                        : 'no-active'
                    "
                  >
                    <!--card icon  -->
                    <div
                      class="icon d-flex justify-content-center align-items-center me-3 ms-0"
                      [ngSwitch]="defaultCard.cardType.toLowerCase()"
                    >
                      <img
                        class="img-fluid"
                        *ngSwitchCase="'visa'"
                        src="assets/images/icons/visa@2x.png"
                        alt=""
                      />
                      <img
                        class="img-fluid"
                        *ngSwitchCase="'mastercard'"
                        src="assets/images/icons/mc@2x.png"
                        alt=""
                      />
                      <img
                        class="img-fluid"
                        *ngSwitchCase="'american_express'"
                        src="assets/images/icons/amex@2x.png"
                        alt=""
                      />
                      <img
                        class="img-fluid"
                        *ngSwitchCase="'discover'"
                        src="assets/images/icons/discover@2x.png"
                        alt=""
                      />
                      <img
                        class="img-fluid"
                        *ngSwitchCase="'diners_club'"
                        src="assets/images/icons/diners@2x.png"
                        alt=""
                      />
                      <img
                        class="img-fluid"
                        *ngSwitchDefault
                        src="assets/images/icons/add-card@2x.png"
                        alt=""
                      />
                    </div>
                    <h6 class="fw-bold">
                      {{ defaultCard.expiryMonth }}/{{ defaultCard.expiryYear }}
                    </h6>
                    <h6 class="fw-bold w-100 text-end me-4 ms-2">
                      **** **** ****
                      {{
                        defaultCard.maskedCreditCardNumber
                          | slice
                            : defaultCard.maskedCreditCardNumber.length - 4
                      }}
                    </h6>

                    <div>
                      <!-- <i
                        class="far fa-edit"
                        (click)="setValuesToEditForm()"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      ></i> -->
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <form
                      autocomplete="off"
                      [formGroup]="editCardForm"
                      *ngIf="defaultCard"
                    >
                      <label class="mb-2 small">Card Holder</label>
                      <div class="txtbox mb-4 bg-grey">
                        {{ defaultCard.holderName | truncate : 12 }}
                      </div>

                      <label class="mb-2 small">Card Number</label>
                      <div class="txtbox mb-4 bg-grey">
                        **** **** ****
                        {{
                          defaultCard.maskedCreditCardNumber
                            | slice
                              : defaultCard.maskedCreditCardNumber.length - 4
                        }}
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-4 mb-lg-0 mb-4">
                          <label for="expiry" class="mb-2 small">Expiry</label>
                          <input
                            autocomplete="off"
                            type="text"
                            id="expiry"
                            formControlName="expiry"
                            ccExp
                            readonly="true"
                            class="txtbox bg-grey"
                            placeholder="MM/YY"
                            maxlength="7"
                          />

                          <span
                            class="text-danger small d-block mt-1"
                            *ngIf="
                              editCardForm
                                .get('expiry')
                                .hasError('expiryNotValid')
                            "
                            >Not Valid!</span
                          >
                        </div>
                        <div class="col-lg-4">
                          <label for="cvv" class="mb-2 small">CVV</label>
                          <input
                            autocomplete="off"
                            type="password"
                            id="cvv"
                            ccCVC
                            readonly="true"
                            class="txtbox bg-grey"
                            formControlName="cvv"
                            appClickOutside
                            (clickOutside)="onClickOutside('edit', 'cvv')"
                          />

                          <span
                            class="text-danger small d-block mt-1"
                            *ngIf="isEditCvvInvalid"
                            >Not Valid!</span
                          >
                        </div>
                      </div>
                    </form>

                    <!-- <button
                      type="button"
                      class="btn btn-success mt-3"
                      [disabled]="editCardForm.invalid"
                      (click)="onEditSave()"
                    >
                      Save changes
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </label>
      </div>

      <div
        class="form-check p-0"
        *ngIf="paymentForm.get('plan').value != 'ONE_MONTH'"
      >
        <input
          autocomplete="off"
          class="form-check-input d-none"
          type="radio"
          value="knet"
          formControlName="paymentMethod"
          name="paymentMethod"
          id="knet"
          checked
        />
        <label class="form-check-label w-100" for="knet">
          <div class="accordion" id="accordionKnet">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingtwo">
                <button
                  (click)="setPaymentType('knet')"
                  class="accordion-button knet-btn d-flex"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                  [ngClass]="
                    paymentForm.value.paymentMethod == 'knet'
                      ? 'active'
                      : 'no-active'
                  "
                >
                  <img src="assets/images/KNETLogo.svg" class="knet-logo" />
                  {{ "general.knet" | translate }}
                </button>
              </h2>
            </div>
          </div>
        </label>
      </div>

      <!-- <div class="accordion mt-2" id="accordionKnet" *ngIf="paymentForm.get('plan').value != 'ONE_MONTH'">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button    [disabled]="paymentForm.invalid ||  isProcessPayment"
              class="accordion-button d-flex knet-btn"
              type="button"
              data-bs-toggle="collapse"
              (click)="payByKnet()"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
            <img src="assets/images/KNETLogo.svg" class="knet-logo"> {{'general.knet' |translate}}
            </button>
          </h2>
        </div>
      </div> -->
    </div>

    <!-- agree TnC  -->
    <div class="my-4 d-flex">
      <input
        autocomplete="off"
        type="checkbox"
        id="agree"
        class="me-2 ms-0"
        formControlName="tnc"
      />
      <h6>
        I agree to the
        <a href="{{ link }}" target="_blank"> terms and conditions </a>
      </h6>
    </div>

    <!-- proceed btn  -->
    <div class="w-100 d-flex justify-content-center">
      <button
        class="btn btn-success btn-lg w-75"
        type="submit"
        [disabled]="paymentForm.invalid || isButtonDisable"
      >
        {{
          paymentForm.get("plan").value == "ONE_MONTH"
            ? "Complete Order"
            : "Process Payment"
        }}
      </button>
    </div>
  </form>

  <!-- <div class="text-center mt-3">
    <a
      role="button"
      data-bs-toggle="modal"
      data-bs-target="#howModel"
      (click)="onOpenHowModel()"
      >How it works?</a
    >
  </div> -->

  <!-- how model  -->
  <div
    class="modal fade"
    id="howModel"
    tabindex="-1"
    aria-labelledby="howModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="howModelLabel">How it works?</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center">
          <h4>3 Interest-free payments of Taly</h4>
          <label class="small mb-3"
            >Taly lets you split your purchases into 4 interest-free payments or
            pay after your purchase within 30 days.</label
          >

          <!-- nav tab toggler -->
          <ul class="nav mb-4 justify-content-center">
            <li class="nav-item me-5 ms-0" (click)="onNavTab(1)">
              <h6
                class="pb-1 navTab"
                [ngClass]="navtabsClass[0]"
                data-bs-toggle="tab"
                data-bs-target="#tab-online"
                type="button"
                role="tab"
                aria-selected="true"
              >
                <b>{{
                  "content.howPage.section1.tabs.tabName1" | translate
                }}</b>
              </h6>
            </li>
            <li class="nav-item" (click)="onNavTab(2)">
              <h6
                class="pb-1 navTab"
                [ngClass]="navtabsClass[1]"
                data-bs-toggle="tab"
                data-bs-target="#tab-offline"
                type="button"
                role="tab"
                aria-selected="false"
              >
                <b>{{
                  "content.howPage.section1.tabs.tabName2" | translate
                }}</b>
              </h6>
            </li>
          </ul>

          <!-- tab content  -->
          <div class="tab-content">
            <!-- online tab -->
            <div
              class="tab-pane fade show active mb-5"
              id="tab-online"
              role="tabpanel"
            >
              <img
                src="assets/images/how1.png"
                alt=""
                class="img-fluid mx-auto mx-lg-0 mb-2"
                style="height: 10rem; width: 10rem"
              />
              <h6 class="fw-bold">Choose your favorite brand</h6>
              <label class="text-muted small"
                >Add what you want to your cart and choose Taly at checkout.
                Sign up, link any card and get approved instantly.</label
              >

              <img
                src="assets/images/how2.png"
                alt=""
                class="img-fluid mx-auto mx-lg-0 mb-2"
                style="height: 10rem; width: 10rem"
              />
              <h6 class="fw-bold mt-2">Choose Taly at the checkout</h6>
              <label class="text-muted small"
                >Pay only 25% upfront and we'll remind you about the rest.
                Completely free, no interest.</label
              >

              <img
                src="assets/images/how3.png"
                alt=""
                class="img-fluid mx-auto mx-lg-0 mb-2"
                style="height: 10rem; width: 10rem"
              />
              <h6 class="fw-bold mt-2">Pay in 4 or in 30 days</h6>
              <label class="text-muted small"
                >Add what you want to your cart and choose Taly at checkout.
                Sign up, link any card and get approved instantly.</label
              >
            </div>

            <!-- offline tab -->
            <div class="tab-pane fade mb-5" id="tab-offline" role="tabpanel">
              <img
                src="assets/images/how4.png"
                alt=""
                class="img-fluid mx-auto mx-lg-0 mb-2"
                style="height: 10rem; width: 10rem"
              />
              <h6 class="fw-bold">Download our app</h6>
              <label class="text-muted small"
                >Add what you want to your cart and choose Taly at checkout.
                Sign up, link any card and get approved instantly.</label
              >

              <img
                src="assets/images/how5.png"
                alt=""
                class="img-fluid mx-auto mx-lg-0 mb-2"
                style="height: 10rem; width: 10rem"
              />
              <h6 class="fw-bold">Scan our QR code</h6>
              <label class="text-muted small"
                >Pay only 25% upfront and we'll remind you about the rest.
                Completely free, no interest.</label
              >

              <img
                src="assets/images/how6.png"
                alt=""
                class="img-fluid mx-auto mx-lg-0 mb-2"
                style="height: 10rem; width: 10rem"
              />
              <h6 class="fw-bold">Pay and enjoy</h6>
              <label class="text-muted small"
                >Add what you want to your cart and choose Taly at checkout.
                Sign up, link any card and get approved instantly.</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--process payment Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    *ngIf="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h4 class="modal-title" id="staticBackdropLabel">
            {{ onPay30 ? "Pay in 30 days" : "Split in 4" }}
          </h4>
        </div>
        <div class="modal-body">
          <!-- steps  -->
          <div>
            <div
              class="step"
              *ngFor="let installment of installments; let i = index"
              [ngClass]="i == 0 ? 'step-active' : ''"
            >
              <div>
                <div class="circle"></div>
              </div>
              <div class="row">
                <div class="col-sm-5">
                  <h5 class="fw-bold text-black-50">
                    {{
                      installment.amount
                        | currency : installment.currency : "code"
                    }}
                  </h5>
                </div>
                <div class="col-sm-5">
                  <h6 class="fw-bold text-black-50">
                    Installment {{ installment.nbOfInstallment + 1 }}
                  </h6>
                  <span class="fw-bold text-info">
                    {{ installment.dueDate | utcToLocalTime : "short" }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4 mb-2">
            <i class="far fa-check me-2 ms-0"></i>
            <span class="text-muted">No Sign up fees</span>
          </div>
          <div>
            <div>
              <i class="far fa-check me-2 ms-0"></i>
              <span class="text-muted">Interest free - automatic payments</span>
            </div>
          </div>
          <div class="modal-footer border-top-0 d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-outline-success"
              data-bs-dismiss="modal"
              (click)="onProcessPayment()"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add card modal  -->
  <div
    class="modal fade"
    id="addcardModel"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="addcardModel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Card</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            #addCloseModel
            (click)="addCardForm.reset(); backToCard()"
          ></button>
        </div>
        <div class="modal-body">
          <ng-container *ngIf="isCardPosted; else card_content">
            <div class="offcanvas-body text-center align-middle h-100 mt-30">
              <ng-constainer *ngIf="isCardVerified; else error_content">
                <div class="text-center text-primary mb-20">
                  <img
                    routerLink="/home"
                    src="assets/images/logo/taly_logo.png"
                    class="img-fluid mb-1"
                    width="120"
                    height="100"
                    alt="taly"
                  />
                  <h4 class="text-primary">Secure Checkout</h4>
                </div>
                <div
                  class="d-flex flex-column align-items-center justify-content-center"
                >
                  <div
                    class="tick-oval d-flex justify-content-center align-items-center mb-4"
                    style="background-color: #6edd9d"
                  >
                    <i class="fal fa-check-circle text-white fa-3x"></i>
                  </div>

                  <h4 class="text-center">{{ cardVerifiedMsg }}</h4>
                  <button
                    class="btn btn-primary w-100 mt-5"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    #addCloseModel
                    (click)="backToCard()"
                  >
                    {{ "general.back" | translate }}
                  </button>
                </div>
              </ng-constainer>

              <ng-template #error_content>
                <div class="text-center text-primary mb-20">
                  <img
                    routerLink="/home"
                    src="assets/images/logo/taly_logo.png"
                    class="img-fluid mb-1"
                    width="120"
                    height="100"
                    alt="taly"
                  />
                  <h4 class="text-primary">Secure Checkout</h4>
                </div>
                <div
                  class="d-flex flex-column align-items-center justify-content-center"
                >
                  <div
                    [class.bg-danger]="cardVerifiedMsg != undefined"
                    class="tick-oval d-flex justify-content-center align-items-center mb-4"
                  >
                    <i
                      *ngIf="cardVerifiedMsg != undefined"
                      class="fal fa-exclamation-circle text-white fa-3x"
                    ></i>

                    <div *ngIf="cardVerifiedMsg == undefined" class="fa-3x">
                      <i class="fas fa-spinner fa-pulse"></i>
                    </div>
                  </div>

                  <div>
                    {{
                      cardVerifiedMsg == undefined
                        ? ("general.processing" | translate)
                        : cardVerifiedMsg
                    }}
                  </div>
                  <button
                    class="btn btn-primary w-100 mt-5"
                    (click)="backToCard()"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    #addCloseModel
                  >
                    {{ "general.back" | translate }}
                  </button>
                </div>
              </ng-template>
            </div>
          </ng-container>

          <ng-template #card_content>
            <form autocomplete="off" [formGroup]="addCardForm">
              <label class="mb-2 small" for="holder">Card Holder</label>
              <input
                autocomplete="off"
                type="text"
                id="holder"
                formControlName="holder"
                noSpecialCharacter
                avoidNumbers
                maxlength="100"
                class="txtbox mb-3"
              />

              <label class="mb-2 small" for="cardNumber">Card Number</label>
              <div class="txtbox mb-1">
                <input
                  style="width: 85%"
                  autocomplete="off"
                  type="tel"
                  name="card-number"
                  class="fw-bold contactTxtbox"
                  formControlName="number"
                  autocomplete="cc-number"
                  maxlength="19"
                  ccNumber
                  #ccNumber="ccNumber"
                  appClickOutside
                  (clickOutside)="onClickOutside('add', 'cNum')"
                />

                <span
                  class="scheme float-end"
                  *ngIf="!addCardForm.get('number').hasError('ccNumber')"
                  [ngSwitch]="ccNumber.resolvedScheme$ | async"
                >
                  <img
                    class="img-fluid"
                    *ngSwitchCase="'visa'"
                    src="assets/images/icons/visa.png"
                    alt=""
                    style="height: 26px; width: 35px"
                  />
                  <img
                    class="img-fluid"
                    *ngSwitchCase="'mastercard'"
                    src="assets/images/icons/mc.png"
                    alt=""
                    style="height: 26px; width: 35px"
                  />
                  <img
                    class="img-fluid"
                    *ngSwitchCase="'american express'"
                    src="assets/images/icons/amex.png"
                    alt=""
                    style="height: 26px; width: 35px"
                  />
                  <img
                    class="img-fluid"
                    *ngSwitchCase="'discover'"
                    src="assets/images/icons/discover.png"
                    alt=""
                    style="height: 26px; width: 35px"
                  />
                  <img
                    class="img-fluid"
                    *ngSwitchCase="'dinersclub'"
                    src="assets/images/icons/diners.png"
                    alt=""
                    style="height: 26px; width: 35px"
                  />
                  <img
                    class="img-fluid"
                    *ngSwitchDefault
                    src="assets/images/icons/add-card.png"
                    alt=""
                    style="height: 26px; width: 35px"
                  />
                </span>
              </div>
              <!-- <span class="text-danger small d-block mt-1"
                        *ngIf="addCardForm.get('number').value!=null && !addCardForm.get('number').hasError('ccNumber')">Not
                        valid!</span> -->

              <span
                class="text-danger small d-block mt-1"
                *ngIf="
                  type != undefined &&
                  type != null &&
                  type != 'visa' &&
                  type != 'mastercard'
                    ? 'text-danger'
                    : ''
                "
              >
                Not supported cards by the Taly
              </span>

              <span
                class="text-danger small d-block mt-1"
                *ngIf="
                  isClickOutSide &&
                  addCardForm.get('number').value != null &&
                  addCardForm.get('number').hasError('ccNumber')
                "
                >Please provide valid card number.</span
              >

              <!-- card Accepted  -->
              <div class="mb-3">
                <p class="small my-1">
                  {{ "general.cardAccepted" | translate }}
                </p>
                <div class="mb-4">
                  <img src="assets/images/visa.svg" class="cards me-2 ms-0" />
                  <img
                    src="assets/images/icons-8-mastercard.png"
                    class="cards me-2"
                  />
                  <!-- <img src="assets/images/icons-8-american-express.png" class="cards me-2" />
                                        <img src="assets/images/icons-8-discover.png" class="cards me-2" />
                                        <img src="assets/images/icons-8-diners-club.png" class="cards me-2" /> -->
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-lg-4 mb-lg-0 mb-4">
                  <label for="expiry" class="mb-2 small">Expiry</label>
                  <input
                    autocomplete="off"
                    type="text"
                    id="expiry"
                    formControlName="expiry"
                    ccExp
                    class="txtbox"
                    placeholder="MM/YY"
                    maxlength="7"
                  />

                  <span
                    class="text-danger small d-block mt-1"
                    *ngIf="addCardForm.get('expiry').hasError('expiryNotValid')"
                    >Not Valid!</span
                  >
                </div>
                <div class="col-lg-4">
                  <label for="cvv" class="mb-2 small">CVV</label>
                  <input
                    autocomplete="off"
                    type="password"
                    id="cvv"
                    ccCVC
                    class="txtbox"
                    formControlName="cvv"
                    appClickOutside
                    (clickOutside)="onClickOutside('add', 'cvv')"
                  />

                  <span
                    class="text-danger small d-block mt-1"
                    *ngIf="isCvvInvalid"
                    >Not Valid!</span
                  >
                </div>
              </div>
            </form>
          </ng-template>
        </div>
        <div
          class="modal-footer d-flex justify-content-end"
          *ngIf="!isCardPosted"
        >
          <button
            type="button"
            class="btn btn-success"
            [disabled]="
              addCardForm.invalid || (type != 'visa' && type != 'mastercard')
            "
            (click)="onAdd()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- edit card modal  -->
  <div
    class="modal fade"
    id="editcardModel"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="editcardModel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Card</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            #editCloseModel
          ></button>
        </div>
        <div class="modal-body">
          <form
            autocomplete="off"
            [formGroup]="editCardForm"
            *ngIf="defaultCard"
          >
            <label class="mb-2 small">Card Holder</label>
            <div class="txtbox mb-4">{{ defaultCard.holderName }}</div>

            <label class="mb-2 small">Card Number</label>
            <div class="txtbox mb-4">
              **** **** ****
              {{
                defaultCard.maskedCreditCardNumber
                  | slice : defaultCard.maskedCreditCardNumber.length - 4
              }}
            </div>

            <div class="row mb-4">
              <div class="col-lg-4 mb-lg-0 mb-4">
                <label for="expiry" class="mb-2 small">Expiry</label>
                <input
                  autocomplete="off"
                  type="text"
                  id="expiry"
                  formControlName="expiry"
                  ccExp
                  class="txtbox"
                  placeholder="MM/YY"
                  maxlength="7"
                />

                <span
                  class="text-danger small d-block mt-1"
                  *ngIf="editCardForm.get('expiry').hasError('expiryNotValid')"
                  >Not Valid!</span
                >
              </div>
              <div class="col-lg-4">
                <label for="cvv" class="mb-2 small">CVV</label>
                <input
                  autocomplete="off"
                  type="password"
                  id="cvv"
                  ccCVC
                  class="txtbox"
                  formControlName="cvv"
                  appClickOutside
                  (clickOutside)="onClickOutside('edit')"
                />

                <span
                  class="text-danger small d-block mt-1"
                  *ngIf="isEditCvvInvalid"
                  >Not Valid!</span
                >
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-success"
            [disabled]="editCardForm.invalid"
            (click)="onEditSave()"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- all cards list Model  -->
  <div
    class="modal fade"
    id="cardlistModel"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="cardlistModel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Select Card</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            #listCloseModel
          ></button>
        </div>
        <div class="modal-body px-0">
          <div
            class="cardBox"
            *ngFor="let card of cards; let i = index"
            (click)="onSelectCard(i)"
          >
            <div class="row py-3 border-2 border-bottom w-100 m-0">
              <div class="col-2 d-flex justify-content-center p-0 text-white">
                <div
                  class="number-icon bg-info d-flex justify-content-center align-items-center"
                  *ngIf="defaultCardIndex != i"
                >
                  {{ i + 1 }}
                </div>
                <i
                  class="fas fa-check-circle select-icon text-success"
                  *ngIf="defaultCardIndex == i"
                ></i>
              </div>
              <div
                class="d-flex flex-column justify-content-center col-10 px-2"
              >
                <div class="mb-1 fw-bold">
                  {{ card.cardType }}
                  <span
                    >**** **** ****
                    {{
                      card.maskedCreditCardNumber
                        | slice : card.maskedCreditCardNumber.length - 4
                    }}</span
                  >
                </div>

                <div class="small">
                  Expiry: {{ card.expiryMonth }}/{{ card.expiryYear }}
                </div>
                <div class="small text-truncate">
                  {{ card.holderName }}
                  <span
                    class="badge badge-danger .rounded-pill"
                    *ngIf="card.status == 'EXPIRED'"
                  >
                    {{ card.status }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->

          <button
            type="button"
            class="btn btn-success"
            *ngIf="cards"
            [disabled]="cards.length == 1 || isExpired"
            (click)="setCardAsDefault()"
          >
            Select
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
