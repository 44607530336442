import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { AuthenticationService } from 'src/app/Core/services/signin/auth.service';
import { LoginOtpService } from 'src/app/Core/services/signin/login-otp.service';
import { SignupService } from 'src/app/Core/services/signup/signup.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  showNationalIDScreen: boolean = false;

  @Input() mobNumber: any;
  @Input() countryCode: any;
  @Output() moveStep = new EventEmitter<number>();
  @Output() loggedIn = new EventEmitter<any>();
  constructor(
    private _loginService: AuthenticationService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private dataSerive: DataServiceService,
    private signupService: SignupService,
    private otpService: LoginOtpService
  ) {}

  success: string;
  error: string;
  errorText: string;

  isActionLogin: boolean;
  otpResponse: any;
  ngOnInit(): void {
    this.translate
      .get('toastr.otpNumber.error')
      .subscribe((text) => (this.error = text));
    this.translate
      .get('toastr.otpNumber.errortext')
      .subscribe((text) => (this.errorText = text));
    this.translate
      .get('toastr.otpNumber.success2')
      .subscribe((text) => (this.success = text));

    this.dataSerive.isCheckoutActionlogin().subscribe((res) => {
      this.isActionLogin = res;
      res ? '' : (this.otpResponse = this.signupService.getOtpResponse());
    });
  }

  public settings = {
    length: 6,
    allowNumbersOnly: true,
    inputClass: 'border-primary',
    containerClass: 'text-center',
  };

  disableSubmit: boolean = true;
  @ViewChild('mobileNumber') mobileNumberElem: ElementRef;
  public onInputChange(otp) {
    if (otp.length == this.settings.length) {
      // if otp has filled

      if (this.isSubmit == false) {
        this.isActionLogin ? this.login(otp) : this.signup(otp);
      }
    }
  }

  isSubmit: boolean = false;

  login(otp) {
    this.isSubmit = true;
    const username = (this.countryCode + this.mobNumber).trim();
    const otpModel = {
      requestId: parseInt(sessionStorage.getItem('reqID')),
      otpReference: sessionStorage.getItem('otpRef'),
      otpValue: otp,
    };
    this.otpService.verifyOTP(otpModel).subscribe(
      (res) => {
        if (res != 'signup') {
          this._loginService
            .login(username, res, false, 'checkout-login')
            .subscribe((res) => {
              if (res) {
                this.disableSubmit = false;
                this.getOrderToken();
                this.loggedIn.emit();
              } else {
                this.disableSubmit = true;
                this.toastr.error(this.errorText, this.error);
              }
            });
        } else if (res == 'signup') {
          this.disableSubmit = false;
          this.getOrderToken();
          sessionStorage.setItem('reg_otp', otp);
          sessionStorage.setItem('step', '3');
          sessionStorage.setItem(
            'regis_number',
            this.mobileNumberElem.nativeElement.innerHTML.replace(' ', '')
          );
          sessionStorage.setItem('checkoutSignupEmit', 'true');
          //this.router.navigate(['/consumer/login']);
          this.moveStep.emit(10);
          // this.showNationalIDScreen = true;
        }
        this.isSubmit = false;
      },
      (err) => {
        this.isSubmit = false;
        this.toastr.error(JSON.parse(err.error).message, this.error);
      }
    );
  }

  signup(otp) {
    this.isSubmit = true;
    const otpModel: any = {
      otpValue: otp,
      otpReference: sessionStorage.getItem('otpRef'),
      requestId: parseInt(sessionStorage.getItem('reqID')),
    };

    this.otpService.verifyOTP(otpModel).subscribe(
      (res) => {
        if (res == 'signup') {
          this.disableSubmit = false;
          this.getOrderToken();
          sessionStorage.setItem('step', '3');
          sessionStorage.setItem(
            'regis_number',
            this.mobileNumberElem.nativeElement.innerHTML.replace(' ', '')
          );
          sessionStorage.setItem('checkoutSignupEmit', 'true');
          this.router.navigate(['/consumer/register']);
        }
        this.isSubmit = false;
      },
      (err) => {
        this.disableSubmit = true;
        this.isSubmit = false;
        this.toastr.error(JSON.parse(err.error).message, this.error);
      }
    );
  }

  onSubmit() {
    this.toastr.success(this.success);
    this.loadingAnimation();
  }

  loading: boolean = false;
  loadingAnimation() {
    if (!this.disableSubmit) {
      this.loading = true;
      this.disableSubmit = true;
      setTimeout(() => {
        this.loading = false;
        this.disableSubmit = false;
        // this.isActionLogin? this.login(otp): this.signup(otp);
      }, 1000);
    }
  }

  getOrderToken() {
    let orderToken = this.router.url.slice(
      this.router.url.lastIndexOf('/') + 1
    );
    sessionStorage.setItem('orderTokenForCheckout', orderToken);
  }
}
