import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-taly-business',
  templateUrl: './taly-business.component.html',
  styleUrls: ['./taly-business.component.scss']
})
export class TalyBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
