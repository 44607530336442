<div class="p-1 dropdown me-2 ms-0">
  <button
    class="btn bg-white d-flex align-items-center px-2 mobile-input"
    type="button"
    *ngIf="country"
  >
    <img src="{{ country.imageUrl }}" class="me-1 ms-0 img-fluid" />+{{
      country.countryCode
    }}
  </button>
</div>
