export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public ApiUrl = 'https://api.dev-taly.io';

  // Whether or not to enable debug mode
  public enableDebug = true;

  constructor() {
  }

}
