<div class="sidebar" [class.expanded]="isExpanded">
  <div class="header">
    <h1 class="title">
      <img
        [routerLink]="['/home']"
        src="./assets/images-new/Logo.png"
        class="logo_image_styles"
      />
    </h1>
  </div>
  <div class="mt-2">
    <strong class="getting-started">getting started</strong>
  </div>
  <div class="content">
    <ng-container *ngFor="let menu of menuList; let i = index">
      <div class="link" *ngIf="menu?.isChild == false">
        <div
          class="content"
          [routerLink]="menu.link"
          [routerLinkActive]="'active'"
        >
          <!-- <div class="icon" *ngIf="menu?.icon">
            <i class="fa {{ menu.icon }}" aria-hidden="true"></i>
          </div> -->
          <div class="title">{{ menu.title }}</div>
        </div>
      </div>

      <div
        class="link with-children"
        hide="!item.isActive"
        *ngIf="menu?.isChild == true"
      >
        <div class="content">
          <div class="title menuTitle" (click)="toggleAccordian($event, i)">
            {{ menu.title }}
          </div>
        </div>
        <div class="children panel">
          <div class="link" *ngFor="let child of menu.children; let j = index">
            <div
              class="content"
              [routerLink]="child.link"
              [routerLinkActive]="'active'"
            >
              <!-- <div class="icon"></div> -->
              <div class="title">
                <div class="flex-row align-items-center">
                  <div style="padding-right: 10px">
                    <ng-container [ngSwitch]="child.menthod">
                      <!-- <span
                        *ngSwitchCase="'POST'"
                        class="badge bg-warning {{ child.menthod }}"
                        >{{ child.menthod }}</span
                      > -->
                      <!-- <span
                        *ngSwitchCase="'PUT'"
                        class="badge bg-secondary {{ child.menthod }}"
                        >{{ child.menthod }}</span
                      > -->
                      <!-- <span
                        *ngSwitchCase="'GET'"
                        class="badge bg-success {{ child.menthod }}"
                        >{{ child.menthod }}</span
                      > -->
                      <!-- <span
                        *ngSwitchCase="'DELETE'"
                        class="badge bg-danger {{ child.menthod }}"
                        >{{ child.menthod }}</span
                      > -->
                      <!-- <span
                        *ngSwitchCase="'PATCH'"
                        class="badge bg-info text-dark {{ child.menthod }}"
                        >{{ child.menthod }}</span
                      > -->
                    </ng-container>
                  </div>
                  <div>{{ child.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="footer"></div>
</div>
