import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
// import * as Sentry from "@sentry/angular";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
    // Sentry.init({
    //   dsn: "https://eaedc09f8dd1409f8df168a142d4cda4@o641935.ingest.sentry.io/5757705",
    //   environment: environment.name
    // });
  }
  handleError(error: any) {
    // const router = this.injector.get(Router);
    // //capture error to sentry cloud
    // const eventId = Sentry.captureException(error.originalError || error);
    // if (Error instanceof HttpErrorResponse) {
    // }
    // else {
    // console.error("an error occured here mate",error);
    // //ask user to report error if error not server related
    // //Sentry.showReportDialog({ eventId });
    // }
    // //navigate to error page
    // //router.navigate(['error']);
    // return error;
    // //pass the error if needed
  }
}
