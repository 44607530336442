<section>
    <div class="container section-inner">
        <nav aria-label="breadcrumb" style="margin-bottom:80px;">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/faq" class="text-muted nav-link p-0">Shopping</a></li>
                <span class="mx-2 text-muted">></span>
                <li class="breadcrumb-item active" aria-current="page"><a routerLink="/faqanswers"
                        class="text-muted nav-link p-0">What is taly?</a></li>
            </ol>
        </nav>

        <span class="display-6">What is Taly?</span>
        <!-- <div class="text-muted mt-3">
            <span>4 months ago</span> <span class="mx-3">•</span> <span>Updated</span>
        </div> -->

        <div class="answerBox row mt-5">
            <h5 class="col-lg-8 col-12 text-gray">Taly offers safe, simple and convenient payment options that allow you
                to buy the things you love now, and pay later, or in installments interest-free.</h5>
        </div>


        <h5 class="text-gray mt-5">Have more questions? <a  routerLink="/contactus" class="ms-1">Submit a request</a></h5>


    </div>
</section>