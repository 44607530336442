//import { CreditCardRequest } from 'src/app/Core/models/profile/creditcard.model';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { CreditCardValidators } from 'angular-cc-library';
import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { ProfileService } from 'src/app/Core/services/profile/profile.service';
import { TokenStorageService } from 'src/app/Core/interceptors/token-storage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-card-info-checkout',
  templateUrl: './card-info-checkout.component.html',
  styleUrls: ['./card-info-checkout.component.scss'],
})
export class CardInfoCheckoutComponent implements OnInit {
  @Output() loggedIn = new EventEmitter<any>();
  @Output() moveStep = new EventEmitter<number>();

  registerForm: FormGroup;
  constructor(
    private _profileService: ProfileService,
    private toastr: ToastrService,
    private router: Router,
    private dataService: DataServiceService,
    private tokenStorageService: TokenStorageService,
    private loaderService: NgxUiLoaderService,
  ) {}
  addCardForm: FormGroup;
  CardData: any = {
    holderName: null,
    creditCardNumber: null,
    cvv: null,
    expiryMonth: null,
    expiryYear: null,
    defaultCard: null,
    key: null,
  };
  isCardPosted: boolean = false;
  isCardVerified: boolean = false;
  cardVerifiedMsg: string;
  success: string;
  successtext: string;
  error: string;

  isSignupFromCheckout: boolean;
  orderToken: string;
  link: any = `${location.origin}/terms-and-conditions`;
  ngOnInit(): void {
    // sessionStorage.setItem('step', '5');

    this.addCardForm = new FormGroup({
      holderName: new FormControl(null, [Validators.required]),
      creditCardNumber: new FormControl(null, [
        Validators.required,
        CreditCardValidators.validateCCNumber,
      ]),
      expiry: new FormControl(null, [
        Validators.required,
        CreditCardValidators.validateExpDate,
        this.validateExpiry,
      ]),
      cvv: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ]),
      TnC: new FormControl(false, [Validators.requiredTrue]),
    });

    this.dataService
      .getOrderToken()
      .subscribe((res) => (this.orderToken = res));
  }

  onSubmit() {
    this.loaderService.start();
    if (this.addCardForm.invalid) {
      return;
    }
    this.isCardPosted = true;
    let expirydate = this.addCardForm.get('expiry').value.split('/');
 
    this.CardData.holderName = this.addCardForm.get('holderName').value.trim();
    this.CardData.creditCardNumber = this.addCardForm
      .get('creditCardNumber')
      .value.replace(/\s/g, '');
    this.CardData.expiryMonth = parseInt(expirydate[0].trim());
    this.CardData.expiryYear = parseInt('20' + expirydate[1].trim());
    this.CardData.cvv = this.addCardForm.get('cvv').value;
    this.CardData.defaultCard = true;
    this.CardData.key = this.tokenStorageService.getToken();

    this._profileService.postCardDetails(this.CardData).subscribe(
      (res) => {
        this.clearRegistrationDetails();
        if (res.response.code == '000' && res.transaction.url == null) {
          this.toastr.success(this.successtext, this.success);
          //this.onSkip();
          this.isCardVerified = true;
          this.cardVerifiedMsg = res.response.message;
        } else if (res.response.code == '100' && res.transaction.url != null) {
          window.location = res.transaction.url;
          //window.open(res.transaction.url, '_self');
          //this.onSkip();
        } else {
         // this.loaderService.stop();
          this.isCardVerified = false;
          this.cardVerifiedMsg = res.response.message;
          this.toastr.error(res.response.message, this.error);
        }
        // this.isSignupFromCheckout ? this.router.navigate(['/checkout/securecheckout', this.orderToken]) : this.router.navigate(['/consumer/dashboard']);
      },
      (err) => {
     //   this.loaderService.stop();
        this.isCardVerified = false;
        this.cardVerifiedMsg = err.error.message;
        this.toastr.error(err.error.message, this.error);
      }
    );
  }

  ccNumOutClick: boolean;
  cvvOutClick: boolean;
  checkInput(invalidType: String) {
    switch (invalidType) {
      case 'ccNum':
        this.addCardForm.get('creditCardNumber').value
          ? (this.ccNumOutClick = true)
          : (this.ccNumOutClick = false);
        break;

      case 'cvv':
        this.addCardForm.get('cvv').value
          ? (this.cvvOutClick = true)
          : (this.cvvOutClick = false);
        break;
    }
  }

  loading: boolean = false;
  loadingAnimation() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  //validation:-
  validateExpiry(control: FormControl): ValidationErrors | null {
    if (control.value != null) {
      let expiryDate: string[] = control.value.split('/');
      let expiryMonth = parseInt(expiryDate[0].trim());

      if (expiryDate[1] != null && expiryDate[1].length > 2) {
        let expiryYear = parseInt('20' + expiryDate[1].trim());
        let date = new Date();

        if (expiryYear > date.getFullYear()) {
          return null;
        } else if (expiryYear === date.getFullYear()) {
          if (expiryMonth > date.getMonth() + 1 && expiryMonth < 12) {
            return null;
          }
        }
        return { expiryNotValid: true };
      }
    }
    return null;
  }

  onSkip() {
    // this.moveStep.emit(5);
    this.clearRegistrationDetails();
    if (
      sessionStorage.getItem('orderTokenForCheckout') != null &&
      sessionStorage.getItem('orderTokenForCheckout') != undefined
    ) {
      let orderToken = sessionStorage.getItem('orderTokenForCheckout');
      // this.router.navigate(['/checkout/securecheckout', orderToken]);
      this.loggedIn.emit();
      sessionStorage.setItem('checkoutSignupEmit', 'true');
    } else {
      this.router.navigate(['/consumer/dashboard']);
    }
  }

  clearRegistrationDetails() {
    sessionStorage.removeItem('step');
    sessionStorage.removeItem('signupResponse');
  }

  backToCard() {
    this.isCardPosted = false;
    this.isCardVerified = false;
  }
}
