<!-- About us page  -->

<section class="mb-5 mx-auto bg-header  overflow-hidden py-5 py-lg-0" style="margin-top: 5rem;">
    <div class="container mt-5 d-flex flex-column justify-content-center align-items-center text-white">
        <h1 class="mb-3 mob-textcenter">Get to know Taly</h1>
        <h5 class="mob-textcenter px-4 p-lg-0">Taly lets you split your purchases into 4 interest-free payments or pay after your purchase within 30 days.</h5>
    </div>
</section>


<section class="container">
    <div class="row">
        <!-- column 1 for links  -->
        <div class="col-sm-4 px-2 mb-4">
            <div class="nav p-4 flex-column me-0 me-lg-3 border" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">

                <!-- link 1:  About Us -->
                <div class="navTab d-flex justify-content-start mb-3" data-bs-toggle="tab" data-bs-target="#about"
                type="button" role="tab" aria-controls="about" aria-selected="true" (click)="onLinkClick('about')">
                    <a role="button" [ngClass]="linkColors[0]">
                        About Us
                    </a>
                </div>

                <!-- link 2: Our Principles -->
                <div class="navTab d-flex justify-content-start mb-3" data-bs-toggle="tab" data-bs-target="#principles"
                    type="button" role="tab" aria-controls="principles" aria-selected="false" (click)="onLinkClick('principles')">
                    <a role="button" [ngClass]="linkColors[1]">
                        Our Principles
                    </a>
                </div>

                <!-- link 3:  Responsible Spending -->
                <div class="navTab  d-flex justify-content-start mb-3" data-bs-toggle="tab" data-bs-target="#spending"
                    type="button" role="tab" aria-controls="spending" aria-selected="false" (click)="onLinkClick('spending')">
                    <a role="button" [ngClass]="linkColors[2]">
                        Responsible Spending
                    </a>
                </div>

                <!-- link 4: PCI DSS -->
                <div class="navTab d-flex justify-content-start mb-3" data-bs-toggle="tab" data-bs-target="#PCI"
                    type="button" role="tab" aria-controls="PCI" aria-selected="false" (click)="onLinkClick('PCI')">
                    <a role="button" [ngClass]="linkColors[3]">
                        PCI DSS
                    </a>
                </div>

                <!-- link 5: Investors -->
                <!-- <div class="navTab d-flex justify-content-start mb-3" data-bs-toggle="tab" data-bs-target="#investors"
                    type="button" role="tab" aria-controls="investors" aria-selected="false" (click)="onLinkClick('investors')">
                    <a role="button" [ngClass]="linkColors[4]">
                        Investors
                    </a>
                </div> -->
            </div>

        </div>

        <!-- column 2 for content  -->
        <div class="col-sm-8 py-0">
            <div class="tab-content w-100">
                <!-- link 1 content  -->
                <div class="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="about">
                    <app-about-us></app-about-us>
                    <!-- link 1 end  -->
                </div>

                <!-- link 2 content  -->
                <div class="tab-pane fade" id="principles" role="tabpanel" aria-labelledby="principles">
                    l2
                    <!-- link 2 end  -->
                </div>

                <!-- link 3 content  -->
                <div class="tab-pane fade" id="spending" role="tabpanel" aria-labelledby="spending">
                    l3
                    <!-- link 3 end  -->
                </div>

                <!-- link 4 content  -->
                <div class="tab-pane fade" id="PCI" role="tabpanel" aria-labelledby="PCI">
                    l4
                    <!-- link 4 end  -->
                </div>

                <!-- link 5 content  -->
                <div class="tab-pane fade" id="investors" role="tabpanel" aria-labelledby="investors">
                    l5
                    <!-- link 5 end  -->
                </div>
            </div>
        </div>
    </div>
</section>
