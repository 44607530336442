import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessMsgDirective } from './success-msg.directive';
import { AlertMsgDirective } from './alert-msg.directive';
import { AllowOnlyNumberDirective } from './allow-only-number.directive';
import { OtpDesignDirective } from './otp-design.directive';
import { WebcamDesignDirective } from './webcam-design.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { LoginOtpDirective } from './login-otp.directive';
import { SpecialCharacterDirective } from './special-character.directive';
import { AvoidNumbersDirective } from './avoid-numbers.directive';



@NgModule({
  declarations: [
    SuccessMsgDirective,
    AlertMsgDirective,
    AllowOnlyNumberDirective,
    OtpDesignDirective,
    WebcamDesignDirective,
    ClickOutsideDirective,
    LoginOtpDirective,
    SpecialCharacterDirective,
    AvoidNumbersDirective
  ],
  imports: [
    CommonModule
  ],
  exports : [
    SuccessMsgDirective,
    AlertMsgDirective,
    AllowOnlyNumberDirective,
    OtpDesignDirective,
    WebcamDesignDirective,
    ClickOutsideDirective,
    LoginOtpDirective,
    SpecialCharacterDirective,
    AvoidNumbersDirective
  ]
})
export class CustomDirectivesModule { }
