import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pay30',
  templateUrl: './pay30.component.html',
  styleUrls: ['./pay30.component.scss']
})
export class Pay30Component implements OnInit {

  constructor() { }
  @Input() installments:any[];

  ngOnInit(): void {
  }

}
