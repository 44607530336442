import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataServiceService {
  constructor() {}
  languageControl = new Subject<any>();

  private emitCheckout = new BehaviorSubject(false);
  private isCheckoutsignup = this.emitCheckout.asObservable();
  checkoutSignupEmit(isLogin: boolean) {
    this.emitCheckout.next(isLogin);
  }

  isCheckoutSignup(): Observable<boolean> {
    return this.isCheckoutsignup;
  }

  private orderToken = new BehaviorSubject('');
  private getorderToken = this.orderToken.asObservable();
  emitOrderToken(ordertoken: string) {
    this.orderToken.next(ordertoken);
  }

  getOrderToken(): Observable<string> {
    return this.getorderToken;
  }

  private checkoutlogin = new BehaviorSubject(false);
  private ischeckoutlogin = this.checkoutlogin.asObservable();
  checkoutActionLoginEmit(isLogin: boolean) {
    this.checkoutlogin.next(isLogin);
  }

  isCheckoutActionlogin(): Observable<boolean> {
    return this.ischeckoutlogin;
  }

  private personalInfo = new BehaviorSubject(false);
  private isPersonalInfo = this.personalInfo.asObservable();
  personalInfoGotUpdate(gotUpdate: boolean) {
    this.personalInfo.next(gotUpdate);
  }

  isPersonalInfoGotUpdate(): Observable<boolean> {
    return this.isPersonalInfo;
  }

  private loginEmit = new BehaviorSubject(true);
  private islogin = this.loginEmit.asObservable();
  headerClickLogin(gotUpdate: boolean) {
    this.loginEmit.next(gotUpdate);
  }

  isHeaderClickLogin(): Observable<boolean> {
    return this.islogin;
  }
}
