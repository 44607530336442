<!-- <p>secure-checkout works!</p> -->
<div class="container-fluid pagebg">
  <!-- header  -->
  <div class="brandHead"></div>

  <section class="py-4 px-3">
    <div class="pb-1 borderbottom mb-4">
      <h5 class="fw-bold text-uppercase">Secure Checkout</h5>
    </div>

    <div class="row flex-wrap-reverse ms-0 me-0">
      <div class="col-lg-8 pe-0 ps-lg-5 ps-0">
        <!-- del method  -->
        <div class="w-100 mb-5">
          <h6 class="fw-bold mb-3">Delivery method</h6>
          <label
            for="home"
            class="d-flex p-2 px-3 bg-white w-75 mobW d-flex align-items-center"
          >
            <input
              autocomplete="off"
              type="radio"
              name="Home"
              id="home"
              class="me-3 ms-0"
            />
            <i class="fal fa-truck-container fa-2x me-3 ms-0"></i>
            <div>
              <p class="fw-bold">Home delivery</p>
              <p class="text-muted">Free delivery on order above BHD 150.000</p>
            </div>
          </label>
        </div>

        <!-- del information  -->
        <div class="mb-4">
          <h6 class="fw-bold mb-3">Delivery information</h6>
          <div
            style="border: 2px solid black"
            class="bg-light py-2 px-3 w-75 mobW mobW d-flex align-items-center"
          >
            <p>Please add your contact details and address.</p>
            <i class="fal fa-arrow-right ms-auto me-0"></i>
          </div>
        </div>
        <!-- payment method  -->
        <div class="mb-4">
          <h6 class="fw-bold mb-3">Payment information</h6>
          <label
            for="Payment1"
            class="d-flex p-2 px-3 bg-white w-75 mobW d-flex align-items-center mb-2"
          >
            <input
              autocomplete="off"
              type="radio"
              name="Payment"
              id="Payment1"
              class="me-3 ms-0"
            />
            <i class="fal fa-credit-card fa-2x me-3 ms-0"></i>
            <div>
              <p class="fw-bold">Debit Card/Credit Card</p>
              <p class="text-muted">Use your Credit or Debit card</p>
            </div>
          </label>
          <label
            for="Payment2"
            class="d-flex p-2 px-3 bg-white w-75 mobW d-flex align-items-center mb-2"
          >
            <input
              autocomplete="off"
              type="radio"
              name="Payment"
              id="Payment2"
              class="me-3 ms-0"
            />
            <i class="fal fa-sack fa-2x me-3 ms-0"></i>
            <div>
              <p class="fw-bold">Checkout with Benefit</p>
              <p class="text-muted">Pay with BenefitPay</p>
            </div>
          </label>
          <label
            for="Payment3"
            class="d-flex p-2 px-3 bg-white w-75 mobW d-flex align-items-center mb-2"
          >
            <input
              autocomplete="off"
              type="radio"
              name="Payment"
              id="Payment3"
              class="me-3 ms-0"
            />
            <img
              src="assets/images/group.png"
              class="img-fluid me-3 ms-0"
              alt=""
              height="40"
              width="40"
            />
            <div>
              <p class="fw-bold">Checkout with Taly</p>
              <p class="text-muted">
                Making payments easier than ever That's what Taly is all about!
              </p>
            </div>
          </label>
        </div>

        <!-- plan -->
        <div class="mb-4">
          <div class="p-4 px-3 bg-white w-75 mobW row m-0">
            <label
              for="plan1"
              class="col-lg-6 mb-lg-0 mb-2"
              (click)="onPlan(1)"
            >
              <input
                autocomplete="off"
                type="radio"
                name="plan"
                id="plan1"
                class="me-3 ms-0"
              />
              <label for="plan1" class="text-muted">Pay in 30 days</label>
            </label>
            <label for="plan2" class="col-lg-6" (click)="onPlan(2)">
              <input
                autocomplete="off"
                type="radio"
                name="plan"
                id="plan2"
                class="me-3 ms-0"
              />
              <label for="plan2" class="text-muted">Split in 4</label>
            </label>
            <div class="mt-4">
              <app-split4
                *ngIf="plans[1]"
                [installments]="installments"
              ></app-split4>
              <app-pay30
                *ngIf="plans[0]"
                [installments]="installments"
              ></app-pay30>
            </div>
          </div>
        </div>

        <!-- proceed  -->
        <div class="w-75 mobW">
          <p class="fw-bold">
            I confirm that I have read and accept the
            <a href="" class="text-dark text-decoration-underline me-1 ms-0"
              >Privacy policy</a
            >and
            <a href="" class="text-dark text-decoration-underline me-1 ms-0"
              >Terms & conditions</a
            >of purchase, read our
            <a href="" class="text-dark text-decoration-underline me-1 ms-0"
              >Return</a
            >policy.
          </p>
          <!-- btn -->
          <button
            class="mt-4 btn btn-dark w-100 text-uppercase"
            [disabled]="orderDetails ? !orderDetails.orderToken : true"
            [routerLink]="[
              '/checkout/securecheckout',
              orderDetails ? orderDetails.orderToken : ''
            ]"
            (click)="clearmerchantLogin()"
          >
            Complete Purchase
          </button>
        </div>
      </div>
      <div class="col-lg-4 mb-lg-0 mb-5">
        <h6 class="fw-bold mb-3">Order summary <span>(items 1)</span></h6>

        <div class="summary p-3">
          <div class="bg-white p-3 d-flex">
            <img
              src="assets/images/sample product.jpg"
              class="img-fluid"
              height="100"
              width="80"
            />

            <div class="ms-3 me-0">
              <p class="mb-2 fw-bold small">Hoodie Top</p>
              <p class="mb-3 small fw-bold">
                BHD <span class="ms-1 me-0">120.000</span>
              </p>
              <p class="mb-1 text-muted small fw-bold">
                Color label: <span class="ms-1 me-0">Dark black</span>
              </p>
              <p class="text-muted small fw-bold">
                Size: <span class="ms-1 me-0">5</span>
              </p>
            </div>
          </div>
        </div>
        <div class="bg-white p-3">
          <div class="pb-2 border-bottom d-flex mb-2">
            <span class="fw-bold">Subtotal</span>
            <span class="ms-auto fw-bold">BHD 120.000</span>
          </div>
          <div class="d-flex">
            <div class="fw-bold">
              <span class="mb-1">Order total</span>
              <p class="text-muted small">Excluding delivery</p>
            </div>
            <div class="fw-bold ms-auto">
              <span class="mb-1 ms-auto me-0 float-end">BHD 120.000</span>
              <p class="text-muted small">Inclusive of VAT</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
