import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';
import { HowItWorksComponent } from './content/how-it-works/how-it-works.component';
import { AboutComponent } from './content/about/about.component';
import { ContactUsComponent } from './content/contact-us/contact-us.component';
import { FaqComponent } from './content/faq/faq.component';
import { ContactComponent } from './content/contact/contact.component';
import { ComponentsModule } from '../Core/components/components.module';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { TalyBusinessComponent } from './content/taly-business/taly-business.component';
import { WhatIsTalyComponent } from './content/faq/what-is-taly/what-is-taly.component';
import { AboutUsComponent } from './content/about/about-us/about-us.component';
import { CustomDirectivesModule } from '../Core/custom-directives/custom-directives.module';
import { LangTranslateModule } from '../lang-translate.module';
import { CountrySelectComponent } from './country-select/country-select.component';
import { MerchantCheckoutModule } from './merchant-checkout/merchant-checkout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PluginHowComponent } from './plugin-how/plugin-how.component';
import { ShopComponent } from './content/shop/shop.component';
import { FeaturedMerchantComponent } from './home/featured-merchant/featured-merchant.component';
import { ShopCategoriesComponent } from './home/shop-categories/shop-categories.component';
import { HowitworksComponent } from './content/how-it-works/howitworks/howitworks.component';
import { PrivacyComponent } from './content/privacy/privacy.component';
import { TermsAndConditionsComponent } from './content/terms-and-conditions/terms-and-conditions';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TermsOfUseComponent } from './content/terms-of-use/terms-of-use.component';
import { RequestForm1Component } from './merchant/merchant-forms/request-form1/request-form1.component';
import { RequestForm2Component } from './merchant/merchant-forms/request-form2/request-form2.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { Form1Component } from './merchant/merchant-forms/request-form1/form1/form1.component';
import { Form2Component } from './merchant/merchant-forms/request-form1/form2/form2.component';
import { ThankYouComponent } from './content/thank-you/thank-you.component';

@NgModule({
  declarations: [
    HomeComponent,
    HowItWorksComponent,
    AboutComponent,
    ContactUsComponent,
    FaqComponent,
    ContactComponent,
    TalyBusinessComponent,
    WhatIsTalyComponent,
    AboutUsComponent,
    CountrySelectComponent,
    PluginHowComponent,
    ShopComponent,
    TermsAndConditionsComponent,
    FeaturedMerchantComponent,
    ShopCategoriesComponent,
    HowitworksComponent,
    PrivacyComponent,
    TermsOfUseComponent,
    RequestForm1Component,
    RequestForm2Component,
    Form1Component,
    Form2Component,
    ThankYouComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    ComponentsModule,
    NgxUsefulSwiperModule,
    CustomDirectivesModule,
    LangTranslateModule,
    MerchantCheckoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    AccordionModule.forRoot(),
  ],

  exports: [
    HomeComponent,
    HowItWorksComponent,
    AboutComponent,
    CountrySelectComponent,
    FeaturedMerchantComponent,
    ShopCategoriesComponent,
    HowitworksComponent,
    PrivacyComponent,
    TermsAndConditionsComponent,
    RequestForm1Component,
    RequestForm2Component,
    Form1Component,
    Form2Component,
  ],
})
export class PagesModule {}
