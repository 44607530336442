import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutPagesComponent } from './checkout-pages/checkout-pages.component';
import { LoginSignupComponent } from './checkout-pages/login-signup/login-signup.component';
import { SecureCheckoutComponent } from './secure-checkout/secure-checkout.component';

const routes: Routes = [
  { path: 'securecheckout/:id', component: CheckoutPagesComponent },
  { path: 'securecheckout', component: CheckoutPagesComponent },
  // {path: 'securecheckout/:id', component: LoginSignupComponent},
  { path: '', component: SecureCheckoutComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MerchantCheckoutRoutingModule {}
