import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/env.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  constructor(private http: HttpClient, private environment: EnvService) { }

  getCalcPayment(orderToken): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.environment.ApiUrl}/accounts/payment/calcInstallments/${orderToken}`
    );
  }

  processOrderPayment(order: any): Observable<any> {
    return this.http.post<any>(
      `${this.environment.ApiUrl}/accounts/payment/processOrderPayment`,
      order
    );
  }

  getOrderDetails(orderToken): Observable<any> {
    return this.http.post<any>(
      `${this.environment.ApiUrl}/accounts/payment/info/${orderToken}`,
      null
    );
  }

  getExpectedOrderDetails(orderPlan: any): Observable<any> {
    return this.http.post<any>(
      `${this.environment.ApiUrl}/accounts/payment/expectedInstallments`,
      orderPlan
    );
  }

  getDuePaymentDetails(orderId): Observable<any> {
    return this.http.get<any>(
      `${this.environment.ApiUrl}/accounts/orders/nextInstallmentDue/${orderId}`
    );
  }

  payInstallment(installment: any): Observable<any> {
    return this.http.post<any>(
      `${this.environment.ApiUrl}/accounts/payment/payInstallment`,
      installment
    );
  }

  payFullAmount(installment: any): Observable<any> {
    return this.http.post<any>(
      `${this.environment.ApiUrl}/accounts/payment/payTotalPaymentDue`,
      installment
    );
  }
  payMultipleInstallments(multipleInstallments) {
    return this.http.post<any>(`${this.environment.ApiUrl}/accounts/payment/payMultipleInstallments`, multipleInstallments)
  }
  
  payInstallmentOnKnet(installment: any): Observable<any> {
    return this.http.post<any>(
      `${this.environment.ApiUrl}/accounts/payment/payInstallment/knet`,
      installment
    );
  }
}
