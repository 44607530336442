import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { PasswordShowbtnComponent } from './password-showbtn/password-showbtn.component';
import { SuccessBoxComponent } from './success-box/success-box.component';
import { AlertBoxComponent } from './alert-box/alert-box.component';
import { DataLoadingComponent } from './data-loading/data-loading.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { FormsModule } from '@angular/forms';
import { LangTranslateModule } from 'src/app/lang-translate.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { CountycodeSelectorComponent } from './countycode-selector/countycode-selector.component';
import { SearchInTableComponent } from './search-in-table/search-in-table.component';
import { SortTableComponent } from './sort-table/sort-table.component';
import { PaginationComponent } from './pagination/pagination.component';
import { OtpCounterComponent } from './otp-counter/otp-counter.component';
import { TalyOverviewComponent } from './taly-overview/taly-overview.component';


@NgModule({
  declarations: [
    PasswordStrengthComponent,
    PasswordShowbtnComponent,
    SuccessBoxComponent,
    AlertBoxComponent,
    DataLoadingComponent,
    LanguageSelectComponent,
    CountycodeSelectorComponent,
    SearchInTableComponent,
    SortTableComponent,
    PaginationComponent,
    OtpCounterComponent,
    TalyOverviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LangTranslateModule
  ],

  exports : [
    PasswordStrengthComponent,
    PasswordShowbtnComponent,
    SuccessBoxComponent,
    AlertBoxComponent,
    DataLoadingComponent,
    LanguageSelectComponent,
    CountycodeSelectorComponent,
    SearchInTableComponent,
    SortTableComponent,
    PaginationComponent,
    OtpCounterComponent
  ]
})
export class ComponentsModule { }
