import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-version-check',
  templateUrl: './version-check.component.html',
  styleUrls: ['./version-check.component.scss'],
})
export class VersionCheckComponent implements OnInit {
  envType: any;
  version: any;

  constructor() {
    console.log(environment);
    this.envType = environment.name;
    this.version = environment.version;
  }

  ngOnInit(): void {}
}
