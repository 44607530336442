export class TextDirectionController {
  textDirection: string;
  constructor() {
    this.checkDirection();
  }

  public checkDirection() {
    const lang = sessionStorage.getItem('lang');
    if (lang == 'ar-KW') this.textDirection = 'rtl';
    else this.textDirection = 'ltr';
  }

  public isLTR(): boolean {
    if (sessionStorage.getItem('lang') === 'en-US') {
      return true;
    }
    return false;
  }
}
