import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
//import { SignupService } from './../../../../Core/services/signup/signup.service';\
import { SignupService } from '../../services/signup/signup.service';
@Component({
  selector: 'app-otp-counter',
  templateUrl: './otp-counter.component.html',
  styleUrls: ['./otp-counter.component.scss']
})
export class OtpCounterComponent implements OnInit {

  constructor(private signupService: SignupService) { }
  counter$: Observable<number>;
  count = 60;

  otpDetails: any;
  @Input() countryCode: any;
  ngOnInit(): void {

    // otpReference: sessionStorage.getItem('otpRef'),

    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

  @Output() onReTimer = new EventEmitter();
  onResend() {
    // const otp: any = {
    //   requestId: JSON.parse(sessionStorage.getItem('reqID')),
    //   otpReference: JSON.parse(sessionStorage.getItem('otpRef')),
    //   otpReference: 'cd75a227-aee7-416d-9dba-ceaaf0d83ffe',
    //   countryCode: '+965',
    //   mobileNumber: '99023559',
    // };
    // console.log(otp);
    
    // this.signupService.resendOtp(otp).subscribe((res) => {
    //   this.otpDetails = res;
    //   console.log(this.otpDetails);

    // })
    this.onReTimer.emit();
    this.count = 60;
  }

}
