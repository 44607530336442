import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/Core/services/profile/profile.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  constructor(public datepipe: DatePipe, public router: Router, private activeRoute: ActivatedRoute, private profileService: ProfileService,private loaderService: NgxUiLoaderService,) { }
  currentDateTime: any;
  retriveInvoiceRes: any;
  tapId: any;
  orderId:any;
  ngOnInit(): void {
    this.loaderService.start();

    this.currentDateTime = this.datepipe.transform((new Date), 'yyyy/MM/dd hh:mm');
    this.activeRoute.queryParams.subscribe((params) => {
      if (params['tap_id']) {
        this.tapId = params['tap_id'];
      //  setTimeout(() => {
          this.retrieveInvoice();
      //  }, 401);
      }
    });
  }
  retrieveInvoice() {
    this.profileService.retrieveInvoice(this.tapId).subscribe((res) => {
      this.retriveInvoiceRes = res;       
      this.orderId = this.retriveInvoiceRes.orderId
      if(this.orderId){
        this.loaderService.stop();        
      }
    })
  }
  login() {
    this.router.navigate(['/consumer/login']);
  }
}
