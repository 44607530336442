import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plugin-how',
  templateUrl: './plugin-how.component.html',
  styleUrls: ['./plugin-how.component.scss']
})
export class PluginHowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  navtabsClass:string[]=[];

  onOpenHowModel(){
    this.navtabsClass=["navTabActive",""];
  }

  onNavTab(num:number){
    switch (num) {
      case 1:
        this.navtabsClass=["navTabActive",""]
        break;

      case 2:
        this.navtabsClass=["","navTabActive"]
        break;
    
      default:
        break;
    }
  }
}
