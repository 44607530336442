import { SignupService } from 'src/app/Core/services/signup/signup.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DataServiceService } from 'src/app/Core/services/data-service.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/Core/services/signin/auth.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-customer-info-checkout',
  templateUrl: './customer-info-checkout.component.html',
  styleUrls: ['./customer-info-checkout.component.scss'],
  // providers: [SignupService],
})
export class CustomerInfoCheckoutComponent implements OnInit {
  @Output() loggedIn = new EventEmitter<any>();

  bsConfig?: Partial<BsDatepickerConfig>;

  customerForm: FormGroup;
  submitted = false;
  errorText: string;
  error: string;
  date: any;
  @Output() moveStep = new EventEmitter<number>();

  get f() {
    return this.customerForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private loaderService: NgxUiLoaderService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dataService: DataServiceService,
    private router: Router,
    private authService: AuthenticationService,
    private datePipe: DatePipe
  ) {
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-blue',
        dateInputFormat: 'DD-MM-YYYY',
        showWeekNumbers: false,
        customTodayClass: 'custom-today-class',
        adaptivePosition: true,
      }
    );
  }

  otpResponse: any;
  customerDetails: any;
  dobCurrentDate = new Date();
  ngOnInit(): void {
    // sessionStorage.setItem('step', '4');

    this.customerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      emailAddress: ['', [Validators.required, Validators.email]],
      gender: ['', Validators.required],
      dob: ['', [Validators.required]],
    });

    const currentDate = new Date();
    let month: number = currentDate.getMonth() + 1;
    let tmpDate =
      currentDate.getFullYear() -
      16 +
      '-' +
      (month < 9 ? 0 + '' + month : month) +
      '-' +
      (currentDate.getDate() < 9
        ? 0 + '' + currentDate.getDate()
        : currentDate.getDate());

    this.date = new Date(tmpDate);

    this.signupService.getConsumerDetails().subscribe((user) => {
      console.group('getConsumerDetails');
      console.log(user);
      console.groupEnd();
      this.customerDetails = user;

      this.customerDetails = user;

      if (user) {
        if (user?.englishName) {
          var str = user?.englishName;
          const fname = str.substring(0, str.indexOf(' '));
          const lname = str.substring(str.indexOf(' ') + 1);
          if (fname) {
            this.customerForm?.get('firstName')?.patchValue(fname);
            this.customerForm?.controls['firstName'].disable();
          }
          if (lname) {
            this.customerForm?.get('lastName')?.patchValue(lname);
            this.customerForm?.controls['lastName'].disable();
          }
        }

        if (user?.contacts && user?.contacts?.email) {
          this.customerForm
            ?.get('emailAddress')
            ?.patchValue(user?.contacts?.email);
          // this.customerForm?.controls['emailAddress'].disable();
        }

        if (user?.sex) {
          if (user?.sex === 'M') {
            this.customerForm?.get('gender')?.patchValue('Male');
            this.customerForm?.controls['gender'].disable();
          }
          if (user?.sex === 'F') {
            this.customerForm?.get('gender')?.patchValue('Female');
            this.customerForm?.controls['gender'].disable();
          }
        }

        if (user?.dateOfBirth && user.dateOfBirth?.date) {
          console.log(user.dateOfBirth?.date);
          const _dob = new Date(user.dateOfBirth?.date);
          console.log(_dob);
          this.customerForm?.get('dob')?.patchValue(_dob);
          this.customerForm?.controls['dob'].disable();
        }
      }
    });

    var _d = new Date();
    var _year = _d.getFullYear();
    var _month = _d.getMonth();
    var _day = _d.getDate();
    this.dobCurrentDate = new Date(_year - 5, _month, _day);
  }

  onSubmit() {
    this.submitted = true;

    if (this.customerForm.invalid) {
      this.toastr.error(this.errorText);
      return;
    }
    this.loaderService.start();

    const formData: any = {
      requestId: sessionStorage.getItem('reqID'),
      firstName: this.f.firstName.value.trim(),
      lastName: this.f.lastName.value.trim(),
      emailAddress: this.f.emailAddress.value.trim(),
      gender: this.f.gender.value.toLocaleUpperCase(),
      dateOfBirth: this.datePipe.transform(this.f.dob.value, 'dd/MM/yyyy'),
      nationalId: sessionStorage.getItem('nationalId'),
    };

    this.signupService.storeCustomerInfo(formData).subscribe(
      (resp) => {
        if (resp) {
          sessionStorage.setItem('reg_otp', resp);
          this.authService
            .login(sessionStorage.getItem('regis_number'), resp)
            .subscribe(
              (res) => {
                if (res) {
                  sessionStorage.removeItem('regis_number');
                  sessionStorage.removeItem('reg_otp');
                  this.onSkip();
                  sessionStorage.setItem('checkoutSignupEmit', 'true');
                } else {
                  console.log(res);
                }
                this.loaderService.stop();
              },
              (err) => {
                this.loaderService.stop();
                console.log(err);
              }
            );
        } else {
          console.log(resp);
        }
      },
      (error) => {
        this.loaderService.stop();
        this.toastr.error(
          error.error.errorCode || error.error.message,
          this.error
        );
      }
    );
  }

  onBack() {
    sessionStorage.removeItem('step');
    if (sessionStorage.getItem('checkoutSignupEmit') != '') {
      let orderToken = sessionStorage.getItem('orderTokenForCheckout');
      this.router.navigate(['/checkout/securecheckout', orderToken]);
    } else this.moveStep.emit(1);
  }

  disabledDates: NgbDateStruct[] = [
    {
      year: 2021,
      month: 10,
      day: 30,
    },
  ];

  isDisabled = (
    date: NgbDateStruct,
    current: {
      month: number;
      year: number;
    }
  ) => {
    //in current we have the month and the year actual
    return this.disabledDates.find((x) =>
      new NgbDate(x.year, x.month, x.day).equals(date)
    )
      ? true
      : false;
  };

  disableDate() {
    return false;
  }

  onSkip() {
    // this.moveStep.emit(5);
    this.clearRegistrationDetails();
    if (
      sessionStorage.getItem('orderTokenForCheckout') != null &&
      sessionStorage.getItem('orderTokenForCheckout') != undefined
    ) {
      let orderToken = sessionStorage.getItem('orderTokenForCheckout');
      sessionStorage.setItem('checkoutSignupEmit', 'true');
      this.router.navigate(['/checkout/securecheckout', orderToken]);
      this.loggedIn.emit();
    } else {
      this.router.navigate(['/consumer/dashboard']);
    }
  }

  clearRegistrationDetails() {
    sessionStorage.removeItem('step');
    sessionStorage.removeItem('signupResponse');
  }
}
