import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      window.location.href = sessionStorage.getItem('redUrl');
      sessionStorage.removeItem('redUrl');
    }, 5000);
  }

  onRedirect() {
    window.location.href = sessionStorage.getItem('redUrl');
    sessionStorage.removeItem('redUrl');
  }
}
